import moment from 'moment/moment';

const getGroupUUID = () => window.location.pathname.split('/')[2];
const defaultSearchQuery = () => ({
  group_uuid: getGroupUUID(),
  message_status: '',
  search_query: '',
  order: 'trans_id',
  order_by: 'desc',
  recipient_email: '',
  transaction_search_string: '',
  start_date: moment().subtract(29, 'days').unix(),
  end_date: moment().unix(),
  page: 1,
});

const getGroupStatsQuery = (reportType, startDate = '', endDate = '') => {
  let groupStatsQuery = { report_type: reportType, group_uuid: getGroupUUID() };
  if (startDate !== '') {
    groupStatsQuery = { ...groupStatsQuery, start_date: startDate, end_date: endDate };
  }
  return groupStatsQuery;
};

// eslint-disable-next-line import/prefer-default-export
export { getGroupUUID, defaultSearchQuery, getGroupStatsQuery };
