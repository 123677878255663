import { Link } from 'react-router-dom';

const TemplateHeader = ({ deleteClick, createLink, createText }) => (
  <div>
    <div
      className={`icon-stack icon-gray icon-with-text text-muted right-buffer10 ${true === '' ? 'disabled' : ''}`}
      role="button"
      tabIndex={0}
      data-toggle="tooltip"
      data-placement="top"
      onClick={deleteClick}
      onKeyUp={deleteClick}
      title=""
      data-original-title="Delete"
    >
      <i className="seve-icon-circle-thin stack-one text-muted">
        <i className="seve-icon-bin-2 stack-two text-muted" />
      </i>
    </div>
    <Link
      className="icon-stack icon-primary icon-with-text text-primary"
      to={createLink}
      role="button"
      data-toggle="tooltip"
      data-placement="top"
      title=""
      data-original-title={createText}
      tabIndex={-1}
    >
      <i className="seve-icon-circle-thin stack-one text-primary">
        <i className="seve-icon-add-1 stack-two text-primary" />
      </i>
      <span className="top-buffer10 left-buffer10 pull-left text-primary">
        {createText}
      </span>
    </Link>
  </div>
);

export default TemplateHeader;
