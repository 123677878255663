import makeAxiosCall from '../Utilities/make-axios-call';

const TransactionGroupExportComponent = (prop) => {
  const createCsvReport = () => {
    const {
      dateValue,
      setAlert,
      searchQuery,
    } = prop;

    makeAxiosCall('v1/transaction-groups/reports/export', dateValue, {
      ...searchQuery,
      isExport: true,
    })
      .then((res) => {
        if (res.status === 200) {
          setAlert('success', 'Report queued successfully. We will send you an email when your report is ready to download.');
        } else {
          setAlert('danger', 'An error occurred when queuing the report. Please try again.');
        }
      })
      .catch(() => setAlert('danger', 'An error occurred when queuing the report. Please try again.'));
  };

  return (
    <button
      className="btn btn-primary inverted"
      style={{ float: 'right', marginRight: '5rem' }}
      type="button"
      onClick={createCsvReport}
    >
      Export
    </button>
  );
};

export default TransactionGroupExportComponent;
