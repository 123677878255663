import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Alert from '../../Utilities/Alert';
import Header from '../../Layout/Header';
import AttachmentOptions from '../AttachmentOptions';
import LinkSettings from '../LinkSettings';
import Files from './Files';
import AttachmentService from '../attachment-service';

const ReviewAttachment = () => {
  const history = useHistory();
  const [isLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [group, setGroup] = useState({ name: 'Untitled', options: { expiry_timestamp: 0 } });
  const [assets, setAssets] = useState([]);
  const [alertType] = useState('success');
  const [message, setMessage] = useState('');
  const save = () => {
    setMessage('Save successful');
    setShowAlert(true);
    setTimeout(() => history.push('/online-attachments'), 3000);
  };

  const { id } = useParams();

  useEffect(() => {
    async function getGroup() {
      try {
        const response = await AttachmentService.getGroup(id);
        setGroup(response.data.group);
        setAssets(response.data.group.assets ?? []);
      } catch (e) {
        // eslint-disable-next-line
      }
    }

    if (id !== undefined) {
      getGroup();
    }
  }, []);

  return (
    <div className={`bg-gray-lightest min-container-height online-attachments ${isLoading ? 'disabled' : ''}`}>
      <Alert message={message} type={alertType} showAlert={showAlert} />
      <>
        <Header noHeader>
          <div>
            Review Online Attachment
          </div>
        </Header>
        <div className="d-flex justify-content-center top-buffer40">
          <div className="width980">
            <h3 className="pad-bottom10">{group.name}</h3>
            <div className="row">
              <div className="col-md-6">
                <div className="padding30 bg-white height-400">
                  <div>
                    <LinkSettings type="view" initialOptions={group.options} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="padding30 bg-white height-400">
                  <div>
                    <AttachmentOptions id={group.id} display="inline" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pad-top30">
              <Files files={assets} />
            </div>
          </div>
        </div>
      </>
      <div className="neutron-footer">
        <div className="text-right pad-left20 pad-right20">
          <button
            type="button"
            className="btn btn-secondary inverted"
            onClick={save}
            onKeyUp={save}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewAttachment;
