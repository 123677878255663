import React from 'react';
import moment from 'moment';
import Header from '../Layout/Header';
import Preloader from '../Layout/Preloader';
import { initTooltip } from '../Utilities/tooltip';
import SendingOptions from './SendingOptions';
import ApiCredentials from './ApiCredentials';
import getAxiosClient from '../Utilities/get-axios-client';
import Webhook from '../Webhook/Webhook';
import SMTPCredentials from './SMTPCredentials';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      webhookData: {
        url: '',
        verb: 'post',
        status: 'enabled',
        auth_type: '',
        event_types: ['sent', 'failed', 'bounce', 'open', 'click', 'unsubscribe', 'resubscribe'],
        auth_details: null,
        field_set: 'standard',
      },
    };
  }

  async componentDidMount() {
    document.title = 'Settings';
    try {
      const result = await getAxiosClient().get('/v1/ui-settings', { withCredentials: true });
      const webhookResponse = await getAxiosClient().get('/v1/webhooks');
      const { data } = result;
      const { data: webhookRawData } = webhookResponse;
      const webhook = webhookRawData.data[0];
      if ((moment(webhook?.created_timestamp).unix() > 0) ?? false) {
        const webhookData = {
          event_types: webhook?.event_types ?? [],
          url: webhook?.url ?? '',
          verb: webhook?.verb ?? 'get',
          status: webhook?.status ?? 'disabled',
          auth_details: webhook?.auth_details ?? null,
          auth_type: webhook?.auth_type ?? '',
          field_set: webhook?.field_set ?? 'standard',
        };
        this.setState({ webhookData });
      }
      this.setState({ data });
      initTooltip();
    } catch (e) {
      // eslint-disable-next-line
    }
  }

  render() {
    const { data, webhookData } = this.state;
    if (data == null) {
      return <Preloader />;
    }

    return (
      <>
        <Header>Settings</Header>
        <div className="padding40 col-md-8">
          <div className="bottom-buffer40">
            <SendingOptions settingsValue={data.settings} />
          </div>
          <Webhook webhookFromAPI={webhookData} />
          <div className="bottom-buffer40">
            <ApiCredentials
              apiCredentials={data.credentials.apiCredentials}
              isReseller={data.credentials.isReseller}
            />
          </div>
          <div className="bottom-buffer40">
            <SMTPCredentials smtpCredentials={data.credentials.smtpCredentials} />
          </div>
        </div>
      </>
    );
  }
}

export default Settings;
