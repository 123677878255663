import React from 'react';

const VerticalStatItem = ({ title, value }) => (
  <p className="border-top-light text-muted pad-top5 d-flex justify-content-between">
    <span>{title}</span>
    <span>{value}</span>
  </p>
);

export default VerticalStatItem;
