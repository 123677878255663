import { Link } from 'react-router-dom';

const TransactionGroupSidebar = ({ hash, currentReportType }) => {
  const getCurrentActiveClass = (reportType) => (reportType === currentReportType ? 'active no-pointer-events' : '');
  return (
    <div className="cont-nav border-right-light">
      <div className="text-center bottom-buffer20 top-buffer20">
        <div className="circle circle-110 img-circle circle-no-hover text-center circle-primary">
          <i className="icon icon circle-icon seve-icon-receipt-3" />
          <div className="circle-mini circle-success text-center ">
            <i className="icon circle-icon seve-icon-business-chart-1" />
          </div>
        </div>
      </div>
      <div className="cont-nav-action-btn bottom-buffer20">
        <Link to="/groups">
          <i className="seve-icon-angle-left" />
          Back to Transactions
        </Link>
      </div>
      <ul className="cont-nav-nav">
        <li className={`text-transform-capitalize ${getCurrentActiveClass('overview')}`}>
          <Link to={`/groups/${hash}/overview`}>
            Overview
          </Link>
        </li>
        <li className={`text-transform-capitalize ${getCurrentActiveClass('sent')}`}>
          <Link to={`/groups/${hash}/sent`}>
            Sent
          </Link>
        </li>
        <li className={`text-transform-capitalize ${getCurrentActiveClass('opens')}`}>
          <Link to={`/groups/${hash}/opens`}>
            Opens
          </Link>
        </li>
        <li className={`text-transform-capitalize ${getCurrentActiveClass('clicks')}`}>
          <Link to={`/groups/${hash}/clicks`}>
            Clicks
          </Link>
        </li>
        <li className={`text-transform-capitalize ${getCurrentActiveClass('failed')}`}>
          <Link to={`/groups/${hash}/failed`}>
            Failed
          </Link>
        </li>
        <li className={`text-transform-capitalize ${getCurrentActiveClass('bounced')}`}>
          <Link to={`/groups/${hash}/bounced`}>
            Bounced
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default TransactionGroupSidebar;
