export default (props) => {
  const {
    handleModalClosed = () => {},
  } = props;

  return (
    <div className="modal-header">
      <button
        type="button"
        className="close
          top-buffer0"
        data-dismiss="modal"
        aria-label="close"
        onClick={handleModalClosed}
      >
        <span aria-hidden="true">×</span>
      </button>

      <div className="circle circle-100 circle-no-hover img-circle text-center index-icon -top-55">
        <i className="seve-icon-globe-2 icon-36 absolute-center top-buffer5 border" />
      </div>

      <h4 className="pad-top20 ">
        Verify and Authenticate your Domain
      </h4>
    </div>
  );
};
