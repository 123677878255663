const WebhookModalEvents = ({ events, updateOptions }) => (
  <div className="top-buffer20">
    <p>Select the events you would like to receive events for</p>
    <div className="row">
      <div className="col-md-4">
        <div className="bg-gray-lightest padding20 pad-left40">
          <p className="bold">Transaction Events</p>
          <div className="ev-control clearfix">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="webhook_event_sent"
                className="ev-control-switch"
                checked={events.includes('sent')}
                onChange={(e) => updateOptions('events', e.target.value, 'sent')}
              />
              <label htmlFor="webhook_event_sent" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div>Sent</div>
          </div>
          <div className="ev-control">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="webhook_event_failed"
                className="ev-control-switch"
                checked={events.includes('failed')}
                onChange={(e) => updateOptions('events', e.target.value, 'failed')}
              />
              <label htmlFor="webhook_event_failed" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div>Failed</div>
          </div>
          <div className="ev-control">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="webhook_event_bounced"
                className="ev-control-switch"
                checked={events.includes('bounce')}
                onChange={(e) => updateOptions('events', e.target.value, 'bounce')}
              />
              <label htmlFor="webhook_event_bounced" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div>Bounced</div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="bg-gray-lightest padding20 pad-left40">
          <p className="bold">Email Events</p>
          <div className="ev-control clearfix">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="webhook_event_open"
                className="ev-control-switch"
                checked={events.includes('open')}
                onChange={(e) => updateOptions('events', e.target.value, 'open')}
              />
              <label htmlFor="webhook_event_open" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div>Opened</div>
          </div>
          <div className="ev-control">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="webhook_event_click"
                className="ev-control-switch"
                checked={events.includes('click')}
                onChange={(e) => updateOptions('events', e.target.value, 'click')}
              />
              <label htmlFor="webhook_event_click" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div>Link Clicked</div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="bg-gray-lightest padding20 pad-left40">
          <p className="bold">Recipients Events</p>
          <div className="ev-control clearfix">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="webhook_event_unsubscribe"
                className="ev-control-switch"
                checked={events.includes('unsubscribe')}
                onChange={(e) => updateOptions('events', e.target.value, 'unsubscribe')}
              />
              <label htmlFor="webhook_event_unsubscribe" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div>Unsubscribed</div>
          </div>
          <div className="ev-control">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="webhook_event_resubscribe"
                className="ev-control-switch"
                checked={events.includes('resubscribe')}
                onChange={(e) => updateOptions('events', e.target.value, 'resubscribe')}
              />
              <label htmlFor="webhook_event_resubscribe" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div>Resubscribed</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WebhookModalEvents;
