import React, { useEffect, useState } from 'react';
import TextSearchElement from '../Components/TextSearchElement';
import TemplateColumnListing from './TemplateColumnListing';
import getAxiosClient from '../Utilities/get-axios-client';

const TemplateSearchElement = ({ tabOption, index }) => {
  const [templates, setTemplates] = useState([]);
  const { templateType } = tabOption;
  const templateStatus = 'published';
  const blankTemplate = {
    id: 'new',
    name: 'Blank Template',
    status: 'draft',
    from_name: '',
    from_email: '',
    reply_email: '',
    subject: '',
    description: '',
    content_html: '',
  };

  const searchTemplate = async (templateName) => {
    try {
      const response = await getAxiosClient().post('/v1/templates/search', { templateName, templateType, templateStatus });
      setTemplates([blankTemplate, ...response.data.data]);
    } catch (e) {
      setTemplates([]);
    }
  };

  useEffect(() => {
    searchTemplate('');
  }, []);

  return (
    <div id={tabOption.targetValue.substring(1)} className={`tab-pane fade ${index === 0 ? 'in active' : ''}`} key={tabOption.targetValue}>
      <div className="">
        <div className="row top-buffer30 bottom-buffer30">
          <div className="col-md-3">
            <TextSearchElement setSearchTextValue={searchTemplate} placeholder="Search templates" />
          </div>
        </div>
        <TemplateColumnListing templates={templates} />
      </div>
    </div>
  );
};

export default TemplateSearchElement;
