import getAxiosClient from '../Utilities/get-axios-client';

class Service {
  static URL_PATH = {
    getStarted: 'v1/get-started',
  };

  static getStartedData() {
    const client = getAxiosClient(process.env.REACT_APP_SERVICE_BASE_URL);
    const response = client
      .get(Service.URL_PATH.getStarted)
      .then(({ data }) => data)
      .catch((error) => ({ error }));

    return response;
  }
}

export default Service;
