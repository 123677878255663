import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import GroupReportChartOptions from '../GroupReportChartOptions.json';
import getAxiosClient from '../../Utilities/get-axios-client';
import TextSearchElement from '../../Components/TextSearchElement';
import TransactionTableHeader from '../../Transactions/TransactionTableHeader';
import headers from './TransactionsGroupOverviewHeaderData.json';
import PaginationElement from '../../Components/PaginationElement';
import { defaultDashboardPieChartOptions } from '../../Dashboard/dashboard-chart-options';
import TransactionGroupOverviewTableBody from './TransactionGroupOverviewTableBody';
import TransactionModal from '../../Transactions/TransactionModalElement';
import TransactionGroupOverviewStats from './TransactionGroupOverviewStats';
import { defaultSearchQuery, getGroupStatsQuery } from '../TransactionGroupReportHandler';
import TransactionGroupOverviewDataFormatter from './TransactionGroupOverviewDataFormatter';
import TransactionGroupReportElement from '../TransactionGroupReportElement';
import { reportStartDate, reportEndDate } from '../../Utilities/date-format';

class TransactionGroupOverviewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      transactionResponse: {},
      showModal: false,
      selectedTransaction: {},
      chartOptions: GroupReportChartOptions,
      overviewPieChartOptions: defaultDashboardPieChartOptions,
      openedPieChartOptions: defaultDashboardPieChartOptions,
      clickedPieChartOptions: defaultDashboardPieChartOptions,
      searchQuery: { report_type: 'overview', ...defaultSearchQuery() },
      dateRange: {},
    };
    this.groupElementRef = React.createRef();
  }

  async componentDidMount() {
    const dateRange = this.groupElementRef.current.dateValue;
    this.setState((prevState) => (
      {
        ...prevState,
        dateRange,
      }
    ));
    await this.getGroupStats(getGroupStatsQuery('overview', reportStartDate(dateRange.start), reportEndDate(dateRange.end)));
  }

  async getGroupStats(groupStatsQuery) {
    try {
      const response = await getAxiosClient()
        .post('v1/transaction-groups/reports', { ...groupStatsQuery });
      this.setState({
        data: response.data,
        transactionResponse: response.data.transactions,
      });
      await this.setState((previousState) => ({
        chartOptions: TransactionGroupOverviewDataFormatter
          .getChartOptions(response.data, previousState.chartOptions),
      }));
    } catch (e) {
      // eslint-disable-next-line
    }
  }

  getTransactions = async (searchQuery) => {
    const response = await getAxiosClient().post(
      'v1/transaction/search',
      searchQuery,
      { withCredentials: true },
    );
    this.setState({ transactionResponse: response?.data ?? null });
  };

  setCurrentDateRangeValue = async (dateRange) => {
    this.setState((prevState) => (
      {
        ...prevState,
        dateRange,
      }
    ));

    await this.getGroupStats(
      getGroupStatsQuery('overview', reportStartDate(dateRange.start), reportEndDate(dateRange.end)),
    );
  };

  sort = async (order, orderBy) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      order,
      order_by: orderBy,
      page: 1,
    };
    await this.setState({ searchQuery: updatedSearchQuery });
    await this.getTransactions(updatedSearchQuery);
  };

  setSearchTextValue = async (transactionSearchString) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      transaction_search_string: transactionSearchString,
      page: 1,
    };
    await this.setState({ searchQuery: updatedSearchQuery });
    await this.getTransactions(updatedSearchQuery);
  };

  showTransactionModal = async (transaction) => {
    await this.setState({ showModal: true, selectedTransaction: transaction });
  };

  closeModal = () => this.setState({ showModal: false });

  render() {
    const {
      transactionResponse,
      chartOptions,
      showModal,
      selectedTransaction,
      dateRange,
      searchQuery,
    } = this.state;
    return (
      <TransactionGroupReportElement
        ref={this.groupElementRef}
        setCurrentDateRangeValue={this.setCurrentDateRangeValue}
        reportType="overview"
      >
        <TransactionGroupOverviewStats
          dateRange={dateRange}
        />
        <div className="padding30 border-light top-buffer30">
          <h4 className="bottom-buffer30 top-buffer0">Activity</h4>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
          />
        </div>
        <div>
          <div className="bg-white top-buffer20 padding40 border-light">
            <div className="d-flex justify-content-between bottom-buffer30">
              <div className="h4">
                Latest Sent Transactions
                <Link to="/groups" className="small text-primary left-buffer5"><u>View All Transactions</u></Link>
              </div>
              <div className="col-md-3">
                <TextSearchElement
                  transactionSearchQuery={searchQuery}
                  getTransactions={this.getTransactions}
                  dateRange={dateRange}
                />
              </div>
            </div>
            <TransactionModal
              closeModal={this.closeModal}
              showModal={showModal}
              selectedTransaction={selectedTransaction}
            />
            <table className="table table-striped">
              <TransactionTableHeader sort={this.sort} headers={headers} />
              <TransactionGroupOverviewTableBody
                transactionsResponse={transactionResponse}
                showTransactionModal={this.showTransactionModal}
              />
            </table>
            <PaginationElement
              transactionsResponse={transactionResponse}
              transactionSearchQuery={searchQuery}
              getTransactions={this.getTransactions}
              dateRange={dateRange}
            />
          </div>
        </div>
      </TransactionGroupReportElement>
    );
  }
}

export default withRouter(TransactionGroupOverviewReport);
