import moment from 'moment';
import getAxiosClient from './get-axios-client';
import { reportEndDate, reportStartDate } from './date-format';

const makeAxiosCall = async (
  route,
  dateRange = {},
  queryData = {},
) => {
  const startDate = dateRange?.start ?? moment().subtract(29, 'days');
  const endDate = dateRange?.end ?? moment();
  return getAxiosClient().post(
    route,
    {
      ...queryData,
      start_date: reportStartDate(startDate),
      end_date: reportEndDate(endDate),
    },
    { withCredentials: true },
  );
};

export default makeAxiosCall;
