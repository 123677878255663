import { Component } from 'react';
import moment from 'moment';
import DateRangePicker from '../Components/DateRangePicker';
import uiConfig from './UiData/TransactionalGroupReportType.json';
import Stats from './TransactionalGroupReportTypeStats';

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "transactionFilterInputFields",
    ]
  }
] */
/* eslint max-len: ["error" , { "code": 220 }] */
class TransactionalGroupReportType extends Component {
  static UNIT = uiConfig.unit;

  static TRANSACTION_INPUT_FIELDS = uiConfig.transactionFilterInputField;

  static TRANSACTION_INPUT_FIELD_ID = uiConfig.transactionFilterInputFieldId;

  static GROUP_STAT = uiConfig.groupStat;

  static TRANSACTION_FILTER_INPUT_VALUES = {};

  static DEFAULT_DEBOUNCE_TIMEOUT = 500;

  static DATE_FORMAT = 'YYYY-MM-DD';

  constructor(props) {
    super(props);

    const to = new Date();
    const from = moment(to).subtract(1, 'month').toDate();

    this.state = {
      page: 1,
      dateRange: { from, to },
      transactionFilterInputValues: {},
    };

    this.throttleUpdateSearchResults = setTimeout(() => { });
    this.handleDateRangeSelected = this.handleDateRangeSelected.bind(this);
    this.validateTransactionFilterInputFields = this.validateTransactionFilterInputFields.bind(this);
    this.updateSearchResults = this.updateSearchResults.bind(this);
  }

  handleDateRangeSelected(event, picker) {
    const { DATE_FORMAT } = TransactionalGroupReportType;
    const { state } = this;
    const { startDate, endDate } = picker;
    const newState = {
      ...state,
      dateRange: {
        from: startDate.format(DATE_FORMAT),
        to: endDate.format(DATE_FORMAT),
      },
    };

    this.setState(newState);
  }

  getSentStats() {
    const { props } = this;
    const { UNIT, GROUP_STAT } = TransactionalGroupReportType;
    const { type: key, searchResults } = props;
    const { groupStat = {}, transactions = {} } = searchResults;
    const {
      sent = {},
      delivered = {},
      total,
    } = groupStat;
    const groupStats = [
      [
        {
          header: key,
          primary: sent.value,
          secondary: total,
          unit: sent.percentage ? UNIT.percentage : '',
        },
        {
          header: GROUP_STAT.delivered,
          primary: delivered.value,
          secondary: total,
          unit: delivered.percentage ? UNIT.percentage : '',
        },
      ],
      [
        {
          header: GROUP_STAT.transactions,
          primary: transactions.total,
        },
      ],
    ];

    return groupStats;
  }

  get groupStats() {
    const { props } = this;
    const { type, searchResults } = props;
    const getGroupStats = {
      sent: () => this.getSentStats(),
    };
    const stats = searchResults && getGroupStats[type] ? getGroupStats[type]() : [];

    return stats;
  }

  get transactionGroupInput() {
    const { TRANSACTION_INPUT_FIELDS } = TransactionalGroupReportType;
    const { transactionGroup } = TRANSACTION_INPUT_FIELDS;
    const { props } = this;
    const { searchResults = {} } = props;
    const { groupStat = {} } = searchResults;
    const { values = [] } = transactionGroup;
    const transactionGroupInput = {
      ...transactionGroup,
      values: [
        ...values,
        ...Object.keys(groupStat),
      ],
    };

    return transactionGroupInput;
  }

  get transactionDateRangeInputFields() {
    const {
      TRANSACTION_INPUT_FIELDS,
      DATE_FORMAT,
    } = TransactionalGroupReportType;
    const { state } = this;
    const { dateRange } = state;
    const { from, to } = dateRange;
    const {
      transactionDateRangeFrom,
      transactionDateRangeTo,
    } = TRANSACTION_INPUT_FIELDS;
    const fromDate = moment(from).format(DATE_FORMAT);
    const toDate = moment(to).format(DATE_FORMAT);
    const transactionDateRangeInputFields = [
      {
        ...transactionDateRangeFrom,
        value: fromDate,
      },
      {
        ...transactionDateRangeTo,
        value: toDate,
      },
    ];

    return transactionDateRangeInputFields;
  }

  get transactionFilterInputFields() {
    const {
      TRANSACTION_INPUT_FIELDS,
    } = TransactionalGroupReportType;
    const {
      transactionGroupInput,
      transactionDateRangeInputFields,
    } = this;
    const {
      transactionSearch,
    } = TRANSACTION_INPUT_FIELDS;
    const inputFields = [
      transactionSearch,
      transactionGroupInput,
      ...transactionDateRangeInputFields,
    ];

    return inputFields;
  }

  get transactionsTableBodyRows() {
    const { props } = this;
    const {
      searchResults = {},
    } = props;
    const { transactions = {} } = searchResults;
    const { data: transactionData } = transactions;
    const bodyRows = transactionData
      ? transactionData.map((transaction) => this.getTransactionsTableBodyRow({ transaction }))
      : [];

    return bodyRows;
  }

  get transactionsTable() {
    const { props, transactionsTableBodyRows: bodyRows } = this;
    const { transactionsTableHeader: headerRow } = props;
    const transactionsTable = [
      headerRow,
      ...bodyRows,
    ];

    return transactionsTable;
  }

  getTransactionsTableBodyRow(params) {
    const { transaction } = params;
    const { props } = this;
    const {
      transactionsTableHeader: headerRow = [],
    } = props;
    const row = [];

    let value;
    let column;

    headerRow.forEach(({ id, type = undefined }) => {
      value = transaction[id];
      column = type ? { value, type } : value;

      row.push(column);
    });

    return row;
  }

  validateTransactionFilterInputFields(values) {
    const { TRANSACTION_FILTER_INPUT_VALUES } = TransactionalGroupReportType;
    const { state, props } = this;
    const { type } = props;
    const { transactionFilterInputValues } = state;
    const newState = {
      ...state,
      transactionFilterInputValues: {
        ...transactionFilterInputValues,
        [type]: {
          ...values,
        },
      },
    };

    TRANSACTION_FILTER_INPUT_VALUES[type] = {
      ...values,
    };

    this.setState(newState);
  }

  updateSearchResults() {
    const { DEFAULT_DEBOUNCE_TIMEOUT } = TransactionalGroupReportType;
    const {
      props,
      state,
      throttleUpdateSearchResults,
    } = this;
    const {
      type,
      debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT,
      handledActive = () => { },
    } = props;
    const {
      page,
      dateRange,
      transactionFilterInputValues,
    } = state;
    const inputValues = transactionFilterInputValues[type] || {};
    const params = {
      ...inputValues,
      type,
      page,
      dateRange,
    };

    clearTimeout(throttleUpdateSearchResults);

    this.throttleUpdateSearchResults = setTimeout(() => handledActive(params), debounceTimeout);
  }

  render() {
    const {
      TRANSACTION_FILTER_INPUT_VALUES: transactionFilterInputValues,
    } = TransactionalGroupReportType;
    const {
      props,
      state,
      groupStats,
      transactionFilterInputFields,
      transactionsTable,
      handleDateRangeSelected,
      updateSearchResults,
      validateTransactionFilterInputFields,
    } = this;
    const {
      type: id,
      config,
    } = props;
    const {
      page,
      dateRange,
    } = state;
    const {
      iconClass,
      iconContainerClass,
    } = config;
    const { [id]: inputValues = {} } = transactionFilterInputValues;

    updateSearchResults();

    return (
      <div id={id} page={page} className="display-grid row-gap-20px">
        <div>
          <DateRangePicker
            className="width-30em"
            dateRange={dateRange}
            handleDateRangeSelected={handleDateRangeSelected}
          />
        </div>

        <Stats
          iconContainerClass={iconContainerClass}
          iconClass={iconClass}
          stats={groupStats}
          table={transactionsTable}
          inputFields={transactionFilterInputFields}
          inputValues={inputValues}
          validate={validateTransactionFilterInputFields}
        />
      </div>
    );
  }
}

export default TransactionalGroupReportType;
