import { Config } from './SettingsConfigElement';
import documentationUrl from '../Utilities/documentation-url';

const ApiCredentials = ({ apiCredentials, isReseller }) => (
  <>
    <h5 className="font500">API Credentials</h5>
    <div className="padding15 pad-left30 border-radius-base d-flex justify-content-between border-light">
      <div className="row">
        <div className="col-md-10 pad-right40 border-right-light">
          <Config configValues={[
            {
              label: 'Username',
              value: apiCredentials.username,
            },
            {
              label: 'API Key',
              value: apiCredentials.apiKey,
              type: 'password',
            },
            {
              label: 'API Endpoint',
              value: `${apiCredentials.url}/${apiCredentials.url.includes('localhost') ? 'api' : ''}/v1/mail`,
            },
          ]}
          />
        </div>
        <div className="col-md-2 pad-left40 height100p d-flex align-items-center">
          <div className="">
            <>
              <h5 className="semibold">Documentation</h5>
              <p>
                To get started, take a look at the documentation.
              </p>
              <a href={documentationUrl(isReseller)} rel="noreferrer" target="_blank" className="btn btn-primary btn-md">
                Go to documentation
              </a>
            </>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default ApiCredentials;
