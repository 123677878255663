const TransactionGroupFailedReportTableBody = ({ transactionsData }) => (
  <tbody>
    {transactionsData.map((transaction) => (
      <tr key={transaction.trans_uuid} title={transaction.trans_uuid}>
        <td>{transaction?.log_date ?? ''}</td>
        <td><p className="width300 text-truncate">{transaction?.trans_to_email ?? ''}</p></td>
        <td>
          <p className="width300 text-truncate">{transaction?.tm_subject ?? ''}</p>
        </td>
        <td>
          <p
            className="width460 text-truncate"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={transaction?.tf_message ?? ''}
          >
            {transaction?.tf_message ?? ''}
          </p>
        </td>
        <td><></></td>
      </tr>
    ))}
  </tbody>
);

export default TransactionGroupFailedReportTableBody;
