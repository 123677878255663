import { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import defaultConfig from './UiData/LineGraph.json';

export default class LineGraph extends Component {
  static CONFIG = {
    ...defaultConfig,
  };

  render() {
    const { CONFIG } = LineGraph;
    const { props } = this;
    const { config = {} } = props;
    const currentConfig = {
      ...CONFIG,
      config,
    };

    return (
      <div className="line-grapth padding30 bg-white border-light">

        <HighchartsReact
          highcharts={Highcharts}
          options={currentConfig}
        />
      </div>
    );
  }
}
