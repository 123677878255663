import Home from '../Home/Home';
import TransactionalGroups from '../TransactionalGroups/TransactionalGroups';
import TransactionalGroupReport from '../TransactionalGroupReport/TransactionalGroupReport';
import Settings from '../Settings/Settings';
import TransactionGroupFailedReport from '../TransactionalGroupReport/Failed/TransactionGroupFailedReport';
import TransactionGroupBouncedReport from '../TransactionalGroupReport/Bounced/TransactionGroupBouncedReport';
import TransactionGroupOverviewReport from '../TransactionalGroupReport/Overview/TransactionGroupOverviewReport';
import TransactionGroupSentReport from '../TransactionalGroupReport/Sent/TransactionGroupSentReport';
import TransactionGroupClicksReport from '../TransactionalGroupReport/Clicks/TransactionGroupClicksReport';
import TransactionGroupOpensReport from '../TransactionalGroupReport/Opens/TransactionGroupOpensReport';
import Suppression from '../Suppression/Suppression';
import Templates from '../Templates/Templates';
import CreateFromTemplate from '../Templates/CreateFromTemplate';
import TemplateCreate from '../Templates/Create/TemplateCreate';
import WebhookLogs from '../Webhook/WebhookLogs';
import AttachmentListing from '../Attachments/Listing/AttachmentListing';
import CreateAttachment from '../Attachments/Create/CreateAttachment';
import ReviewAttachment from '../Attachments/Create/ReviewAttachment';
import Unauthorised from '../Pages/Unauthorised';
import ExportDownloadView from '../TransactionalGroupReport/Export/ExportDownloadView';

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/groups',
    component: TransactionalGroups,
  },
  {
    path: '/groups/:hash',
    component: TransactionalGroupReport,
  },
  {
    path: '/groups/:hash/failed',
    component: TransactionGroupFailedReport,
  },
  {
    path: '/groups/:hash/sent',
    component: TransactionGroupSentReport,
  },
  {
    path: '/groups/:hash/opens',
    component: TransactionGroupOpensReport,
  },
  {
    path: '/groups/:hash/clicks',
    component: TransactionGroupClicksReport,
  },
  {
    path: '/groups/:hash/overview',
    component: TransactionGroupOverviewReport,
  },
  {
    path: '/groups/:hash/bounced',
    component: TransactionGroupBouncedReport,
  },
  {
    path: '/suppression-list',
    component: Suppression,
  },
  {
    path: '/templates',
    component: Templates,
  },
  {
    path: '/templates/create/:hash',
    component: TemplateCreate,
  },
  {
    path: '/templates/edit/:hash',
    component: TemplateCreate,
  },
  {
    path: '/templates/create',
    component: CreateFromTemplate,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/webhook-logs',
    component: WebhookLogs,
  },
  {
    path: '/online-attachments',
    component: AttachmentListing,
  },
  {
    path: '/online-attachments/create',
    component: CreateAttachment,
  },
  {
    path: '/online-attachments/:id/edit',
    component: CreateAttachment,
  },
  {
    path: '/online-attachments/:id/review',
    component: ReviewAttachment,
  },
  {
    path: '/unauthorized',
    component: Unauthorised,
  },
  {
    path: '/export/:uuid',
    component: ExportDownloadView,
  },
];

export default routes;
