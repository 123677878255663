import React, { useEffect } from 'react';
import getAxiosClient from '../Utilities/get-axios-client';

const LoadingIcon = () => (
  <div className="circle-animated-border-no-icon" />
);

const Login = () => {
  document.title = 'Loading';
  useEffect(() => {
    function fetchData() {
      const query = new URLSearchParams(window.location.search);
      const INITIATE_CHALLENGE = 'v1/auth/initiate-challenge';
      if (query.has('install_url')) {
        const installUrl = query.get('install_url');
        const url = query.get('url') ?? null;
        getAxiosClient().post(
          INITIATE_CHALLENGE,
          { installUrl, redirect: url },
        ).then((response) => {
          window.location.href = response.data.redirectUrl;
          return null;
        }).catch(() => {
          window.location.href = `https://${query.get('install_url')}`;
        });
      }
    }
    fetchData();
  }, []);

  return (
    <div className="bg-gray-lightest" style={{ height: '100vh', minHeight: '900px' }}>
      <div className="d-flex items-center height-percentage-100">
        {/* eslint-disable-next-line react/style-prop-object */}
        <div className="col-md-4" style={{ maxWidth: '600px' }}>
          <div className="padding30 bg-white shadow-sm">
            <div className="top-buffer20">
              <LoadingIcon />
            </div>
            <>
              <h3 className="text-center top-buffer30 bottom-buffer25">Loading </h3>
            </>
          </div>
        </div>
      </div>
      <LoginVersionFooter />
    </div>
  );
};

const LoginVersionFooter = () => (
  <div
    className="bg-white text-center small-copy text-muted padding5 position-fixed force-width-cover position-bottom0 position-left0 box-shadow-light"
  >
    v
    {process.env.REACT_APP_NEUTRON_VERSION}
  </div>
);

export default Login;
