import { Component } from 'react';
import Header from './Header';
import DomainVerification from './DomainVerification';
import Configuration from './Configuration';
import Steps from './Steps';
import Service from './Service';

const { getStartedData, getDomains } = Service;
class Onboarding extends Component {
  constructor(props) {
    document.title = 'Getting started';
    super(props);

    this.state = {
      domains: undefined,
      smtpCredentials: {
        host: undefined,
        port: undefined,
        username: undefined,
        password: undefined,
      },
      apiCredentials: {
        username: undefined,
        apiKey: undefined,
        url: undefined,
      },
      error: undefined,
      isReseller: undefined,
    };

    this.updateDomains = this.updateDomains.bind(this);
  }

  async componentDidMount() {
    const { state } = this;
    const {
      domains,
      smtpCredentials,
      apiCredentials,
      isReseller,
      error,
    } = await getStartedData();
    const newState = error ? { error: error || state.error }
      : {
        domains,
        smtpCredentials: smtpCredentials || state.smtpCredentials,
        apiCredentials: apiCredentials || state.apiCredentials,
        isReseller: isReseller || state.isReseller,
      };
    this.setState(newState);
  }

  async updateDomains() {
    const { state } = this;
    const { domains = [] } = await getDomains();
    const newState = {
      ...state,
      domains,
    };

    this.setState(newState);
  }

  render() {
    const { state, updateDomains } = this;
    const {
      smtpCredentials,
      apiCredentials,
      domains,
      isReseller,
    } = state;

    return (
      <div className="bg-gray-lightest padding40 pad-left50 vh-100">
        <Header />

        <div className="display-grid col-40px-auto column-gap-30px">
          <Steps count="2" domains={domains?.length ?? 0} />

          <div>
            <DomainVerification domains={domains} updateDomains={updateDomains} />

            <Configuration
              apiCredentials={apiCredentials}
              smtpCredentials={smtpCredentials}
              isReseller={isReseller}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Onboarding;
