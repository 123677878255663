import { forwardRef } from 'react';

function ConfigurationSmtp(props, ref) {
  const {
    activeClass,
    activeHeaderClass,
    onClick,
  } = props;

  return (
    <button type="button" ref={ref} onClick={onClick} className={`onboarding-configure-button border-light padding10 ${activeClass}`}>
      <div className="col-md-3">
        <h4 className={`text-center semibold header ${activeHeaderClass}`}>
          SMTP
        </h4>
      </div>
      <div className="text-center col-md-6">
        The easiest way to send email.
        <br />
        Grab your SMTP credentials and plug
        them into your app&apos;s settings
      </div>
      <div className="col-md-3">
        <button type="button" className="btn btn-primary btn-block">Get Key</button>
      </div>
    </button>
  );
}

export default forwardRef(ConfigurationSmtp);
