import React, {
  forwardRef, useContext, useImperativeHandle, useState,
} from 'react';
import moment from 'moment';
import TransactionGroupSidebar from './TransactionGroupSidebar';
import { getGroupUUID } from './TransactionGroupReportHandler';
import TransactionDatePickerSearchElement from '../Transactions/TransactionDatePickerSearchElement';
import { AuthenticationContext } from '../Auth/AuthenticationContext';
import TransactionGroupExportComponent from './TransactionGroupExportComponent';
import Alert from '../Utilities/Alert';

const TransactionGroupReportElement = (props, ref) => {
  const [authentication, setAuthentication] = useContext(AuthenticationContext);
  const defaultValue = authentication?.groupReportDateRange ?? { start: moment().subtract(29, 'days'), end: moment() };
  const [dateValue, setDateValue] = useState(defaultValue);
  const [alertType, setAlertType] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const toggleExportBTN = `${process.env.REACT_APP_TOGGLE_EXPORT_BUTTON}`;

  const setCurrentDateRangeValue = (dateRangeValue) => {
    setDateValue(dateRangeValue);
    const { setCurrentDateRangeValue: setCurrentDateRangeValueMethod } = props;
    setCurrentDateRangeValueMethod(dateRangeValue);
    setAuthentication({ ...authentication, groupReportDateRange: dateRangeValue });
  };

  const setAlert = (type, message) => {
    setAlertType(type);
    setShowAlert(true);
    setAlertMessage(message);
    setShowAlert(false);
  };

  useImperativeHandle(ref, () => ({
    dateValue,
  }), [dateValue]);

  const { children, reportType, searchQuery } = props;
  return (
    <div>
      <TransactionGroupSidebar currentReportType={reportType} hash={getGroupUUID()} />
      <div className="has-sidemenu ">
        <div className="pad-left15">
          <div className="row bg-white pad-left15 pad-right15 pad-top15 pad-bottom15 border-bottom-light">
            <div className="col-sm-6">
              <div className="top-buffer10">
                <h4 className="text-capitalize">{`Transaction Group: ${reportType}`}</h4>
              </div>
            </div>
          </div>
          <div className="top-buffer20 pad-left15 pad-right30">
            <div className="row">
              <Alert
                type={alertType}
                showAlert={showAlert}
                message={alertMessage}
                timeout={5000}
              />
            </div>
            <div className="row">
              <div className="col-sm-4">
                <TransactionDatePickerSearchElement
                  setCurrentDateRangeValue={setCurrentDateRangeValue}
                  defaultDateValue={defaultValue}
                />
              </div>
              <div className="col-sm-8" hidden={reportType === 'overview'}>
                <div hidden={toggleExportBTN === 'hide'}>
                  <TransactionGroupExportComponent
                    dateValue={dateValue}
                    setAlert={setAlert}
                    searchQuery={searchQuery}
                  />
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(TransactionGroupReportElement);
