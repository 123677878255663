import React from 'react';
import { useParams } from 'react-router-dom';
import GetAxiosClient from '../../Utilities/get-axios-client';
import Alert from '../../Utilities/Alert';
import DownloadBlock from './DownloadBlock';

const ExportDownloadView = () => {
  const { uuid } = useParams();
  const [exportData, setExportData] = React.useState({
    fileName: '',
    token: '',
  });
  const [alertMessage, setAlertMessage] = React.useState('');
  const [showAlert, setShowAlert] = React.useState(false);
  const [downloadLink, setDownloadLink] = React.useState('');
  const [errorCode, setErrorCode] = React.useState(0);

  const showError = (errorMessage) => {
    setAlertMessage(errorMessage);
    setShowAlert(true);
  };

  const fetchData = async () => {
    await GetAxiosClient().post('/v1/transaction-groups/export-view', {
      uuid,
    }, {
      withCredentials: true,
    })
      .then((response) => {
        setErrorCode(response.status);

        if (response.status === 204) {
          showError('Export not found.');
        } else {
          setShowAlert(false);
          setExportData(response.data);
          setDownloadLink(`${process.env.REACT_APP_NEUTRON_PUBLIC_BASE_URL}/v1/transaction-groups/export-download/${response.data.token}/${uuid}`);
        }
      })
      .catch((error) => {
        if ('response' in error) {
          showError(error.response.data);
          setErrorCode(error.response.status);
        } else {
          showError('Unknown error.');
          setErrorCode(404);
        }
      });
  };

  React.useEffect(async () => {
    await fetchData().then((r) => r);
  }, []);

  return (
    <>
      <div
        className="pad-top40 min-height-100vh"
      >
        <Alert type="danger" message={alertMessage} showAlert={showAlert} timeout={6000} />
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1 text-center">
              <div>
                <h3 className="font600">Report Export Download</h3>
              </div>
              <DownloadBlock
                fileName={exportData.fileName}
                downloadLink={downloadLink}
                statusCode={errorCode}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportDownloadView;
