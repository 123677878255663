import WebhookModalEvents from './WebhookModalEvents';

const WebhookModalForm = ({ updateOptions, webhook }) => (
  <>
    <div className="form-group">
      <label htmlFor="url">Webhook URL</label>
      <div>
        <input
          className="form-control"
          id="url"
          type="text"
          name="url"
          placeholder="Enter webhook URL"
          value={webhook.url}
          onChange={(e) => updateOptions('url', e.target.value)}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 top-buffer10">
        <div className="form-group">
          <label htmlFor="webhook_method">Field Set</label>
          <div>
            <select
              className="form-control"
              id="webhook_field_set"
              name="webhook_field_set"
              value={webhook.field_set}
              onChange={(e) => updateOptions('field_set', e.target.value)}
            >
              <option value="legacy">Legacy</option>
              <option value="standard">Standard</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 top-buffer10">
        <div className="form-group">
          <label htmlFor="webhook_verb">Verb</label>
          <div>
            <select
              className="form-control"
              id="webhook_verb"
              name="webhook_verb"
              value={webhook.verb}
              onChange={(e) => updateOptions('verb', e.target.value)}
            >
              <option value="post">POST</option>
              <option value="get">GET</option>
              <option value="put">PUT</option>
              <option value="patch">PATCH</option>
              <option value="delete">DELETE</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-5 top-buffer10">
        <div className="form-group">
          <label htmlFor="webhook_method">Authentication</label>
          <div>
            <select
              className="form-control"
              id="webhook_method"
              name="webhook_method"
              value={webhook.auth_type}
              onChange={(e) => updateOptions('auth_type', e.target.value)}
            >
              <option value="">None</option>
              <option value="basic">Basic Authentication</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    { webhook.auth_type === 'basic'
        && (
        <>
          <div className="row">
            <div className="col-md-6 top-buffer10">
              <div className="form-group">
                <label htmlFor="webhook_method">Username</label>
                <div>
                  <input
                    className="form-control"
                    id="webhook_username"
                    type="text"
                    value={webhook.auth_details.username}
                    name="webhook_username"
                    placeholder="Enter webhook auth username"
                    onChange={(e) => updateOptions('auth', e.target.value, 'username')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 top-buffer10">
              <div className="form-group">
                <label htmlFor="webhook_method">Password</label>
                <div>
                  <input
                    className="form-control"
                    id="webhook_password"
                    type="password"
                    name="webhook_password"
                    placeholder="Enter webhook auth password"
                    value={webhook.auth_details.password}
                    onChange={(e) => updateOptions('auth', e.target.value, 'password')}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
        )}
    <div className="clearfix">
      <div>
        <div className="float-left pad-right10 top-buffer5">
          <input
            type="checkbox"
            id="status"
            className="cursor-pointer"
            checked={webhook.status === 'enabled'}
            onChange={(e) => updateOptions('status', e.target.value, '')}
          />
          <label htmlFor="status" className="pad-left5 cursor-pointer">Active</label>
        </div>
      </div>
    </div>
    <WebhookModalEvents events={webhook.event_types} updateOptions={updateOptions} />
  </>
);

export default WebhookModalForm;
