import { Link } from 'react-router-dom';
import { useContext } from 'react';
import getAxiosClient from '../Utilities/get-axios-client';
import { AuthenticationContext } from '../Auth/AuthenticationContext';

const navbar = () => {
  const [authentication] = useContext(AuthenticationContext);
  const { enterprise, user } = authentication;
  const canSend = user?.user_can_send_transactions === 'yes' ?? true;

  const logout = async () => {
    try {
      await getAxiosClient().post('/v1/logout');
      window.location.href = enterprise.enterprise_pmailer_url;
    } catch (e) {
      // eslint-disable-next-line
    }
  };

  const activeMenu = (() => {
    if (window.location.pathname === '/') {
      return 'dashboard';
    }
    if (window.location.pathname === '/settings') {
      return 'settings';
    }
    if ((window.location.pathname.split('/').includes('templates'))) {
      return 'templates';
    }
    if ((window.location.pathname.split('/').includes('online-attachments'))) {
      return 'online-attachments';
    }
    if (window.location.pathname === '/suppression-list') {
      return 'suppression-list';
    }
    if (window.location.pathname === '/webhook-logs') {
      return 'webhook-logs';
    }
    return 'reports';
  })();

  return (
    <nav className="navbar-left">
      <ul className="nav">
        <li className={`dashboard ${activeMenu === 'dashboard' ? 'active' : ''}`}>
          <Link to="/">
            <i className="seve-icon-scale" />
          </Link>
        </li>
        <li className={`transactional-groups dashboard ${activeMenu === 'reports' ? 'active' : ''}`}>
          <Link to="/groups">
            <i className="seve-icon-business-chart-1" />
          </Link>
        </li>
        {canSend && (
          <>
            <li className={`transactional-groups dashboard ${activeMenu === 'suppression-list' ? 'active' : ''}`}>
              <Link to="/suppression-list">
                <i className="seve-icon-user-block-2" />
              </Link>
            </li>
            <li className={`templates dashboard ${activeMenu === 'templates' ? 'active' : ''}`}>
              <Link to="/templates">
                <i className="seve-icon-arrange-4-1" />
              </Link>
            </li>
          </>
        )}
        <li className={`webhook-logs dashboard ${activeMenu === 'webhook-logs' ? 'active' : ''}`}>
          <Link to="/webhook-logs">
            <i className="seve-icon-webhook" />
          </Link>
        </li>
      </ul>
      <ul className="nav stick-bottom">
        {canSend && (
          <li className={`${activeMenu === 'settings' ? 'active' : ''}`}>
            <Link
              to="/settings"
              data-toggle="tooltip"
              data-placement="right"
              title=""
              data-original-title="Settings"
            >
              <i className="seve-icon-setting-gear" />
            </Link>
          </li>
        )}
        <li className="nav-logout">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            role="button"
            tabIndex={0}
            aria-label="Previous"
            onClick={logout}
            onKeyDown={logout}
            data-toggle="tooltip"
            data-placement="right"
            title=""
            data-original-title="Logout"
          >
            <i className="seve-icon-power-5" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default navbar;
