import React, { createContext, useEffect, useState } from 'react';
import getAxiosClient from '../Utilities/get-axios-client';

export const AuthenticationContext = createContext();

export const AuthenticationProvider = (props) => {
  const [authentication, setAuthentication] = useState({
    isAuthenticated: false,
    loading: true,
    backendUrl: '',
  });

  useEffect(() => {
    function fetchData() {
      getAxiosClient().get(process.env.REACT_APP_NEUTRON_VERIFY_URL).then((result) => {
        setAuthentication({
          enterprise: result.data.enterprise,
          customer: result.data.customer,
          user: result.data.user,
          isAuthenticated: result.data.isAuthenticated,
          loading: false,
          backendUrl: result.data.backendUrl,
          groupReportDateRange: null,
        });
      }).catch(() => {
        setAuthentication({
          isAuthenticated: false,
          loading: false,
          enterpriseUrl: '',
          backendUrl: '',
        });
      });
    }
    fetchData();
  }, []);

  const { children } = props;
  return (
    <AuthenticationContext.Provider value={[authentication, setAuthentication]}>
      {children}
    </AuthenticationContext.Provider>
  );
};
