import { useEffect, useState } from 'react';
import GroupReportHeaderStat from '../GroupReportHeaderStat';
import GetOverviewDataByReportType from '../GetOverviewDataByReportType';

const FailedGroupReportHeaderStats = ({ dateRange }) => {
  const [data, setData] = useState({
    overview: {},
  });
  const fetchData = async () => {
    await GetOverviewDataByReportType(dateRange, setData, 'failed');
  };

  useEffect(() => {
    fetchData().then((r) => r).catch((r) => r.toJSON());
  }, [dateRange]);

  const groupStat = data?.overview?.overviewStats;

  return (
    <div className="border-light top-buffer30 d-flex justify-content-between">
      <div className="padding20 d-flex">
        <div className="height-65 width-65 d-flex items-center border-light border-radius-large right-buffer20">
          <div className="width-60 height-60 d-flex items-center border-radius-large text-white bg-brand-danger">
            <i className="d-flex items-center icon-22 seve-icon-delete-1" />
          </div>
        </div>
        <div className="margin-right-80">
          <GroupReportHeaderStat
            title="Failed"
            percentage={(groupStat?.failed.percentage ?? 0)}
            value={groupStat?.failed.value ?? 0}
          />
        </div>
        <div>
          <GroupReportHeaderStat
            title="Sent"
            percentage={(groupStat?.sent.percentage ?? 0)}
            value={groupStat?.sent.value ?? 0}
          />
        </div>
      </div>
      <div className="padding20 d-flex border-left-light">
        <div className="margin-right-80 left-buffer30">
          <GroupReportHeaderStat
            title="Delivered"
            percentage={(groupStat?.delivered.percentage ?? 0)}
            value={groupStat?.delivered.value ?? 0}
          />
        </div>
        <div className="margin-right-80">
          <GroupReportHeaderStat
            title="Sent"
            percentage={(groupStat?.sent.percentage ?? 0)}
            value={groupStat?.sent.value ?? 0}
          />
        </div>
        <div className="right-buffer30">
          <h5 className="margin0">Transactions</h5>
          <h2 className="font400 margin0">
            <span>{groupStat?.total ?? 0}</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default FailedGroupReportHeaderStats;
