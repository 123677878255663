import SummaryStatsIcon from './SummaryStatsIcon';
import SummaryStatComponent from './SummaryStat';

function SummaryStat(props, index) {
  const {
    header = '',
    primary = '',
    secondary = '',
    unit = '',
  } = props;
  const key = `SummaryStat-${index}: ${header}`;

  return (
    <SummaryStatComponent
      key={key}
      header={header}
      primary={primary}
      secondary={secondary}
      unit={unit}
    />
  );
}

export default (props) => {
  const {
    className = '',
    iconContainerClass,
    iconClass,
    stats = [],
  } = props;
  const primaryStats = stats[0] && stats[0].map(SummaryStat);
  const secondaryStats = stats[1] && stats[1].map(SummaryStat);
  const secondaryStatsClass = secondaryStats && secondaryStats.length ? 'summary-stats-secondary' : '';

  return (
    <div className={`padding30 bg-white border-light d-flex justify-content-between overflow-hidden ${className}`}>
      <div className="d-flex align-items-center">
        <SummaryStatsIcon iconContainerClass={iconContainerClass} iconClass={iconClass} />

        <div className="left-buffer10 d-flex column-gap-3em">
          {primaryStats}
        </div>
      </div>

      <div className={`d-flex position-relative ${secondaryStatsClass}`}>
        {secondaryStats}
      </div>
    </div>
  );
};
