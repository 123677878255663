import { useEffect, useState } from 'react';

const Alert = ({
  type = 'danger', showAlert = false, timeout = 2000, message = '', callback,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (showAlert) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
        if (callback) {
          callback();
        }
      }, timeout);
    }
  }, [showAlert]);
  return (
    <>
      {show && (
        <div className={`alert alert-${type} position-absolute left-15 top-0 text-center`} role="alert">
          { message }
        </div>
      )}
    </>
  );
};

export default Alert;
