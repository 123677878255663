import React from 'react';
import activateTooltip from '../Utilities/activate-tooltip';

activateTooltip();

const AttachmentOptions = ({ id, display = 'block' }) => {
  if (!id) {
    return '';
  }
  const copy = (text) => {
    navigator.clipboard.writeText(text);
  };
  const values = [
    { key: 'ID', value: id, tooltip: 'Use your unique ID for this Online Attachment Link and include it in your API or SMTP requests.' },
    // eslint-disable-next-line max-len
    // { key: 'URL', value: `${process.env.REACT_APP_ONLINE_ATTACHMENT_BASE_URL}/group/${id}/preview`, tooltip: 'Your unique URL to share with your customers to download the online attachments.' },
    // eslint-disable-next-line max-len
    // { key: 'Tag', value: `{{attach.${id}}}`, tooltip: 'Use this tag in your email body to include your attachments' },
  ];
  return (
    <div>
      <h5 className="border-bottom-light pad-bottom10 top-buffer0 bottom-buffer20 font-weight-400">Integration Options</h5>
      {values.map((value) => (
        <div className={`form-group ${display === 'inline' && 'display-flex items-center'}`} key={value.key}>
          <label htmlFor="name" className="font-weight-400 width-60">
            {value.key}
            <i
              className="seve-icon-question-mark left-buffer5"
              data-toggle="tooltip"
              data-placement="top"
              role="button"
              aria-label={value.tooltip}
              title={value.tooltip}
            />
          </label>
          <div className={`input-group bg-gray-lightest ${display === 'inline' && 'flex-grow'}`}>
            <input type="text" readOnly className="border-2 form-control bg-none" value={value.value} />
            <span
              className="input-group-addon cursor-pointer bg-white"
              onClick={() => copy(value.value)}
              onKeyUp={() => copy(value.value)}
              role="button"
              tabIndex={0}
            >
              <i className="icon seve-icon-files-4 text-primary" />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AttachmentOptions;
