import React, {
  lazy,
  Suspense,
  useEffect,
  useState,
} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DashboardSendStats from '../../Dashboard/DashboardSendStats';
import DashboardStatItem from '../../Dashboard/DashboardStatItem';
import VerticalStatItem from './VerticalStatItem';
import makeAxiosCall from '../../Utilities/make-axios-call';
import { defaultDashboardPieChartOptions } from '../../Dashboard/dashboard-chart-options';

const RetryFetchData = lazy(() => import('../../Retry/RetryFetchData'));

const OverviewClicksOpenElement = ({
  dateRange,
  route,
  reportType,
}) => {
  const [overviewData, setOverviewData] = useState({
    overview: {},
    openPieOptions: defaultDashboardPieChartOptions,
    clickPieOptions: defaultDashboardPieChartOptions,
  });
  const [retry, setRetry] = useState(false);
  const fetchData = async () => {
    try {
      const getGroupUUID = window.location.pathname.split('/')[2] ?? '';
      const overview = await makeAxiosCall(
        route,
        dateRange,
        { group_uuid: getGroupUUID, report_type: reportType },
      ).catch(() => {
        setTimeout(() => {
          setRetry(true);
        }, process.env.REACT_APP_AXIOS_TIMEOUT);
      });

      setOverviewData((prevState) => ({
        overview: overview?.data,
        openPieOptions: {
          ...prevState.openPieOptions,
          series: [{
            name: 'Opens',
            data: [{
              color: '#0082c6',
              name: 'Opens',
              y: +overview?.data?.overviewStats?.uniqueOpens?.percentage ?? 0,
            }, {
              color: '#f1f1f2',
              name: 'Not Opened',
              y: +overview?.data?.overviewStats?.notOpened?.percentage ?? 0,
            }],
          }],
        },
        clickPieOptions: {
          ...prevState.clickPieOptions,
          series: [{
            name: 'Clicks',
            data: [{
              color: '#955ba5',
              name: 'Clicked',
              y: +overview?.data?.overviewStats?.uniqueClicks?.percentage ?? 0,
            }, {
              color: '#f1f1f2',
              name: 'Not Clicked',
              y: +overview?.data?.overviewStats?.notClicked?.percentage ?? 0,
            }],
          }],
        },
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData().then((r) => r).catch((r) => r.toJSON());
  }, [dateRange, retry]);

  const overviewStats = overviewData?.overview?.overviewStats ?? false;

  if (!overviewStats) {
    return (
      <div className="bg-white top-buffer20 padding40 border-light height-250">
        {
          retry
          && (
          <Suspense fallback={<h1>Loading...</h1>}>
            <RetryFetchData setRetry={setRetry} />
          </Suspense>
          )
        }
        <div className="d-flex items-center height100p">No Data</div>
      </div>
    );
  }

  return (
    <div className="bg-white top-buffer20 padding20 border-light height-250 padding20 pad-left0 pad-right0">
      <div className="col-md-6 row">
        <div className="col-md-8 pad-left0 pad-right0">
          <HighchartsReact
            highcharts={Highcharts}
            options={overviewData.openPieOptions}
          />
        </div>
        <div className="col-md-4 pad-left0 pad-right0">
          <DashboardStatItem
            colorClass=""
            statTitle="Opened"
            bottomData={overviewStats?.uniqueOpens?.value ?? 0}
            topData={`${(overviewStats?.uniqueOpens?.percentage) ?? 0}%` ?? '0%'}
            containerWidth=""
            noPadding="true"
          />
          <div>
            <VerticalStatItem title="Opened" value={overviewStats?.uniqueOpens?.value ?? 0} />
            <VerticalStatItem title="Not Opened" value={overviewStats?.notOpened?.value ?? 0} />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="col-md-8 pad-left0 pad-right0">
          <HighchartsReact
            highcharts={Highcharts}
            options={overviewData.clickPieOptions}
          />
        </div>
        <div className="col-md-4 pad-left0 pad-right0">
          <DashboardStatItem
            colorClass=""
            statTitle="Clicked"
            bottomData={overviewStats?.uniqueClicks?.value ?? 0}
            topData={`${(overviewStats?.uniqueClicks?.percentage) ?? 0}%` ?? '0%'}
            containerWidth=""
            noPadding="true"
          />
          <div>
            <VerticalStatItem title="Clicked" value={overviewStats?.uniqueClicks?.value ?? 0} />
            <VerticalStatItem title="Not Clicked" value={overviewStats?.notClicked?.value ?? 0} />
          </div>
        </div>
      </div>
    </div>
  );
};

const TransactionGroupOverviewStats = ({
  dateRange,
  route = '/v1/transaction-groups/overview',
  reportType = 'overview',
}) => (
  <div className="row">
    <div className="col-md-6">
      <DashboardSendStats
        dateRange={dateRange}
        reportType={reportType}
        route={route}
      />
    </div>
    <div className="col-md-6">
      <OverviewClicksOpenElement
        dateRange={dateRange}
        route={route}
        reportType={reportType}
      />
    </div>
  </div>
);

export default TransactionGroupOverviewStats;
