import React, { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import Quota from './Quota';
import Header from '../Layout/Header';
import Preloader from '../Layout/Preloader';
import TransactionTableHeader from '../Transactions/TransactionTableHeader';
import TransactionTableBody from '../Transactions/TransactionTableBody';
import TransactionModal from '../Transactions/TransactionModalElement';
import getAxiosClient from '../Utilities/get-axios-client';
import TextSearchElement from '../Components/TextSearchElement';
import TransactionDatePickerSearchElement from '../Transactions/TransactionDatePickerSearchElement';
import PaginationElement from '../Components/PaginationElement';
import headers from '../Transactions/TransactionsHeaderData.json';
import makeAxiosCall from '../Utilities/make-axios-call';

// eslint-disable-next-line
const TopTrans = lazy(() => import('./TopTransactions'));
const DashboardSendStats = lazy(() => import('./DashboardSendStats'));

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    document.title = 'Dashboard';
    this.state = {
      data: null,
      showModal: false,
      selectedTransaction: null,
      transactionSearchQuery: {},
      transactionResponse: {},
      dateRange: {},
    };
  }

  async componentDidMount() {
    const data = await makeAxiosCall('/v1/dashboard').catch(() => {}) ?? { data: {} };
    await this.getData(data.data);
  }

  async getData(data) {
    try {
      this.setState(() => ({
        data,
        transactionResponse: data?.transactionStats ?? {},
      }));
    } catch (e) {
      // eslint-disable-next-line
    }
  }

  closeModal = () => this.setState({ showModal: false });

  showTransactionModal = async (transaction) => {
    await this.setState({ showModal: true, selectedTransaction: transaction });
  };

  setCurrentDateRangeValue = async (dateRange) => {
    const result = await makeAxiosCall('/v1/dashboard', dateRange);

    this.setState((prevState) => ({
      ...prevState,
      dateRange,
    }));
    await this.getData(result.data);
  };

  getTransactions = async (searchQuery) => {
    this.setState({ transactionSearchQuery: searchQuery });
    try {
      this.setState({ isLoading: true });
      const response = await getAxiosClient().post(
        'v1/transaction/search',
        searchQuery,
        { withCredentials: true },
      );
      this.setState({ transactionResponse: response?.data ?? null });
    } catch (e) {
      // eslint-disable-next-line
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      data,
      selectedTransaction,
      showModal,
      transactionResponse,
      isLoading,
      dateRange,
      transactionSearchQuery,
    } = this.state;
    if (data == null || isLoading) {
      return <Preloader />;
    }

    return (
      <>
        <Header>Transactional Dashboard</Header>
        <div className="bg-gray-lightest padding30 vh-100">
          <div className="row">
            <div className="d-flex justify-content-between">
              <div className="col-sm-4">
                <TransactionDatePickerSearchElement
                  setCurrentDateRangeValue={this.setCurrentDateRangeValue}
                />
              </div>
              <div className="col-sm-3">
                <Quota dateRange={dateRange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Suspense fallback={<h1>Loading...</h1>}>
                <DashboardSendStats
                  dateRange={dateRange}
                  reportType="dashboard"
                  route="/v1/dashboard/overview"
                />
              </Suspense>
            </div>
            <div className="col-md-6">
              <Suspense fallback={<h1>Loading...</h1>}>
                <TopTrans dateRange={dateRange} />
              </Suspense>
            </div>
          </div>

          <div className="bg-white top-buffer20 padding40 border-light">
            <div className="d-flex justify-content-between bottom-buffer30">
              <div className="h4">
                Latest Sent Transactions
                <Link to="/groups" className="small text-primary left-buffer5"><u>View All Transactions</u></Link>
              </div>
              <div className="col-md-3">
                <TextSearchElement
                  transactionSearchQuery={transactionSearchQuery}
                  getTransactions={this.getTransactions}
                  dateRange={dateRange}
                />
              </div>
            </div>
            <TransactionModal
              closeModal={this.closeModal}
              showModal={showModal}
              selectedTransaction={selectedTransaction}
            />
            <table className="table table-striped">
              <TransactionTableHeader
                headers={headers}
                transactionSearchQuery={transactionSearchQuery}
                getTransactions={this.getTransactions}
                dateRange={dateRange}
              />
              <TransactionTableBody
                transactionsResponse={transactionResponse}
                showTransactionModal={this.showTransactionModal}
                getTransactions={this.getTransactions}
                transactionSearchQuery={transactionSearchQuery}
              />
            </table>
            <PaginationElement
              transactionsResponse={transactionResponse}
              transactionSearchQuery={transactionSearchQuery}
              getTransactions={this.getTransactions}
              dateRange={dateRange}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
