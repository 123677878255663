import { Component } from 'react';
import apiDocumentationUrl from '../Utilities/documentation-url';

export default class ConfigurationApiTest extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { props } = this;
    const { isReseller } = props;
    return (
      <div className="display-grid row-gap-15px">
        <header>
          <h5 className="semibold">Documentation</h5>

          <p>To get started, take a look at the documentation.</p>
        </header>

        <div>
          <a href={apiDocumentationUrl(isReseller)} rel="noreferrer" target="_blank" className="btn btn-primary btn-md">
            Go to documentation
          </a>
        </div>
      </div>
    );
  }
}
