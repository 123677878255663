import React, { useState, useRef } from 'react';

const Dropzone = ({ updateFiles }) => {
  const [isHover, setIsHover] = useState(false);
  const inputFileRef = useRef(null);

  const dragOver = (e) => {
    e.preventDefault();
    setIsHover(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setIsHover(false);
  };

  const buttonClick = () => {
    inputFileRef.current.click();
  };

  const fileUpload = (files) => {
    updateFiles(files[0]);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    setIsHover(false);
    fileUpload(files);
  };

  const onFileChange = (e) => {
    e.preventDefault();
    fileUpload(e.target.files);
  };

  return (
    <div className="text-center">
      <div>
        <div
          className={`transition-1 border-dashed padding40 drop-container cursor-pointer hover-border-primary hover-bg-gray-lighter ${isHover && 'bg-white'}`}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        >
          <i className="seve-icon-download-6 icon-32" />
          <p className="font-weight-600">Drag and drop files here</p>
          <input
            type="file"
            ref={inputFileRef}
            onChange={onFileChange}
            className="hide"
          />
          <div className="height-45">
            {!isHover && (<button type="button" onClick={buttonClick} className="btn btn-primary">Browse Computer</button>)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dropzone;
