import getAxiosClient from '../Utilities/get-axios-client';

class AttachmentService {
  createGroup = (group, asset) => {
    const formData = new FormData();
    formData.append('group', JSON.stringify(group));
    if (asset !== null) {
      formData.append('asset', asset);
    }
    return getAxiosClient().post('/v1/tracked-attachments/groups', formData, {
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
    });
  };

  uploadFile = (groupId, asset, onUploadProgress) => {
    const formData = new FormData();
    formData.append('group', JSON.stringify({ groupId }));
    formData.append('asset', asset);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
      onUploadProgress,
      timeout: 0,
    };
    return getAxiosClient().post(`/v1/tracked-attachments/groups/${groupId}/assets`, formData, config);
  };

  saveBranding = (settings, logo) => {
    const formData = new FormData();
    formData.append('settings', JSON.stringify(settings));
    formData.append('asset', logo);
    return getAxiosClient().post('/v1/tracked-attachments/branding', formData, {
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
    });
  };

  getBranding = () => getAxiosClient().get('/v1/tracked-attachments/branding');

  deleteBrandingLogo = () => getAxiosClient().delete('/v1/tracked-attachments/branding/logo');

  deleteFile = (groupId, assetID) => getAxiosClient()
    .delete(`/v1/tracked-attachments/groups/${groupId}/assets/${assetID}`);

  updateGroup = (group) => getAxiosClient().patch(`/v1/tracked-attachments/groups/${group.id}`, { ...group });

  deleteGroup = (group) => getAxiosClient().delete(`/v1/tracked-attachments/groups/${group.id}`);

  getGroup = (id) => getAxiosClient().get(`/v1/tracked-attachments/groups/${id}`);

  getGroups = (params) => getAxiosClient().get('/v1/tracked-attachments/groups', { params });
}

export default new AttachmentService();
