export default (props) => {
  const {
    className = 'circle-primary',
    primaryIconClass = '',
    secondaryIconClass = '',
  } = props;
  const socondaryIconContainerClass = secondaryIconClass
    ? '' : 'display-none';

  return (
    <div className={`circle circle-110 img-circle circle-no-hover text-center ${className}`}>
      <i className={`icon icon circle-icon ${primaryIconClass}`} />
      <div className={`circle-mini circle-success text-center ${socondaryIconContainerClass}`}>
        <i className={`icon circle-icon ${secondaryIconClass}`} />
      </div>
    </div>
  );
};
