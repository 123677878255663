import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Header from '../Layout/Header';
import SuppressionHeader from './SuppressionHeader';
import getAxiosClient from '../Utilities/get-axios-client';
import activateTooltip from '../Utilities/activate-tooltip';
import SuppressionTable from './SuppressionTable';
import Alert from '../Utilities/Alert';
import SmallLoader from '../Loader/SmallLoader';

class Suppression extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: null, suppressionQuery: {}, emailToBeDeleted: '', showDeleteAlert: false,
    };
  }

  async componentDidMount() {
    document.title = 'Suppression List';
    activateTooltip();
    await this.getSuppressions();
  }

  getSuppressions = async () => {
    const { suppressionQuery } = this.state;
    try {
      const suppressions = await getAxiosClient().post('/v1/suppression/search', suppressionQuery);
      this.setState({ response: suppressions?.data ?? null });
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  goToPage = async (page) => {
    await this.setState((previousState) => ({
      suppressionQuery: { ...previousState.suppressionQuery, page },
    }));
    await this.getSuppressions();
  };

  setSearchRecipient = async (recipientEmail) => {
    await this.setState((previousState) => ({
      suppressionQuery: {
        ...previousState.suppressionQuery,
        page: 1,
        email: recipientEmail,
      },
    }));
    await this.getSuppressions();
  };

  setSuppressedOrigin = async (suppressedOrigin) => {
    await this.setState((previousState) => ({
      suppressionQuery: {
        ...previousState.suppressionQuery,
        page: 1,
        suppressed_origin: suppressedOrigin,
      },
    }));
    await this.getSuppressions();
  };

  setEmailToBeDeletedValue = async (emailToBeDeleted) => {
    this.setState({ emailToBeDeleted });
  };

  deleteCallback = async () => {
    await this.setState({ showDeleteAlert: true });
    const { history } = this.props;
    history.go(0);
  };

  done = () => this.deleteCallback();

  render() {
    const { response, emailToBeDeleted, showDeleteAlert } = this.state;
    if (response === null) {
      return <div className="text-center pad-top50"><SmallLoader isLoading size={50} /></div>;
    }

    return (
      <>
        <Header>Suppression List</Header>
        <SuppressionHeader
          setSearchTextValue={this.setSearchRecipient}
          setSuppressedOriginValue={this.setSuppressedOrigin}
          emailToBeDeleted={emailToBeDeleted}
          goToPage={this.goToPage}
          done={this.done}
        />
        <Alert message="Suppression Deleted Successfully" callback={this.deleteCallback} showAlert={showDeleteAlert} />
        {(response.data.length === 0)
          ? <p className="text-center">No data.</p>
          : (
            <SuppressionTable
              suppressionResponse={response}
              searchQuery={this.setSearchRecipient}
              goToPage={this.goToPage}
              setEmailToBeDeletedValue={this.setEmailToBeDeletedValue}
            />
          )}
      </>
    );
  }
}

export default withRouter(Suppression);
