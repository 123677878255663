import React, { useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment/moment';
import { reportEndDate, reportStartDate } from '../Utilities/date-format';

const TextSearchElement = ({
  dateRange,
  transactionSearchQuery,
  getTransactions,
  placeholder = 'Search by Recipient Email or Transaction ID',
}) => {
  const startDate = dateRange?.start ?? moment().subtract(29, 'days');
  const endDate = dateRange?.end ?? moment();
  const searchRef = useRef();
  const debouncedSearch = useDebouncedCallback(
    async () => {
      const newSearchQuery = {
        ...transactionSearchQuery,
        recipient_email: searchRef.current.value,
        transaction_search_string: searchRef.current.value,
        startDate: reportStartDate(startDate),
        endDate: reportEndDate(endDate),
        page: 1,
      };
      await getTransactions(newSearchQuery);
    },
    1000,
  );
  return (
    <div className="input-group">
      <input
        className="form-control"
        placeholder={placeholder}
        type="text"
        size="40"
        name="search"
        id="search"
        ref={searchRef}
        onChange={debouncedSearch}
      />
      <span className="input-group-addon">
        <i className="icon seve-icon-search-find text-white" />
      </span>
    </div>
  );
};

export default TextSearchElement;
