import { useState } from 'react';
import { Link } from 'react-router-dom';

const TemplateListing = ({ template }) => {
  const [show, setShow] = useState(false);
  const onMouseEnter = () => setShow(true);
  const onMouseOut = () => setShow(false);

  return (
    <div className="col-md-2 cursur-pointer" onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
      <div className="text-center border-light padding20 d-flex flex-column justify-content-between bg-white">
        <div className="d-flex items-center">
          <img src="https://live.everlytic.net/images/misc/default_thumb.png" className="img-responsive" alt={template.subject} />
        </div>
      </div>
      <div className="height-350 margin-top-minus-350">
        <div className="height100p d-flex items-center">
          <Link to={`/templates/create/${template.id}`} className={`btn btn-warning ${show ? '' : 'hide'}`}>Select</Link>
        </div>
      </div>
      <div className="bottom-buffer30 top-buffer10 text-center">{template.name}</div>
    </div>
  );
};

const TemplateColumnListing = ({ templates }) => {
  if (templates.length === 0) {
    return 'No templates found';
  }

  return (
    <div className="row">
      {templates.map((template) => <TemplateListing template={template} key={template.id} />)}
    </div>
  );
};

export default TemplateColumnListing;
