import { useState } from 'react';
import PaginationElement from '../Components/PaginationElement';
import { fromISOtoFormat } from '../Utilities/date-format';

const SuppressionTable = ({
  suppressionResponse,
  searchQuery,
  goToPage,
  setEmailToBeDeletedValue,
}) => {
  const [emailToBeDeleted, setEmailToBeDeleted] = useState('');

  const updateDeleteEmail = (email) => {
    if (emailToBeDeleted === email) {
      setEmailToBeDeleted('');
      setEmailToBeDeletedValue('');
    } else {
      setEmailToBeDeleted(email);
      setEmailToBeDeletedValue(email);
    }
  };
  return (
    <div className="padding40">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">&nbsp;</th>
            <th scope="col">Email</th>
            <th scope="col">Origin</th>
            <th scope="col">Date</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {suppressionResponse.data.map((suppression) => (
            <tr key={suppression.email}>
              <th>
                <input
                  type="checkbox"
                  disabled={emailToBeDeleted !== '' && suppression.email !== emailToBeDeleted}
                  onChange={() => updateDeleteEmail(suppression.email)}
                />
              </th>
              <td>
                <p
                  className="width300 text-truncate"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title={suppression.email}
                >
                  {suppression.email ?? ''}
                </p>
              </td>
              <td className="text-capitalize">{suppression.origin ?? ''}</td>
              <td>{fromISOtoFormat(suppression.suppressed_timestamp ?? 0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationElement
        transactionsResponse={suppressionResponse}
        transactionSearchQuery={searchQuery}
        getTransactions={goToPage}
      />
    </div>
  );
};

export default SuppressionTable;
