import React from 'react';
import formatFileSize from '../../Utilities/format-file-size';

const Files = ({ files }) => {
  if (!files || files.length === 0) {
    return '';
  }

  return (
    <>
      <h5 className="border-bottom-light pad-bottom10 top-buffer0 bottom-buffer20 font-weight-400">Files</h5>
      {files.map((file) => (
        <div
          key={file.name + file.id}
          className="padding20 top-buffer10 display-flex justify-content-between bg-white border-radius-base shadow-sm"
        >
          <span className="font-weight-600 flex-grow text-left">
            <i className="seve-icon-paper-clip pad-right15" />
            {file.name}
          </span>
          <span>{formatFileSize(file.size)}</span>
        </div>
      ))}
    </>
  );
};

export default Files;
