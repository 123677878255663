import React from 'react';
import moment from 'moment';
import formatFileSize from '../../Utilities/format-file-size';

const CreateAttachmentContent = ({ group }) => {
  if ((group?.assets ?? []).length === 0) {
    return '';
  }

  return (
    <>
      <div className="padding20 bg-gray-lighter display-flex justify-content-between">
        <span>&nbsp;</span>
        <a className="text-graydark" target="_blank" rel="noreferrer" href={`${group.preview_link}`}>
          <i className="seve-icon-eye right-buffer5" />
          Preview Page
        </a>
        <span className="right-buffer10">&nbsp;</span>
      </div>
      <div className="flex-grow display-flex justify-content-center">
        <div>
          <div className="text-center top-buffer50 bottom-buffer50">
            <h3 className="font600">Online Attachments</h3>
            <p>
              {`${group.options.expiry_timestamp === null
                ? ''
                : `Expiry date: ${moment(group.options.expiry_timestamp).format('D MMMM YYYY')}`}`}
            </p>
          </div>
          <div className="display-block">
            {
              group.assets.map((asset) => (
                <div
                  key={asset.id}
                >
                  <div className="width600 cursor-pointer padding15 top-buffer10 display-flex justify-content-between bg-white border-radius-base shadow">
                    <div className="flex-grow text-left">
                      <div className="font-weight-600">{asset.name}</div>
                      <span className="small">{formatFileSize(asset.size)}</span>
                    </div>
                    <button type="button" className="btn btn-primary">Download</button>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAttachmentContent;
