import {
  BrowserRouter,
} from 'react-router-dom';
import { Switch } from 'react-router';
import TopBar from './Layout/TopBar';
import NavBar from './Layout/NavBar';
import { AuthenticationProvider } from './Auth/AuthenticationContext';
import Content from './Layout/Content';
import routes from './Config/routes';
import Login from './Auth/Login';
import './Style/style.css';
import PrivateRoute from './Auth/PrivateRoute';
import Unsubscribe from './Unsubscribe/Unsubscribe';
import HeaderUnsubscribe from './Unsubscribe/HeaderUnsubscribe';

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthenticationProvider>
          <Switch>
            <PrivateRoute exact path="/login">
              <Login />
            </PrivateRoute>
            <PrivateRoute path="/unsubscribe/:transactionId">
              <Unsubscribe />
            </PrivateRoute>
            <PrivateRoute path="/header-unsubscribe/:transactionId">
              <HeaderUnsubscribe />
            </PrivateRoute>
            {routes.map((route) => (
              <PrivateRoute
                key={route.path}
                path={route.path}
                exact
              >
                <TopBar />
                <NavBar />
                <div className="ev-container">
                  <div>
                    <Content>
                      <route.component />
                    </Content>
                  </div>
                </div>
              </PrivateRoute>
            ))}
          </Switch>
        </AuthenticationProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
