const TransactionGroupClicksReportTableBody = ({ linksData }) => (
  <tbody>
    {linksData.map((link) => (
      <tr>
        <td>
          <p
            className="width300 text-truncate margin0"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={link.tll_url}
          >
            {link?.tll_url ?? ''}
          </p>
        </td>
        <td>
          <p
            className="width300 text-truncate margin0"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={link.tm_subject}
          >
            {link?.tm_subject ?? ''}
          </p>
        </td>
        <td className="text-center">{link?.click_count ?? 0}</td>
        <td className="text-center">{link?.unique_click_count ?? 0}</td>
        <td><></></td>
      </tr>
    ))}
  </tbody>
);

export default TransactionGroupClicksReportTableBody;
