import React from 'react';

const UnsubscribeAction = ({ hasUnsubscribed, toggleSubscribe }) => (
  <>
    {hasUnsubscribed
      ? (
        <div>
          <p>
            You have been unsubscribed successfully and
            we are sorry to see you go. If this was a mistake,
            Please click on the button to re-subscribe again.
          </p>
        </div>
      )
      : <p>You are subscribed, click the button below to unsubscribe</p>}
    <button
      type="button"
      className="btn btn-primary"
      onClick={toggleSubscribe}
      onKeyUp={toggleSubscribe}
      tabIndex={-1}
    >
      { hasUnsubscribed ? 'Resubscribe' : 'Unsubscribe' }
    </button>
  </>
);

export default UnsubscribeAction;
