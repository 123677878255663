import React from 'react';
import Header from '../Layout/Header';
import Tabs from '../Utilities/Tabs';
import TransactionGroupsTableBody from '../Transactions/TransactionGroupsTableBody';
import Transactions from '../Transactions/Transactions';
import getAxiosClient from '../Utilities/get-axios-client';
import TextSearchElement from '../Components/TextSearchElement';
import PaginationElement from '../Components/PaginationElement';
import headers from './TransactionsGroupHeaderData.json';
import TransactionTableHeader from '../Transactions/TransactionTableHeader';
import Preloader from '../Layout/Preloader';

/* eslint-disable class-methods-use-this */

class TransactionalGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'transactionGroups',
      groupsData: [],
      groupsResponse: {},
      groupSearchQuery: {
        page: 1,
        searchQuery: '',
        order: '',
        orderBy: '',
      },
    };
  }

  async componentDidMount() {
    document.title = 'Transaction groups';
    this.loadTab();
    await this.getGroupData();
  }

  async getGroupData(groupSearchQuery = {}) {
    try {
      const results = await getAxiosClient()
        .post('/v1/transaction-groups/search', groupSearchQuery, { withCredentials: true });
      this.setState({ groupsData: results.data.data, groupsResponse: results.data });
    } catch (e) {
      // eslint-disable-next-line
    }
  }

  searchGroup = async (searchQuery = '') => {
    const { groupSearchQuery } = this.state;
    const updatedGroupSearchQuery = {
      ...groupSearchQuery,
      search_query: searchQuery,
    };
    await this.setState({ groupSearchQuery: updatedGroupSearchQuery });
    await this.getGroupData(updatedGroupSearchQuery);
  };

  goToPage = async (page) => {
    const { groupSearchQuery } = this.state;
    const updatedGroupSearchQuery = {
      ...groupSearchQuery,
      page,
    };
    await this.setState({ groupSearchQuery: updatedGroupSearchQuery });
    await this.getGroupData(updatedGroupSearchQuery);
  };

  sort = async (order, orderBy) => {
    const { groupSearchQuery } = this.state;
    const updatedGroupSearchQuery = {
      ...groupSearchQuery,
      order,
      order_by: orderBy,
    };
    await this.setState({ groupSearchQuery: updatedGroupSearchQuery });
    await this.getGroupData(updatedGroupSearchQuery);
  };

  loadTab = () => {
    setTimeout(() => {
      const { hash } = document.location;
      const { $ } = window;
      if (hash === '#recipients') {
        $('.evTab a[href="#recipients"]').tab('show');
      }
    }, 300);
  };

  render() {
    const tabOptions = [
      { name: 'Groups', targetValue: '#groups', isActive: true },
      { name: 'Recipients', targetValue: '#recipients', isActive: false },
    ];

    const {
      currentTab,
      groupsData,
      groupsResponse,
    } = this.state;

    if (Object.keys(groupsResponse).length === 0) {
      return <Preloader />;
    }

    return (
      <>
        <Header>
          Transactions
        </Header>
        <div className="row padding30">
          <div className="col-md-12">
            <Tabs tabOptions={tabOptions} />
            <div className="tab-content padding0">
              <div id="groups" className="tab-pane fade in active">
                <>
                  { (currentTab === 'transactionGroups') && (
                    <>
                      <div className="row top-buffer40 bottom-buffer30">
                        <div className="col-sm-3">
                          <TextSearchElement
                            setSearchTextValue={(searchText) => this.searchGroup(searchText)}
                            placeholder="Search groups"
                          />
                        </div>
                        <div className="col-sm-9" />
                      </div>
                      <table className="table table-striped">
                        <TransactionTableHeader sort={this.sort} headers={headers} />
                        <TransactionGroupsTableBody groups={groupsData} />
                      </table>
                    </>
                  )}
                </>
                <PaginationElement
                  transactionsResponse={groupsResponse}
                  goToPage={this.goToPage}
                />
              </div>
              <div id="recipients" className="tab-pane fade">
                <Transactions />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TransactionalGroups;
