const GroupReportHeaderStat = ({
  title, percentage, value, removePercentage = false,
}) => (
  <>
    <h5 className="margin0">{title}</h5>
    <h2 className="font400 margin0">
      <span>{ percentage }</span>
      {!removePercentage && <small>%</small>}
    </h2>
    <p className="text-gray font400">{ value }</p>
  </>
);

export default GroupReportHeaderStat;
