import { useState } from 'react';
import WebhookModal from './WebhookModal';

const NoWebhook = () => <div className="d-flex align-items-center height100p">Not setup</div>;
const WebhookDetails = ({ webhook }) => (
  <div>
    <table className="table">
      <tbody>
        <tr>
          <td className="col-md-2 font600 no-border">Post to URL</td>
          <td className="no-border">{webhook.url}</td>
        </tr>
        <tr>
          <td className="font600 no-border vertical-align-middle">Events</td>
          <td className="no-border">
            {webhook.event_types.map((event) => (
              <div
                key={event}
                className="display-inline-block padding10 pad-right20 pad-left20 shadow-sm border-radius-small bg-gray-lightest right-buffer20 text-capitalize"
              >
                {event}
              </div>
            ))}
          </td>
        </tr>
        <tr>
          <td className="font600 no-border">Active</td>
          <td className={`no-border ${webhook.status === 'enabled' ? 'text-success' : 'text-danger'}`}>
            <i className={`${webhook.status === 'enabled' ? 'seve-icon-check-mark-circle-two' : 'seve-icon-delete-3'} right-buffer2`} />
            {webhook.status === 'enabled' ? 'Sending event data' : 'Not sending event data'}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const Webhook = ({ webhookFromAPI }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="bottom-buffer40">
      <h5 className="font500">Webhooks</h5>
      <WebhookModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        initialWebhook={webhookFromAPI}
      />
      <div className="padding15 pad-left30 border-radius-base d-flex justify-content-between border-light">
        <div className="width100p right-buffer20">
          { webhookFromAPI.url === '' ? <NoWebhook /> : <WebhookDetails webhook={webhookFromAPI} /> }
        </div>
        <div className="width-100 text-right d-flex justify-content-between flex-column align-items-end">
          <div
            className="edit-item icon-stack icon-primary right-buffer10"
            role="button"
            onClick={() => setShowModal(true)}
            onKeyUp={() => setShowModal(true)}
            tabIndex={0}
          >
            <i className="seve-icon-circle-thin stack-one">
              <i className="seve-icon-pencil-2 stack-two" />
            </i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Webhook;
