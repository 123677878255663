import { Config } from '../Settings/SettingsConfigElement';

export default ({ username = '', apiKey = '', url = '' }) => {
  const getApiEndpoint = (endpointUrl) => (endpointUrl.includes('api.everlytic.net')
    ? `${endpointUrl}/v1/mail`
    : `${endpointUrl}/v1/mail`);

  const apiFields = [
    {
      label: 'Username',
      id: 'configuration-api-username',
      value: username,
      placeholder: 'no username',
    },
    {
      label: 'API Key',
      id: 'configuration-api-key',
      value: apiKey,
      placeholder: 'no api key',
      type: 'password',
    },
    {
      label: 'API Endpoint',
      id: 'configuration-api-host',
      value: `${getApiEndpoint(url)}`,
      placeholder: 'Host',
    },
  ];

  return (
    <div className="display-grid row-gap-15px">
      <header>
        <h5 className="semibold">
          API Key
        </h5>

        <p>
          If you already have an API key listed
          and you would like to use it for your
          application, you can copy it.
          This key will be available under settings.
        </p>
      </header>

      <div className="width780">
        <Config configValues={apiFields} />
      </div>
    </div>
  );
};
