import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Alert from '../../Utilities/Alert';
import TextSearchElement from '../../Components/TextSearchElement';
import ListingHeader from '../../Components/Listing/ListingHeader';
import ListingOverview from './ListingOverview';
import AttachmentService from '../attachment-service';
import PaginationElement from '../../Components/PaginationElement';
import TwoColumnHeader from '../../Layout/TwoColumnHeader';
import CustomizeBranding from '../Branding/CustomizeBranding';
import { valueIsNotSet } from '../../Utilities/check-value';

const AttachmentListing = () => {
  const [attachmentGroups, setAttachmentGroups] = useState([]);
  const [alert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(false);
  const [query, setQuery] = useState({ page: 1, limit: 8, name: '' });
  const [selectedGroup, setSelectedGroup] = useState(null);

  async function getGroup(groupQuery) {
    try {
      setIsLoading(true);
      const result = await AttachmentService.getGroups(groupQuery);
      setResults(result.data);
      setAttachmentGroups(result.data.data);
      if ((result.data?.data ?? []).length > 0) {
        setSelectedGroup(result.data.data[0]);
      }
    } catch (e) {
      // eslint-disable-next-line
    } finally {
      setIsLoading(false);
    }
  }

  const click = async () => {
    try {
      setIsLoading(true);
      await AttachmentService.deleteGroup(selectedGroup);
      setAttachmentGroups((previousState) => previousState
        .filter((group) => group.id !== selectedGroup.id));
    } catch (e) {
      // eslint-disable-next-line
    } finally {
      setIsLoading(false);
    }
  };

  const goToPage = async (page) => {
    setQuery((previousQuery) => ({ ...previousQuery, page }));
    await getGroup({ ...query, page });
  };

  const setSearchTextValue = async (name) => {
    setQuery((previousQuery) => ({ ...previousQuery, name }));
    await getGroup({ ...query, name });
  };

  useEffect(() => {
    getGroup(query);
  }, []);

  return (
    <>
      <Alert message="Template deleted successfully" type="success" showAlert={alert} />
      <TwoColumnHeader>
        <h4 className="font500">Online Attachments</h4>
        <CustomizeBranding />
      </TwoColumnHeader>
      <div className={`${isLoading ? 'disabled' : ''}`}>
        <div className="row">
          <div className="col-md-6 border-right-dark min-container-height d-flex justify-content-between flex-column">
            <div className="">
              <div className="row pad-top30 pad-left30 pad-bottom5">
                <div className="col-md-6">
                  <ListingHeader createText="Create Download Link" createLink="/online-attachments/create" deleteClick={click} />
                </div>
                <div className="col-md-6">
                  <TextSearchElement setSearchTextValue={setSearchTextValue} placeholder="Search" />
                </div>
              </div>
              <div className="pad-left30 pad-top10 min-listing-height display-flex flex-column justify-between">
                <div className="flex-grow">
                  <table className={`table table-hover bottom-buffer0 ${isLoading && 'disabled'}`}>
                    <thead>
                      <tr className="row" role="button">
                        <th className="col-sm-1">&nbsp;</th>
                        <th className="col-sm-7 text-left">
                          Link
                        </th>
                        <th className="col-sm-2 text-center">Files</th>
                        <th className="col-sm-2 text-center">Expires</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attachmentGroups.length > 0
                        ? attachmentGroups.map((group) => (
                          <tr
                            key={group.id}
                            className={`row cursor-pointer ${(group.id === (selectedGroup?.id ?? 0)) ? 'active' : ''}`}
                            onClick={() => setSelectedGroup(group)}
                          >
                            <td className="col-sm-1 text-center">
                              <input
                                type="checkbox"
                                role="button"
                              />
                            </td>
                            <td className="col-sm-7">
                              <h4 className="font-weight-400 bottom-buffer0 top-buffer0">{group.name}</h4>
                              <small>{`Created on ${moment(group.created_timestamp).format('D MMMM YYYY')}`}</small>
                            </td>
                            <td className="col-sm-2 text-center">{group.assets.length}</td>
                            <td className="col-sm-2 text-center">
                              <span>
                                {`${valueIsNotSet(group.options.expiry_timestamp)
                                  ? 'Never'
                                  : moment(group.options.expiry_timestamp).format('D MMMM YYYY')}`}
                              </span>
                            </td>
                          </tr>
                        ))
                        : (
                          <tr>
                            <td colSpan="5">No links available</td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                <PaginationElement goToPage={goToPage} transactionsResponse={results} />
              </div>
            </div>
          </div>
          <div className="col-md-6 bg-gray-lightest min-container-height">
            <ListingOverview selectedGroup={selectedGroup} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AttachmentListing;
