import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-twilight';

const TemplateEditor = ({ onChange, value = '' }) => (
  <AceEditor
    debounceChangePeriod={2000}
    mode="html"
    theme="twilight"
    onChange={onChange}
    name="aceEditor"
    editorProps={{ $blockScrolling: true }}
    value={value}
  />
);

export default TemplateEditor;
