import { Component } from 'react';
import SideMenu from './SideMenu';

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "NavigationUiRoute",
      "updateHash"
    ]
  }
] */

class NavContainer extends Component {
  constructor(props) {
    super(props);

    const { name: className } = NavContainer;

    this.state = {
      title: `Default ${className} Title`,
      className,
      navigationProps: [],
    };

    this.NavigationUi = this.NavigationUi.bind(this);

    this.updateNavigationProps = this.updateNavigationProps.bind(this);
  }

  get activeNavigationProps() {
    const { state } = this;
    const { navigationProps = [] } = state;
    const activeNavigationProps = navigationProps.find(({ active }) => active) || {};

    return activeNavigationProps;
  }

  NavigationUi() {
    const { state, props, activeNavigationProps } = this;
    const { title: defaultTitle } = state;
    const { title: secondaryDefaultTitle } = props;
    const {
      Component: ActiveComponent,
      component: currentComponent,
      title = secondaryDefaultTitle || defaultTitle,
      className = '',
    } = activeNavigationProps || {};
    const component = ActiveComponent ? ActiveComponent(activeNavigationProps)
      : currentComponent;

    return (
      <div className={`has-sidemenu ${className}`}>
        <div className="pad-left15">
          <div className="row bg-white pad-left15 pad-right15 pad-top15 pad-bottom15 border-bottom-light">
            <div className="col-sm-6">
              <div className="top-buffer10">
                <h4 className="semibold">{ title }</h4>
              </div>
            </div>
          </div>

          <div className="top-buffer20 pad-left15 pad-right30">
            {component}
          </div>
        </div>
      </div>
    );
  }

  updateNavigationProps(params) {
    const { state } = this;
    const { navigationProps = [] } = params;
    const newState = {
      ...state,
      navigationProps,
    };

    this.setState(newState);
  }

  render() {
    const {
      state,
      props,
      updateNavigationProps,
      NavigationUi,
    } = this;
    const { className, navigationProps } = state;
    const {
      id = '',
      action = {},
      iconClass,
      primaryIconClass,
      secondaryIconClass,
      navigation,
    } = props;

    return (
      <div id={id} className={className}>
        <SideMenu
          action={action}
          iconclassName={iconClass}
          primaryIconClass={primaryIconClass}
          secondaryIconClass={secondaryIconClass}
          navigation={navigation}
          navigationProps={navigationProps}
          updateNavigationProps={updateNavigationProps}
        />

        <NavigationUi />
      </div>
    );
  }
}

export default NavContainer;
