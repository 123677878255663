import Row from './Row';

const Content = ({ children }) => (
  <Row>
    <div className="col-md-12">
      {children}
    </div>
  </Row>
);

export default Content;
