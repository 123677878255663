import { Component, createRef } from 'react';
import { Formik, Form, Field } from 'formik';
import regex from '../../Utilities/regex';
import SmallLoader from '../../Loader/SmallLoader';

const { validDomain } = regex;

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "getForm",
      "validate",
      "domainErrorMessage",
      "getInputField"
    ]
  }
] */
class AddDomainModalBody extends Component {
  static ERROR_MESSAGE = {
    domain: 'Please enter a valid domain name',
  };

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      successMessage: '',
      isLoading: false,
      initialValues: {
        domain: '',
        email: '',
      },
      values: {},
      verificationResponse: {},
    };
    this.backButton = createRef();

    this.getForm = this.getForm.bind(this);
    this.handleVerificationResponse = this.handleVerificationResponse.bind(this);
    this.validate = this.validate.bind(this);
  }

  async handleVerificationResponse(params) {
    this.setState({ isLoading: false });
    const { verificationResponse = {} } = params;
    const { error, successMessage } = verificationResponse;
    const { state, props, backButton } = this;
    const { handleModalClosed = () => {} } = props;
    const newState = {
      ...state,
      verificationResponse,
      errorMessage: verificationResponse?.error?.message ?? '',
      successMessage: successMessage ?? '',
    };

    if (!error) {
      setTimeout(async () => {
        backButton.current.click();
        handleModalClosed();
      }, 3000);
    }

    setTimeout(() => this.setState({ errorMessage: '' }), 5000);

    this.setState(newState);
  }

  getInputField(params) {
    const {
      id,
      placeholder,
      addon,
      handleChange,
    } = params;

    return (
      <div className="input-group col-md-6">
        <Field
          type="text"
          className="form-control font-weight-400"
          id={id}
          name={id}
          placeholder={placeholder}
          onChange={handleChange}
        />

        <div className="input-group-addon text-white">
          {addon}
        </div>
      </div>
    );
  }

  getForm(params) {
    const {
      handleChange,
      values,
    } = params;
    const { domain = '' } = values;
    const { state, getInputField } = this;
    const { verificationResponse, errorMessage, isLoading } = state;
    const formClass = (errorMessage !== '' || verificationResponse.error)
      ? 'has-error' : '';

    const domainInputField = getInputField({
      id: 'domain',
      placeholder: 'Enter your domain name',
      addon: <i className="seve-icon-asterisk" />,
      handleChange,
    });

    const emailInputField = domain && (
      <div className="top-buffer20">
        <label htmlFor="email">
          <span className="display-inline-block bottom-buffer5">Email:</span>
        </label>
        {getInputField({
          id: 'email',
          placeholder: 'Email for verification',
          addon: `@${domain}`,
          handleChange,
        })}
      </div>
    );

    return (
      <Form>
        <div className={`form-group ${formClass}`}>
          <label htmlFor="domain">
            <span className="display-inline-block bottom-buffer5 right-buffer5">
              Enter the domain you send from:
            </span>
            <SmallLoader isLoading={isLoading} />
          </label>
          {domainInputField}
        </div>

        {emailInputField}
      </Form>
    );
  }

  validate(values) {
    const { state } = this;
    const { domain = '' } = values;
    const { ERROR_MESSAGE } = AddDomainModalBody;
    const errors = {
      domain: domain && !domain.match(validDomain) ? ERROR_MESSAGE.domain : '',
    };
    const newState = {
      ...state,
      values,
      verificationResponse: {},
      errorMessage: errors.domain,
    };

    this.setState(newState);

    return errors;
  }

  render() {
    const {
      state,
      props,
      backButton,
      validate,
      getForm,
      handleVerificationResponse,
    } = this;
    const {
      initialValues,
      values,
      errorMessage,
      successMessage,
      isLoading,
    } = state;
    const {
      show,
      handleSendVerificationEmailClicked = () => {},
      handleModalClosed = () => {},
    } = props;
    const { email: username, domain } = values;
    const email = username && domain && `${username}@${domain}`;
    const enabledClass = !email || errorMessage === AddDomainModalBody.ERROR_MESSAGE.domain || isLoading ? 'disabled' : '';
    const onSendClick = () => {
      this.setState({ isLoading: true });
      handleSendVerificationEmailClicked(
        { email, domain },
        handleVerificationResponse,
      );
    };

    return show && (
      <>
        <div className="modal-body panel-body">
          <div className="height-40">
            {errorMessage !== '' && (
            <div className="bg-brand-danger text-white padding10">
              <span className="">{errorMessage}</span>
            </div>
            )}
            {successMessage !== '' && (
            <div className="bg-brand-success text-white padding10">
              <span className="">{successMessage}</span>
            </div>
            )}
          </div>
          <div className="pad-top15 pad-left40 pad-right40 pad-bottom40">
            <Formik initialValues={initialValues} validate={validate}>
              {getForm}
            </Formik>
          </div>
        </div>

        <div className="pad-top20 pad-bottom20 pad-left35 pad-right35 d-flex justify-content-between bg-gray-lightest">
          <button
            ref={backButton}
            type="button"
            className="btn btn-link"
            data-dismiss="modal"
            onClick={handleModalClosed}
          >
            Back
          </button>

          <div>
            <button
              type="submit"
              className={`btn btn-primary ${enabledClass}`}
              onClick={onSendClick}
            >
              Send Verification Email
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default AddDomainModalBody;
