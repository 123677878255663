import Service from './Service';
import initialState from './UiData/TransactionalGroupReport.json';
import NavUi from '../Layout/NavUi';
import TransactionalGroupReportType from './TransactionalGroupReportType';
/* eslint max-len: ["error" , { "code": 220 }] */

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "getSearchResults",
    ]
  }
] */
class TransactionalGroupReport extends NavUi {
  static DATA_KEY = {
    searchResults: 'searchResults',
  };

  constructor(props) {
    super(props);

    const { name: id } = TransactionalGroupReport;
    const state = this.getCurrentState();

    this.state = {
      ...state,
      id,
    };

    this.getSearchResultParams = undefined;

    this.updateSearchResults = this.updateSearchResults.bind(this);
  }

  getCurrentState() {
    const state = {
      ...initialState,
    };
    const { navigation } = state;

    navigation.sent.Component = (props) => this.TransactionalGroupReportType(props);

    return state;
  }

  TransactionalGroupReportType(props) {
    const { key, ...config } = props;
    const {
      state,
      updateSearchResults,
    } = this;
    const {
      data = {},
      transactionsTableHeader,
    } = state;
    const { [key]: currentTransactionsTableHeader } = transactionsTableHeader;
    const { searchResults } = data;

    return (
      <TransactionalGroupReportType
        type={key}
        config={config}
        searchResults={searchResults}
        transactionsTableHeader={currentTransactionsTableHeader}
        handledActive={updateSearchResults}
      />
    );
  }

  async getSearchResults(params) {
    const { type } = params;
    const searchResults = await Service.getTransactionGroupsDummyData({
      type,
    });

    return searchResults;
  }

  async handleTransactionalGroupReportRendered(params) {
    const { DATA_KEY } = TransactionalGroupReport;

    this.resetData({ key: DATA_KEY.searchResults }, () => this.updateSearchResults(params));
  }

  async updateSearchResults(params) {
    const { getSearchResultParams: currentRequestParams } = this;
    const serialisedRequestParams = JSON.stringify(params);

    if (currentRequestParams !== serialisedRequestParams) {
      this.getSearchResultParams = serialisedRequestParams;

      this.setSearchResults(params);
    }
  }

  async setSearchResults(params) {
    const { state } = this;
    const { data = {} } = state;
    const searchResults = await this.getSearchResults(params);
    const newState = {
      ...state,
      data: {
        ...data,
        searchResults,
      },
    };

    this.setState(newState);
  }
}

export default TransactionalGroupReport;
