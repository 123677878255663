import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../Auth/AuthenticationContext';

const TopBar = () => {
  const [authentication] = useContext(AuthenticationContext);
  const {
    enterprise, customer, user,
  } = authentication;

  const isSuperUser = () => (
    user.user_can_super_administrator === 'yes' || user.user_can_customer_administrator === 'yes'
  );

  const canSendTransactionalSMS = () => (
    enterprise.enterprise_sms_enabled === 'yes'
    && (user.user_can_sms_edit === 'yes' || user.user_can_message_report_access === 'yes')
  );

  const getLogo = () => ((enterprise?.enterprise_logo?.length !== 0) ?? false
    ? `${enterprise.enterprise_pmailer_url}/users/enterprise_logos/${enterprise.enterprise_logo}`
    : '/default-logo.png');

  return (
    <nav className="navbar navbar-default navbar-fixed-top" style={{ backgroundColor: enterprise.enterprise_header_colour }}>
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <Link to="/" className="navbar-brand">
            <img
              srcSet={getLogo()}
              alt={customer.customer_name}
            />
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

          <ul className="nav navbar-nav navbar-right">
            <li id="loading" className="bg-info">
              <a href="/" className="right-buffer10">
                Loading
                {' '}
                <i className="icon seve-icon-loading animate-spin position-spinner-top icon-16" style={{ display: 'none' }} />
              </a>
            </li>
            <li className="dropdown">
              <a
                href="/"
                className="dropdown-toggle"
                data-toggle="dropdown"
                data-hover="dropdown"
                aria-expanded="false"
              >
                <img
                  className="stick-nav-profile-pic"
                  src={user.user_profile_picture ?? 'https://www.gravatar.com/avatar/b9a0b13d0b5a7a9b01106d59cf8ac0fe.jpg?d=mm&amp;r=g&amp;s=30'}
                  width="30"
                  alt="Profile"
                  height="30"
                />
                <span id="stick-nav-username">
                  {user.user_name}
                  {' '}
                </span>
                <i className="seve-icon-caret-down icon-s-small" />
              </a>
              <ul className="dropdown-menu" role="menu">
                <li className="submenu-title">My Profile</li>
                <li>
                  <a href={`${enterprise.enterprise_pmailer_url}/users/overview`}>Users &amp; Access Rights</a>
                </li>
                {isSuperUser() ? (
                  <>
                    <li>
                      <a href={`${enterprise.enterprise_pmailer_url}/customers`}>Customer Profile</a>
                    </li>
                    <li>
                      <a href={`${enterprise.enterprise_pmailer_url}/customers/branding`}>Branding</a>
                    </li>
                  </>
                ) : null}
                {user.user_can_domains_access === 'yes' ? (
                  <li>
                    <a href={`${enterprise.enterprise_pmailer_url}/domains`}>Domains</a>
                  </li>
                ) : null}
                <li>
                  <a href={`${enterprise.enterprise_pmailer_url}/integrations`}>Integrations</a>
                </li>
                <li>
                  <a href={`${enterprise.enterprise_pmailer_url}/stores/listing`}>Stores</a>
                </li>
                <li className="submenu-title">Billing</li>
                <li>
                  <a href={`${enterprise.enterprise_pmailer_url}/billing/quota`}>Current Quota</a>
                </li>
                {user.user_can_archive_report_access === 'yes' ? (
                  <li>
                    <a href={`${enterprise.enterprise_pmailer_url}/history`}>Account Logs</a>
                  </li>
                ) : null }
                {isSuperUser() ? (
                  <>
                    <li className="submenu-title">Enterprise &amp; Resellers</li>
                    <li>
                      <a href={`${enterprise.enterprise_pmailer_url}/customers`}>My Customers</a>
                    </li>
                    <li>
                      <a href={`${enterprise.enterprise_pmailer_url}/enterprises`}>Enterprise Profile</a>
                    </li>
                  </>
                ) : null}
                {user.user_can_enterprise_templates === 'yes' ? (
                  <li>
                    <a href={`${enterprise.enterprise_pmailer_url}/enterprise-templates/${enterprise.enterprise_id}`}>Enterprise Templates</a>
                  </li>
                ) : null}
              </ul>
            </li>

            <li className="dropdown">
              <a href="/" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown">
                <span>Help &amp; Support </span>
                <i className="seve-icon-caret-down icon-s-small" />
              </a>
              <ul className="dropdown-menu" role="menu">
                <li>
                  <a href={`${enterprise.enterprise_pmailer_url}/support`}>
                    Contact Support
                  </a>
                </li>
                <li>
                  <a href="http://help.everlytic.com/" target="_blank" rel="noreferrer">
                    Knowledge Base
                    {' '}
                  </a>
                </li>
              </ul>
            </li>

            <li className="bulk">
              <a
                href={enterprise.enterprise_pmailer_url}
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="Bulk Email &amp; SMS"
              >
                <i className="seve-icon-cube hidden-xs" />
                <span className="visible-xs">Bulk Email &amp; SMS</span>
              </a>
            </li>

            <li className="transactional">
              <a
                href={`${enterprise.enterprise_pmailer_url}/transactions`}
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="Transactional Email"
              >
                <i className="seve-icon-barcode hidden-xs" />
                <span className="visible-xs">Transactional Email</span>
              </a>
            </li>

            <li className="transactional-neutron on">
              <Link
                to="/"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="Transactional (Beta)"
              >
                <i className="seve-icon-credit-card hidden-xs" />
                <span className="visible-xs">Transactional (Beta)</span>
              </Link>
            </li>

            {canSendTransactionalSMS() && (
              <li className="sms">
                <a
                  href={`${enterprise.enterprise_pmailer_url}/transaction-sms/redirect`}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="Transactional SMS"
                >
                  <i className="seve-icon-commenting-o hidden-xs" />
                  <span className="visible-xs">Transactional SMS</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default TopBar;
