import React from 'react';
import Preloader from '../Layout/Preloader';
import getAxiosClient from '../Utilities/get-axios-client';
import TransactionTableElement from './TransactionTableElement';

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionsResponse: null,
      isLoading: false,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      await this.getTranscations({ reportType: 'transaction' });
    } catch (e) {
      // need to catch error
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getTranscations = async (searchQuery) => {
    const response = await getAxiosClient().post(
      'v1/transaction/search',
      searchQuery,
      { withCredentials: true },
    );
    this.setState({ transactionsResponse: response?.data ?? null });
  };

  setSearchQueryValue = async (searchQuery) => {
    await this.getTranscations(searchQuery);
  };

  render() {
    const { transactionsResponse, isLoading } = this.state;
    if (!transactionsResponse || isLoading) {
      return <Preloader />;
    }

    return (
      <>
        <TransactionTableElement
          transactionsResponse={transactionsResponse}
          setSearchQueryValue={this.setSearchQueryValue}
        />
      </>
    );
  }
}

export default Transactions;
