import { useRouteMatch } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import getAxiosClient from '../Utilities/get-axios-client';
import ModalAlert from '../Utilities/ModalAlert';
import SmallLoader from '../Loader/SmallLoader';
import UnsubscribeAction from './UnsubscribeAction';

const HeaderUnsubscribe = () => {
  const match = useRouteMatch('/header-unsubscribe/:transactionId');

  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [showAlert, setShowAlert] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUnsubscribed, setHasUnsubscribed] = useState(false);

  const handle = async (transactionId) => {
    try {
      setAlertMessage('');
      setHasError(false);
      setShowAlert(false);
      setIsLoading(true);
      const baseURL = process.env.REACT_APP_NEUTRON_PUBLIC_BASE_URL;
      const response = await getAxiosClient().post(
        `${baseURL}/v1/${hasUnsubscribed ? 'resubscribe' : 'unsubscribe'}`,
        { transaction_id: transactionId },
      );
      if (response?.data?.redirect) {
        window.location.replace(response.data.redirect);
      }
      setAlertMessage(response?.data?.message ?? 'Success');
      setShowAlert(true);
      setAlertType('success');
      setHasUnsubscribed(!hasUnsubscribed);
    } catch (e) {
      setShowAlert(true);
      setHasError(true);
      setAlertMessage(e?.response?.data?.message ?? 'Error');
      setAlertType('danger');
    }
    setShowAlert(false);
    setIsLoading(false);
  };

  const toggleSubscribe = async () => handle(match.params.transactionId);

  useEffect(() => {
    toggleSubscribe().then((r) => r).catch((r) => r.toJSON());
  }, [match.params.transactionId]);

  return (
    <div className="container text-center">
      <div className="padding-50">
        <SmallLoader isLoading={isLoading} size={50} />
        <ModalAlert message={alertMessage} type={alertType} showAlert={showAlert} />
        {!isLoading && (
          <>
            {!hasError
              ? (
                <>
                  <UnsubscribeAction
                    hasUnsubscribed={hasUnsubscribed}
                    toggleSubscribe={toggleSubscribe}
                  />
                </>
              )
              : <h2>Error</h2>}
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderUnsubscribe;
