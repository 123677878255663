export default () => (
  <header>
    <h4 className="semibold">
      Configure using API or SMTP
    </h4>

    <p>
      Choose the best suited configuration for sending transactional emails.
    </p>
  </header>
);
