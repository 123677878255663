import DateRangePicker from 'react-bootstrap-daterangepicker';
import React, { useState } from 'react';
import moment from 'moment';

const TransactionDatePickerSearchElement = (props) => {
  const {
    defaultDateValue = null,
    setCurrentDateRangeValue = () => {},
  } = props;

  const transformDateObject = (dateValue) => ((dateValue === null)
    ? null
    : { startDate: dateValue.start, endDate: dateValue.end });

  const [currentDateRange, setCurrentDateRange] = useState(
    transformDateObject(defaultDateValue) ?? {
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
    },
  );

  const handleApply = (event, picker) => {
    const newDateRange = {
      start: picker.startDate,
      end: picker.endDate,
    };
    setCurrentDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
    setCurrentDateRangeValue(newDateRange);
  };

  return (
    <DateRangePicker onApply={handleApply} initialSettings={currentDateRange}>
      <div className="input-group bg-white">
        <div
          id="reportrange"
          className="border-2 form-control font600 text-primary width100p bg-none d-flex align-items-center"
          role="button"
        >
          <span>{`${currentDateRange.startDate.format('MMMM D, YYYY')} - ${currentDateRange.endDate.format('MMMM D, YYYY')}`}</span>
        </div>
        <span className="input-group-addon">
          <i className="icon seve-icon-calendar" />
        </span>
      </div>
    </DateRangePicker>
  );
};

export default TransactionDatePickerSearchElement;
