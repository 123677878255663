import { Component, createRef } from 'react';

const { clipboard } = navigator;

class ConfigurationApiField extends Component {
  static copyValue(value) {
    clipboard.writeText(value);
  }

  constructor(props) {
    super(props);

    this.state = {
      label: '',
      value: '',
      type: 'text',
      id: '',
      placeholder: '',
      tooltip: `Copy ${props.label}`,
      ...props,
    };

    this.input = createRef();

    this.handleCopyButtonClick = this.handleCopyButtonClick.bind(this);
  }

  handleCopyButtonClick() {
    const { input } = this;
    input.current.focus();
  }

  render() {
    const { state } = this;
    const {
      label,
      id,
      value,
      tooltip,
      type,
      placeholder,
    } = state;
    const currentPlaceholder = !value ? placeholder : '';

    return (
      <div>
        <label className="input-group copy-paste no-hover" htmlFor={id}>
          <span className="input-group-addon no-hover ng-binding ng-scope" style={({ minWidth: '130px' })}>
            {label}
          </span>

          <input
            readOnly
            ref={this.input}
            id={id}
            type={type}
            value={value}
            placeholder={currentPlaceholder}
            className="form-control bg-white align-items-center cursur-pointer"
            onFocus={() => ConfigurationApiField.copyValue(value)}
          />

          <div
            className="input-group-btn"
          >
            <button
              type="button"
              className="btn btn-primary inverted"
              onClick={this.handleCopyButtonClick}
              title={tooltip}
              data-toggle="tooltip"
              data-placement="top"
              data-original-title={tooltip}
            >
              <i className="icon seve-icon-files-4" />
            </button>
          </div>
        </label>
      </div>
    );
  }
}

export default ConfigurationApiField;
