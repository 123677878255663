import { forwardRef } from 'react';

function ConfigurationApi(props, ref) {
  const {
    activeClass,
    activeHeaderClass,
    onClick,
  } = props;

  return (
    <div ref={ref} onClick={onClick} onKeyUp={onClick} role="button" tabIndex={0} className={`onboarding-configure-button border-light padding20 ${activeClass}`}>
      <div className="col-md-3">
        <h4 className={`text-center semibold header ${activeHeaderClass}`}>
          API
        </h4>
      </div>
      <div className="text-center col-md-6">
        The most flexible, most popular way to send email.
        <br />
        (Use languages like Ruby, Python, PHP, C# and more).
      </div>
      <div className="col-md-3">
        <button type="button" className="btn btn-primary btn-block">Get Key</button>
      </div>
    </div>
  );
}

export default forwardRef(ConfigurationApi);
