const shortenNumber = (numberToBeShortened) => {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
  // eslint-disable-next-line no-bitwise
  const tier = Math.log10(numberToBeShortened) / 3 | 0;
  if (tier === 0) {
    return numberToBeShortened;
  }
  const suffix = SI_SYMBOL[tier];
  // eslint-disable-next-line no-restricted-properties
  const scale = Math.pow(10, tier * 3);
  const scaled = numberToBeShortened / scale;
  return scaled.toFixed(0) + suffix;
};

export default shortenNumber;
