import makeAxiosCall from '../Utilities/make-axios-call';

const getOverviewData = async (dateRange, setData, reportType) => {
  try {
    const getGroupUUID = window.location.pathname.split('/')[2] ?? '';
    const overview = await makeAxiosCall(
      '/v1/transaction-groups/overview',
      dateRange,
      { group_uuid: getGroupUUID, report_type: reportType },
    ).catch(() => {});

    setData({ overview: overview.data });
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export default getOverviewData;
