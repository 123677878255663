import { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'formik';

class PillButtonOption extends Component {
  static EVENT_NAME = {
    optionActive: 'shown.bs.tab',
  };

  static CLASS_NAME = {
    active: 'active',
  };

  constructor(props) {
    super(props);

    this.link = createRef();
  }

  componentDidMount() {
    const { $ } = window;
    const { EVENT_NAME } = PillButtonOption;
    const link = this.link.current;

    $(link).on(EVENT_NAME.optionActive, (event) => this.selectOption({ event }));

    this.handleInitialValue();
  }

  handleInitialValue() {
    const { CLASS_NAME } = PillButtonOption;
    const { props } = this;
    const { initialValue, value } = props;
    const link = this.link.current;
    const parent = link.parentElement;

    if (value === initialValue && parent) {
      parent.classList.add(CLASS_NAME.active);
    }
  }

  selectOption() {
    const { props } = this;
    const { value } = props;
    const link = this.link.current;
    const parent = link.closest('.pill-button-options');
    const labelSelector = `label[data-value="${value}"]`;
    const label = parent && parent.querySelector(labelSelector);

    if (label) {
      label.click();
    }
  }

  render() {
    const { location } = window;
    const { props } = this;
    const { hash: to } = location;
    const {
      name,
      value,
    } = props;
    const controls = `${name}_${value}`;
    const component = (
      <li role="presentation" className="min-width7em text-center text-transform-capitalize">
        <Link
          ref={this.link}
          className="height-40 padding0"
          to={to}
          aria-controls={controls}
          role="tab"
          data-toggle="tab"
        >
          <span>
            {value}
          </span>
        </Link>
      </li>
    );

    return component;
  }
}

function InputOption(props) {
  const {
    name,
    value,
  } = props;

  return (
    <li>
      <label htmlFor={value} data-value={value}>
        <Field type="radio" name={name} value={value} id={value} />
        {value}
      </label>
    </li>
  );
}

export default (props) => {
  const {
    id,
    containerClass = '',
    options = [],
    initialValue = undefined,
  } = props;
  const pillButtonOptions = options.map((value) => (
    <PillButtonOption
      key={value}
      name={id}
      value={value}
      initialValue={initialValue}
    />
  ));
  const inputOptions = options
    .map((value) => <InputOption key={value} name={id} value={value} />);
  const component = !pillButtonOptions.length && !inputOptions.length ? null : (
    <ul id={id} className={`pill-button-options nav nav-pills nav-pill-text-links ${containerClass}`} role="tablist">
      {pillButtonOptions}
      {inputOptions}
    </ul>
  );

  return component;
};
