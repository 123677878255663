import { Field } from 'formik';

export default (props) => {
  const {
    id,
    value,
    className = '',
  } = props;

  return (
    <>
      <label htmlFor={id} className={className}>
        <Field type="date" id={id} name={id} value={value} />

        {id}
      </label>
    </>
  );
};
