import React from 'react';
import { withRouter } from 'react-router';
import Header from '../../Layout/Header';
import Preloader from '../../Layout/Preloader';
import TemplateCreateHeader from './TemplateCreateHeader';
import TemplateEditor from './TemplateEditor';
import TemplateCreateDefaultsForm from './TemplateCreateDefaultsForm';
import getAxiosClient from '../../Utilities/get-axios-client';
import Alert from '../../Utilities/Alert';

/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */

class TemplateCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 12,
      isLoading: false,
      alertType: '',
      errorMessage: '',
      showAlert: false,
      domains: [],
      template: {
        id: '',
        name: 'Untitled Template',
        status: 'draft',
        from_name: '',
        from_email: '',
        reply_email: '',
        subject: '',
        description: '',
        content_html: '',
      },
    };
  }

  async componentDidMount() {
    document.title = 'Create Template';
    await this.setState({ isLoading: true });
    try {
      const response = await Promise.all([
        getAxiosClient().get('v1/domains'),
        getAxiosClient().get(`v1/templates/${this.getHash()}`),
      ]);

      const templateData = ((this.isNewTemplate()) && this.getHash() !== 'new')
        ? {
          ...response[1].data.template,
          status: 'draft',
          name: `${response[1].data.template.name} (Copy)`,
        }
        : response[1].data.template;

      await this.setState({
        domains: response[0].data.domains,
        template: templateData,
      });
      await this.setState({ isLoading: false });
    } catch (e) {
      await this.setState({
        isLoading: false,
        showAlert: true,
        alertType: 'danger',
        errorMessage: e?.response[0]?.data?.message ?? 'Error in getting templates',
      });
    }
  }

  updateTemplateDefault = async (templateDefault) => {
    await this.setState((previousState) => ({
      template: {
        ...previousState.template,
        ...templateDefault,
      },
    }));
  };

  onEditorChange = async (value) => {
    await this.setState((previousState) => ({
      template: {
        ...previousState.template,
        content_html: value,
      },
    }));
  };

  isNewTemplate = () => window.location.href.split('/')[4] === 'create';

  getHash = () => window.location.href.split('/')[5];

  publish = async () => {
    await this.setState((previousState) => ({
      template: {
        ...previousState.template,
        status: 'published',
      },
    }), () => this.save(true));
  };

  save = async (isPublish = false) => {
    const { template } = this.state;
    await this.setState({ isLoading: true, showAlert: false });
    let hash = this.getHash();

    try {
      const response = await getAxiosClient().request({
        url: `v1/templates${this.isNewTemplate() ? '' : `/${hash}`}`,
        method: this.isNewTemplate() ? 'POST' : 'PUT',
        data: template,
      });
      hash = response.data.template.id;

      await this.setState({
        template: response.data.template,
        isLoading: false,
        showAlert: true,
        alertType: 'success',
        errorMessage: 'Save complete',
      }, () => { this.saveCallback(isPublish, hash); });
    } catch (e) {
      await this.setState({
        isLoading: false,
        showAlert: true,
        alertType: 'danger',
        errorMessage: e.response.data.message ?? 'Error in saving template',
      });
    }
  };

  saveCallback(isPublish, hash) {
    const { match, history } = this.props;
    if (isPublish) {
      setTimeout(() => {
        history.push('/templates');
      }, 1000);
    }
    if (this.isNewTemplate()) {
      window.history.replaceState(null, 'Edit Templates', match.path.replace('/create/:hash', `/edit/${hash}`));
    }
  }

  render() {
    const {
      data,
      template,
      domains,
      isLoading,
      showAlert,
      alertType,
      errorMessage,
    } = this.state;
    const { content_html, ...templateWithoutHTML } = template;
    const { updateTemplateDefault, onEditorChange } = this;
    if (data == null || domains.length === 0) {
      return (
        <>
          <Alert message={errorMessage} type={alertType} showAlert={showAlert} timeout={200000} />
          <Preloader />
        </>
      );
    }

    return (
      <div className={`${isLoading ? 'disabled' : ''}`}>
        <Alert message={errorMessage} type={alertType} showAlert={showAlert} />
        <>
          <Header noHeader>
            <div className="">
              <TemplateCreateHeader
                template={template}
                updateTemplateDefault={updateTemplateDefault}
              />
            </div>
          </Header>
          <div className="row d-flex">
            <div className="col-md-3 bg-gray-lightest pad-right0 min-container-height overflow-y-scroll">
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="panel panel-flat">
                  <div className="panel-heading pad-left30 pad-right30 pad-top20 pad-bottom20 bg-gray-lightest" role="tab" id="headingOne">
                    <h5 className="panel-title h5">
                      <a
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Template Defaults
                      </a>
                      <i
                        className="seve-icon-question-mark cursor-pointer left-buffer10 text-muted"
                        data-toggle="tooltip"
                        data-placement="right"
                        title=""
                        data-original-title="Email templates will use default settings when none is specified in API or SMTP requests"
                      />
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    className="panel-collapse collapse in"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                  >
                    <div className="panel-body bg-gray-lightest inset-shadow-accordion pad-left30 pad-right30 pad-top20 pad-bottom50 ">
                      <TemplateCreateDefaultsForm
                        template={templateWithoutHTML}
                        updateTemplateDefault={updateTemplateDefault}
                        domains={domains}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 pad-left0">
              <TemplateEditor onChange={onEditorChange} value={template.content_html} />
            </div>
          </div>
        </>
        <div className="neutron-footer">
          <div className="text-right pad-left20 pad-right20">
            <button
              type="button"
              className="btn btn-link gray padding0 right-buffer20"
              onClick={() => this.save()}
              onKeyUp={() => this.save()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-next"
              onClick={this.publish}
              onKeyUp={this.publish}
            >
              Publish
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TemplateCreate);
