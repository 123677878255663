import { Component } from 'react';
import { Formik, Form } from 'formik';
import FormTextInput from './FormTextInput';
import FormPillButtonOption from './FormPillButtonOption';
import FormDate from './FormDate';

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "InputField",
    ]
  }
] */
class FormComponent extends Component {
  static INPUT_FIELD_TYPE = {
    textInput: 'text-input',
    pillButtonOptions: 'pill-button-options',
    date: 'date',
  };

  constructor(props) {
    super(props);

    this.getForm = this.getForm.bind(this);
    this.InputField = this.InputField.bind(this);
  }

  get initialValues() {
    const { props } = this;
    const { inputFields, inputValues = {} } = props;
    const initialValues = {};

    inputFields.forEach(({ id, value }) => {
      initialValues[id] = value || inputValues[id] || '';
    });

    return initialValues;
  }

  getForm(params) {
    const {
      handleChange,
    } = params;
    const { props, InputField } = this;
    const {
      inputFields: inputFieldParams = [],
      className = '',
      children = null,
    } = props;
    const inputFields = inputFieldParams
      .map((currentInputFieldParams) => ({ ...currentInputFieldParams, handleChange }))
      .map(InputField)
      .filter((inputField) => inputField);

    return (
      <Form className={className}>
        {inputFields}

        {children}
      </Form>
    );
  }

  InputField(params) {
    const { props } = this;
    const {
      inputValues = {},
      validate = () => {},
    } = props;
    const {
      INPUT_FIELD_TYPE,
    } = FormComponent;
    const {
      id,
      value,
      default: defaultValue,
      type = '',
      containerClass,
      placeholder,
      iconClass,
      handleButtonClick,
      options,
      className,
    } = params;

    let component = null;

    if (type === INPUT_FIELD_TYPE.textInput) {
      component = (
        <FormTextInput
          id={id}
          key={id}
          containerClass={containerClass}
          placeholder={placeholder}
          iconClass={iconClass}
          handleButtonClick={handleButtonClick}
        />
      );
    } else if (type === INPUT_FIELD_TYPE.pillButtonOptions) {
      component = (
        <FormPillButtonOption
          id={id}
          key={id}
          initialValue={defaultValue || inputValues[id]}
          options={options}
          handleButtonClick={validate}
        />
      );
    } else if (type === INPUT_FIELD_TYPE.date) {
      component = (
        <FormDate
          id={id}
          key={id}
          value={value}
          className={className}
        />
      );
    }

    return component;
  }

  render() {
    const { props, initialValues } = this;
    const {
      validate = () => {},
    } = props;

    return (
      <Formik initialValues={initialValues} validate={validate}>
        {this.getForm}
      </Formik>
    );
  }
}

export default FormComponent;
