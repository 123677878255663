import React, { useState, useRef } from 'react';
import activateTooltip from '../../Utilities/activate-tooltip';

activateTooltip();

const CreateAttachmentHeader = ({ name, updateName }) => {
  const [showTitleEditor, setShowTitleEditor] = useState(false);
  const textInput = useRef(null);

  const onTitleChange = async () => {
    updateName(textInput.current.value);
  };

  const editTitle = async () => {
    await setShowTitleEditor(!showTitleEditor);
    if (!showTitleEditor) {
      textInput.current.focus();
    }
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex">
        {
          showTitleEditor
            ? (
              <input
                type="text"
                ref={textInput}
                className="form-control width300"
                value={name}
                onChange={onTitleChange}
              />
            )
            : <div className="height-40 width300 d-flex align-items-center">{name}</div>
        }
        <div className="height-40 d-flex items-center left-buffer20 cursur-pointer small text-primary">
          <u
            role="button"
            tabIndex={0}
            onClick={editTitle}
            onKeyDown={editTitle}
          >
            { showTitleEditor ? 'Save' : 'Edit' }
          </u>
        </div>
      </div>
    </div>
  );
};

export default CreateAttachmentHeader;
