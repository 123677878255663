const defaultDashboardPieChartOptions = {
  chart: {
    type: 'pie',
    backgroundColor: 'transparent',
    height: 210,
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      dataLabels: {
        enabled: false,
      },
    },
  },
  dataLabels: {
    enabled: false,
    formatter: () => false,
  },
  series: [],
  title: { text: '' },
  subtitle: { text: '' },
  credits: {
    enabled: false,
  },
};

// eslint-disable-next-line import/prefer-default-export
export { defaultDashboardPieChartOptions };
