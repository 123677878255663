import { Component } from 'react';
import { AuthenticationContext } from '../Auth/AuthenticationContext';

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "getDomain",
    ]
  }
] */
class DomainVerificationDomains extends Component {
  getDomain(params, index) {
    const {
      domain_domain: domain,
      domain_sending_verified: verified,
    } = params;
    const key = `${domain}_${index}`;
    const iconClass = verified === 'no'
      ? 'seve-icon-close-delete text-danger'
      : 'seve-icon-check-mark-circle-two text-success';

    return (
      <span
        key={key}
        className="padding10 pad-right20 pad-left20 shadow border-radius-small bg-gray-lightest right-buffer10 bottom-buffer20 d-flex column-gap-0_3em"
      >
        <i className={iconClass} />
        <span>
          {domain}
        </span>
      </span>
    );
  }

  render() {
    const authentication = this.context;
    const { enterprise } = authentication[0];
    const { props } = this;
    const { domains = [] } = props;
    const currentDomains = domains.map(this.getDomain);
    const className = currentDomains.length
      ? 'd-flex justify-content-between'
      : 'display-none';

    return (
      <div className={`padding20 bg-white shadow-sm border-radius-small border-light ${className}`}>
        <div className="d-flex flex-wrap">
          {currentDomains}
        </div>

        <a
          title="Edit"
          href={`${enterprise.enterprise_pmailer_url}/domains`}
          className="edit-item icon-stack icon-primary pull-left right-buffer10 bootstrap-tooltip"
        >
          <i className="seve-icon-circle-thin stack-one">
            <i className="seve-icon-pencil-2 stack-two" />
          </i>
        </a>
      </div>
    );
  }
}
DomainVerificationDomains.contextType = AuthenticationContext;
export default DomainVerificationDomains;
