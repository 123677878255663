import { useHistory } from 'react-router';
import { useState } from 'react';
import Modal from '../Layout/Modal';
import WebhookModalForm from './WebhookModalForm';
import SmallLoader from '../Loader/SmallLoader';
import getAxiosClient from '../Utilities/get-axios-client';
import ModalAlert from '../Utilities/ModalAlert';
/* eslint-disable camelcase */
const containerClass = 'width980';
const panelClass = 'panel-circle-success panel-circle-blue-primary';

const WebhookModal = ({ showModal, closeModal, initialWebhook }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [webhook, setWebhookData] = useState(initialWebhook);
  const history = useHistory();

  const updateOptions = (key, value, meta = '') => {
    if (key === 'auth') {
      if (webhook.auth_details.auth_type === '') {
        delete (webhook.auth_details.username);
        delete (webhook.auth_details.password);
      }
      const updatedAuth = { ...webhook.auth_details, [meta]: value };
      setWebhookData({ ...webhook, auth_details: updatedAuth });
      return;
    }
    if (key === 'status') {
      const webhookStatus = webhook.status;
      const newStatus = (webhookStatus === 'enabled') ? 'disabled' : 'enabled';
      setWebhookData({ ...webhook, status: newStatus });
      return;
    }
    if (key === 'events') {
      // eslint-disable-next-line camelcase
      const { event_types } = webhook;
      const index = event_types.indexOf(meta);
      if (index < 0) {
        event_types.push(meta);
      } else {
        event_types.splice(index, 1);
      }
      setWebhookData({ ...webhook, event_types });
      return;
    }
    if (key === 'auth_type' && value === '') {
      const { auth_details: auth, ...updatedWebhook } = webhook;
      setWebhookData({ ...updatedWebhook, [key]: value });
      return;
    }
    if (key === 'auth_type' && value === 'basic') {
      const updatedWebhook = {
        ...webhook,
        auth_details: {
          username: '',
          password: '',
        },
      };
      setWebhookData({ ...updatedWebhook, [key]: value });
      return;
    }
    setWebhookData({ ...webhook, [key]: value });
  };

  const validate = async () => {
    const errors = [];
    if (webhook.url === '') {
      errors.push('Webhook URL is empty');
    }
    if (errors.length > 0) {
      throw new Error(errors.join(', '));
    }
  };

  const saveWebhook = async () => {
    await setErrorMessage('');
    await setIsLoading(true);
    try {
      await validate();
      if (webhook.auth_type === '') {
        webhook.auth_type = null;
      }
      await getAxiosClient().post('v1/webhooks', webhook);
      setShowAlert(true);
    } catch (error) {
      const message = ('response' in error) ? error.response.data.message : error.message;
      setErrorMessage(message);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      show={showModal}
      closeModal={closeModal}
      containerClass={containerClass}
      panelClass={panelClass}
    >
      <div className="modal-header bg-gray-lightest">
        <button
          type="button"
          className="close top-buffer0"
          data-dismiss="modal"
        >
          <span aria-hidden="true">×</span>
        </button>
        <div className="circle circle-100 circle-no-hover img-circle text-center index-icon -top-55">
          <i className="seve-icon-setting-gear icon-36 absolute-center top-buffer5 border" />
        </div>
        <h4 className="pad-top20 ">Webhook</h4>
      </div>
      <div className={`modal-body panel-body pad-top20 pad-bottom20 bg-white box ${isLoading ? 'disabled' : ''}`}>
        <ModalAlert type="success" showAlert={showAlert} message="Webhook saved successfully." callback={() => history.go(0)} />
        <ModalAlert message={errorMessage} showAlert={errorMessage !== ''} />
        <div className="padding40 col-md-10 col-md-offset-1">
          <WebhookModalForm webhook={webhook} updateOptions={updateOptions} />
        </div>
      </div>
      <div className={`bg-gray-lightest padding20 d-flex justify-content-between pad-left20 pad-right45 ${isLoading ? 'disabled' : ''}`}>
        <div className="d-flex justify-content-between width100p">
          <button type="button" className="btn btn-link" data-dismiss="modal">Cancel</button>
          <div>
            <SmallLoader isLoading={isLoading} />
            <button type="button" className="btn btn-primary left-buffer5" onClick={saveWebhook}>Save Changes</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WebhookModal;
