import { useEffect, useRef, useState } from 'react';
import Modal from '../../Layout/Modal';
import BrandingColorPicker from './BrandingColorPicker';
import attachmentService from '../attachment-service';
import ModalAlert from '../../Utilities/ModalAlert';

const containerClass = 'width980';
const panelClass = 'panel-circle-success panel-circle-blue-primary';

const CustomizeBranding = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [logoName, setLogoName] = useState('');
  const [logo, setLogo] = useState(null);
  const [message, setMessage] = useState({ type: 'success', message: '' });
  const pageText = useRef(null);
  const pageBackground = useRef(null);
  const buttonText = useRef(null);
  const buttonBackground = useRef(null);
  const linkText = useRef(null);
  const logoFileRef = useRef(null);
  const chooseFile = () => logoFileRef.current.click();

  function validate(event) {
    return new Promise((resolve, reject) => {
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
      const maxFileSize = 512 * 1024;
      if (!allowedTypes.includes(event.target.files[0].type)) {
        reject(new Error(`File type '${event.target.files[0].type}' not allowed`));
      }
      if (event.target.files[0].size >= maxFileSize) {
        reject(new Error('File size exceeds the maximum file size'));
      }
      resolve();
    });
  }

  const onFileChange = async (event) => {
    try {
      setMessage({ type: 'success', message: '' });
      setShowAlert(false);
      await validate(event);
      setLogoUrl(URL.createObjectURL(event.target.files[0]));
      setLogo(event.target.files[0]);
      setLogoName(event.target.files[0].name);
    } catch (e) {
      setMessage({ type: 'danger', message: e.message });
      setShowAlert(true);
    }
  };

  const deleteLogo = async () => {
    try {
      setMessage({ type: 'success', message: '' });
      setShowAlert(false);
      await attachmentService.deleteBrandingLogo();
      setMessage({ type: 'success', message: 'Logo successfully deleted' });
      setLogoUrl('');
      setLogoName('');
    } catch (e) {
      setMessage({ type: 'danger', message: 'Unable to delete logo' });
    } finally {
      setShowAlert(true);
    }
  };

  const handleUpdate = async () => {
    const settings = {
      page: {
        colour: pageBackground.current?.getColor() ?? '',
        text_colour: pageText.current?.getColor() ?? '',
      },
      button: {
        colour: buttonBackground.current?.getColor() ?? '',
        text_colour: buttonText.current?.getColor() ?? '',
      },
      link: {
        colour: '#ffffff',
        text_colour: linkText.current?.getColor() ?? '',
      },
    };
    try {
      setMessage({ type: 'success', message: '' });
      setShowAlert(false);
      await attachmentService.saveBranding(settings, logo);
      setMessage({ type: 'success', message: 'Branding settings saved successful' });
    } catch (e) {
      setMessage({ type: 'danger', message: 'Unable to save branding settings' });
    } finally {
      setShowAlert(true);
    }
  };

  useEffect(() => {
    async function getBranding() {
      try {
        const response = await attachmentService.getBranding();
        pageBackground.current.setColor(response.data.settings.page.colour);
        pageText.current.setColor(response.data.settings.page.text_colour);
        buttonBackground.current.setColor(response.data.settings.button.colour);
        buttonText.current.setColor(response.data.settings.button.text_colour);
        linkText.current.setColor(response.data.settings.link.text_colour);
        setLogoUrl(response.data.logo.url);
        setLogoName(response.data.logo.fileName);
      } catch (e) {
        // eslint-disable-next-line
      }
    }

    getBranding();
  }, []);

  return (
    <>
      <button
        className="icon-stack icon-primary btn-link icon-with-text text-primary"
        type="button"
        data-toggle="tooltip"
        data-placement="top"
        title="Customize Branding"
        data-original-title="Customize Branding"
        tabIndex={-1}
        onClick={() => setShowModal(true)}
      >
        <i className="seve-icon-circle-thin stack-one text-primary">
          <i className="seve-icon-add-1 stack-two text-primary" />
        </i>
        <span className="top-buffer10 left-buffer10 pull-left text-primary">
          Customize Branding
        </span>
      </button>
      <Modal
        show={showModal}
        closeModal={() => setShowModal(false)}
        containerClass={containerClass}
        panelClass={panelClass}
      >
        <div className="modal-header bg-gray-lightest">
          <button
            type="button"
            className="close top-buffer0"
            data-dismiss="modal"
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="circle circle-100 circle-no-hover img-circle text-center index-icon -top-55">
            <i className="seve-icon-stationery-1 icon-36 absolute-center top-buffer5 border" />
          </div>
          <h4 className="pad-top20 font-weight-400">Customise Your Branding</h4>
        </div>
        <div className="modal-body panel-body bg-white box pad-top50 pad-bottom50">
          <ModalAlert message={message.message} showAlert={showAlert} type={message.type} />
          <div className="width-720 margin-auto">
            <div className="text-center">
              <div>
                The branding specified here is
                <strong> used for all Online Attachment links.</strong>
              </div>
              <div>
                You can customise your Branding at any time through the Global Settings Panel.
              </div>
            </div>
            <div className="padding20 bg-gray-lightest top-buffer20 d-flex justify-content-between">
              <div className="font-weight-600 width-80px d-flex align-items-center">Logo</div>
              <div className="d-flex items-center flex-grow">
                <div className="width-300 flex-shrink-0 pad-left10">
                  <p>
                    <strong>Accepted file types</strong>
                    <br />
                    <i>jpeg, jpg, gif, png</i>
                  </p>
                  <p>
                    <strong>File size limit</strong>
                    <br />
                    <i>500KB</i>
                  </p>
                  <div className="image-upload">
                    <label htmlFor="file-input">
                      <button
                        type="button"
                        onClick={chooseFile}
                        className="btn btn-link pad-left0"
                      >
                        Upload Photo link
                      </button>
                    </label>
                    <input id="file-input" onChange={onFileChange} ref={logoFileRef} type="file" className="hide" />
                  </div>
                  {logoName !== '' && (
                    <div className="display-flex">
                      {logoName}
                      <span className="cursor-pointer left-buffer10 top-buffer2" onClick={deleteLogo} onKeyUp={deleteLogo} tabIndex={0} role="button">
                        <i className="seve-icon-bin-2 stack-two" />
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex-grow background-white">
                  {logoUrl && <img src={logoUrl} className="width100p" alt="branding_logo" />}
                </div>
              </div>
            </div>
            <div className="padding20 bg-gray-lightest top-buffer20 d-flex justify-content-between">
              <div className="font-weight-600 width150 d-flex align-items-center">Page</div>
              <BrandingColorPicker ref={pageText} name="Text" />
              <BrandingColorPicker ref={pageBackground} name="Background" />
            </div>
            <div className="padding20 bg-gray-lightest top-buffer20 d-flex justify-content-between">
              <div className="font-weight-600 width150 d-flex align-items-center">Button</div>
              <BrandingColorPicker ref={buttonText} name="Text" />
              <BrandingColorPicker ref={buttonBackground} name="Background" />
            </div>
            <div className="padding20 bg-gray-lightest top-buffer20 d-flex justify-content-between">
              <div className="font-weight-600 width150 d-flex align-items-center">Link</div>
              <BrandingColorPicker ref={linkText} name="Text" />
              <div className="width-300 flex-shrink-0">&nbsp;</div>
            </div>
          </div>
        </div>
        <div className="bg-gray-lightest padding20 d-flex justify-content-between pad-left20 pad-right45">
          <button type="button" className="btn btn-link" data-dismiss="modal">Cancel</button>
          <button type="button" className="btn btn-primary" onClick={handleUpdate}>Update</button>
        </div>
      </Modal>
    </>
  );
};

export default CustomizeBranding;
