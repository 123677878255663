import React, { useEffect, useState } from 'react';
import shortenNumber from '../Utilities/shorten-number';
import activateTooltip from '../Utilities/activate-tooltip';
import makeAxiosCall from '../Utilities/make-axios-call';

const Quota = ({ dateRange }) => {
  const [data, setData] = useState({
    quota: {},
  });
  const fetchData = async () => {
    try {
      const [quota] = await Promise.all([
        makeAxiosCall('/v1/dashboard/get-quota', dateRange).catch(() => {}),
      ]);
      setData({ quota: quota.data });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    activateTooltip();
    fetchData().then((r) => r).catch((r) => r.toJSON());
  }, [dateRange]);
  const quota = data?.quota?.quota;

  return (
    <div>
      <p className="bottom-buffer5">
        <span className="text-graybase semibold">
          Monthly Quota
        </span>
      </p>
      <div
        className="progress bg-gray-light"
        data-toggle="tooltip"
        data-placement="top"
        role="button"
        title={`${shortenNumber(quota?.sent ?? 0)} of ${shortenNumber(+quota?.maximum ?? 0)} sent`}
      >
        <div
          className="progress-bar bg-secondary-green"
          role="progressbar"
          aria-valuenow={(+quota?.sent ?? 0 / +quota?.maximum ?? 1) * 100}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${(+quota?.sent ?? 0 / +quota?.maximum ?? 1) * 100}%` }}
        >
          <span className="sr-only">
            {`${(+quota?.sent ?? 0 / +quota?.maximum ?? 1)}% used`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Quota;
