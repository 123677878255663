import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import activateTooltip from '../Utilities/activate-tooltip';
import { getPlural } from '../Utilities/pluralise-text';
import RetryFetchData from '../Retry/RetryFetchData';

const TransactionTableBody = ({
  transactionsResponse,
  showTransactionModal,
  getTransactions,
  transactionSearchQuery,
}) => {
  useEffect(() => {
    activateTooltip();
  }, []);

  if (!transactionsResponse.data) {
    return (
      <>
        <tbody>
          <tr>
            <td colSpan={8} style={{ textAlign: 'center' }}>
              <RetryFetchData
                setRetry={() => {}}
                onClick={() => {
                  getTransactions(transactionSearchQuery);
                }}
              />
            </td>
          </tr>
        </tbody>
      </>
    );
  }

  const defaultDate = '0';

  const getStatusColor = (status) => {
    if (status === 'delivered') {
      return 'bg-brand-success';
    }
    if (status === 'bounced') {
      return 'bg-brand-warning';
    }

    return 'bg-brand-danger';
  };

  return (
    <tbody>
      {transactionsResponse.data.map((transaction) => (
        <tr
          key={transaction.trans_uuid}
          title={transaction.trans_uuid}
        >
          <td className="height-80 col-md-2 vertical-align-middle">
            <p>
              {transaction.trans_date_sent === defaultDate
                ? transaction.trans_date_queued
                : transaction.trans_date_sent }
            </p>
          </td>
          <td className="height-80 col-md-2 vertical-align-middle">
            <p
              className="width300 text-truncate"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={transaction.trans_to_email}
            >
              {transaction.trans_to_email}
            </p>
          </td>
          <td className="height-80 col-md-1 vertical-align-middle text-center">
            <p
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={transaction.tm_subject}
              className="width150 text-truncate"
            >
              {transaction.tm_subject}
            </p>
          </td>
          <td className="height-80 col-md-1 vertical-align-middle text-capitalize text-center">
            <div>
              <div className={`width-10 height-10 display-inline-block border-radius-large right-buffer5 ${getStatusColor(transaction.trans_delivered_status)}`} />
              {(transaction.trans_status === 'failed' || transaction.trans_status === 'no_quota') ? 'failed' : transaction.trans_delivered_status}
            </div>
          </td>
          <td className="height-80 col-md-2 vertical-align-middle text-primary">
            <p
              className="width300 text-truncate"
            >
              <Link
                to={`/groups/${transaction.trans_group_uuid}/overview`}
                className="text-primary"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title={`Detailed report for Group: ${transaction.trans_group_name}`}
              >
                <i className="icon icon-16 seve-icon-business-chart-1 right-buffer5" />
                {transaction.trans_group_name}
              </Link>
            </p>
          </td>
          <td className="height-80 col-md-1 vertical-align-middle text-center">
            <i
              className={`icon icon-24 seve-icon-mail-1-1 ${+(transaction.trans_read_count > 0) ? 'text-success' : 'text-gray'}`}
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={`${+(transaction.trans_read_count > 0) ? 'Opened' : 'Not Opened'}`}
            />
          </td>
          <td className="height-80  col-md-1 vertical-align-middle text-center">{`${transaction.trans_click_count} ${getPlural('link', +transaction.trans_click_count)}`}</td>
          <td className="height-80 vertical-align-middle text-center">
            <div className="d-flex items-center">
              { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
              <a
                onClick={() => showTransactionModal(transaction)}
                onKeyDown={() => showTransactionModal(transaction)}
                role="button"
                tabIndex={0}
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="More Info"
              >
                <div className="icon-stack icon-primary icon-with-text text-primary">
                  <i className="seve-icon-circle-thin stack-one text-primary">
                    <i className="seve-icon-receipt-3 stack-two text-primary" />
                  </i>
                </div>
              </a>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TransactionTableBody;
