import { Component } from 'react';
import { Link } from 'react-router-dom';
import CircleIcon from '../Components/CircleIcon';

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "PlaceHolderComponent",
    ]
  }
] */
class SideMenu extends Component {
  constructor(props) {
    super(props);

    this.url = '';
    this.path = '';
    this.state = {
      navigationProps: this.navigationProps,
    };

    this.Action = this.Action.bind(this);
    this.ActionButton = this.ActionButton.bind(this);
    this.ActionLink = this.ActionLink.bind(this);
    this.Navigation = this.Navigation.bind(this);
    this.NavigationLink = this.NavigationLink.bind(this);
    this.PlaceHolderComponent = this.PlaceHolderComponent.bind(this);

    this.handleNavigationLinkClick = this.handleNavigationLinkClick.bind(this);
  }

  handleNavigationLinkClick(params) {
    const { state, props } = this;
    const { updateNavigationProps = () => {} } = props;
    const { key } = params;
    const { navigationProps = [] } = state;
    const newState = {
      ...state,
      navigationProps: [],
    };

    navigationProps.forEach((currentProps) => {
      if (currentProps.key === key) {
        newState.navigationProps.push({
          ...currentProps,
          active: true,
        });
      } else {
        newState.navigationProps.push({ ...currentProps, active: false });
      }
    });

    updateNavigationProps({ navigationProps: newState.navigationProps });
  }

  getPath(params) {
    const { key } = params;
    const { path: root = '' } = this;
    const path = `${root}/${key}`;

    return path;
  }

  get navigationProps() {
    const { location } = window;
    const { hash } = location;
    const { pathname } = location;
    const { props, PlaceHolderComponent } = this;
    const { navigation = {}, updateNavigationProps = () => {} } = props;
    const navigationProps = Object
      .entries(navigation)
      .map(([key, currentProps], index) => ({
        key,
        index,
        component: currentProps.component || <PlaceHolderComponent label={currentProps.label} />,
        ...currentProps,
        active: !index,
        to: `${pathname}#${key}`,
      }));
    const hasNavigationProps = navigationProps
      .find(({ key }) => hash && key === hash.slice(1));

    if (hasNavigationProps) {
      for (let i = 0; i < navigationProps.length; i += 1) {
        navigationProps[i].active = navigationProps[i] === hasNavigationProps;
      }
    }

    updateNavigationProps({ navigationProps });

    return navigationProps;
  }

  ActionButton() {
    const { props } = this;
    const { action } = props;
    const { label, to = '' } = action;

    return (
      <Link label={label} to={to} className="btn btn-next">
        {label}
      </Link>
    );
  }

  ActionLink() {
    const { props } = this;
    const { action } = props;
    const {
      label = '',
      to = '',
      iconClass = 'seve-icon-angle-left',
    } = action;

    return (
      <Link to={to}>
        <i className={iconClass} />

        {label}
      </Link>
    );
  }

  Action() {
    const { props, ActionLink, ActionButton } = this;
    const { action } = props;
    const {
      label = '',
      to = '',
      type = 'button',
      actionIconClass = 'seve-icon-angle-left',
    } = action;
    const actionComponent = type === 'link'
      ? <ActionLink label={label} to={to} iconClass={actionIconClass} />
      : <ActionButton label={label} to={to} />;

    return (
      <div className="cont-nav-action-btn bottom-buffer20">
        {actionComponent}
      </div>
    );
  }

  PlaceHolderComponent(props) {
    const { label } = props;

    return (
      <div className="side-menu-placeholder">
        <span>
          Side Menu Placeholder:&nbsp;
        </span>

        {label}
      </div>
    );
  }

  NavigationLink(props) {
    const { props: params } = this;
    const { navigationProps } = params;
    const {
      key = (new Date()).getTime(),
    } = props;
    const currentProps = {
      ...(navigationProps.find(({ key: currentKey }) => key === currentKey) || props),
    };
    const {
      label = '',
      active = false,
      to = '',
    } = currentProps;
    const className = active ? 'active no-pointer-events' : '';
    const { handleNavigationLinkClick } = this;

    return (
      <li key={key} className={`text-transform-capitalize ${className}`}>
        <Link
          to={to}
          onClick={(event) => handleNavigationLinkClick({ ...props, event })}
        >
          {label}
        </Link>
      </li>
    );
  }

  Navigation() {
    const { state, NavigationLink } = this;
    const { navigationProps = [] } = state;
    const navigationLinks = navigationProps.map(NavigationLink);

    return (
      <ul className="cont-nav-nav">
        {navigationLinks}
      </ul>
    );
  }

  render() {
    const { props, Action, Navigation } = this;
    const {
      iconClass,
      primaryIconClass,
      secondaryIconClass,
      action,
      navigation,
    } = props;
    const {
      label = '',
      to = '',
      type = 'button',
      actionClassName = 'seve-icon-angle-left',
    } = action;
    const socondaryIconContainerClass = secondaryIconClass
      ? '' : 'display-none';

    return (
      <div className="cont-nav border-right-light">
        <div className="text-center bottom-buffer20 top-buffer20">
          <CircleIcon
            className={iconClass}
            primaryIconClass={primaryIconClass}
            secondaryIconClass={secondaryIconClass}
            socondaryIconContainerClass={socondaryIconContainerClass}
          />
        </div>

        <Action className={actionClassName} label={label} type={type} to={to} />

        <Navigation navigation={navigation} />
      </div>
    );
  }
}

export default SideMenu;
