import React, { useState, useRef, useEffect } from 'react';
import activateTooltip from '../../Utilities/activate-tooltip';

const TemplateCreateHeader = ({ template, updateTemplateDefault }) => {
  const [showTitleEditor, setShowTitleEditor] = useState(false);
  const textInput = useRef(null);

  useEffect(() => {
    activateTooltip();
  }, []);

  const onTitleChange = async () => {
    await updateTemplateDefault({
      ...template, name: textInput.current.value,
    });
  };

  const editTitle = async () => {
    await setShowTitleEditor(!showTitleEditor);
    if (!showTitleEditor) {
      textInput.current.focus();
    }
  };

  return (
    <div className="d-flex justify-content-between font-weight-300">
      <div className="d-flex">
        {
          showTitleEditor
            ? (
              <input
                type="text"
                ref={textInput}
                className="form-control width300"
                value={template.name}
                onChange={onTitleChange}
              />
            )
            : <div className="height-40 width300 d-flex align-items-center">{template.name}</div>
        }
        <div className="height-40 d-flex items-center left-buffer20 cursur-pointer small text-primary">
          <u
            role="button"
            tabIndex={0}
            onClick={editTitle}
            onKeyDown={editTitle}
          >
            { showTitleEditor ? 'Save' : 'Edit' }
          </u>
        </div>
        <div className="height-40 d-flex items-center left-buffer20">
          <span
            className={`label label-${template.status === 'published' ? 'success' : 'warning'} text-capitalize pad-top5`}
          >
            {template.status}
          </span>
        </div>
      </div>
      <div className="right-buffer30 d-flex">
        <div className="text-right">
          <p className="bold h6 margin0">Template ID/Hash</p>
          <p className="h6 margin0">{template.id}</p>
        </div>
        <div className="height-40 d-flex items-center">
          <i
            className="seve-icon-question-mark cursor-pointer left-buffer10 text-muted"
            data-toggle="tooltip"
            data-placement="left"
            title=""
            data-original-title="Use template ID in API and SMTP headers"
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateCreateHeader;
