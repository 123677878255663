import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import getAxiosClient from '../Utilities/get-axios-client';
import WebhookLogModalElement from './WebhookLogModalElement';
import PaginationElement from '../Components/PaginationElement';
import Preloader from '../Layout/Preloader';

class WebhookLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: {},
      query: { page: 1, orderBy: 'DESC' },
      selectedLog: {},
      showModal: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { query } = this.state;
    document.title = 'Webhook Logs';
    await this.getWebhookLogs(query);
  }

  async getWebhookLogs(query) {
    this.setState({ isLoading: true });
    try {
      const response = await getAxiosClient().post('/v1/webhook-logs', { ...query });
      this.setState({ response: response.data, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  goToPage = async (page) => {
    await this.getWebhookLogs({ page });
  };

  sort = async () => {
    const { query } = this.state;
    const newQuery = { page: 1, order_by: (query.orderBy === 'DESC') ? 'ASC' : 'DESC' };
    this.setState({ query: newQuery });
    await this.getWebhookLogs(newQuery);
  };

  setShowModal = (selectedLog) => this.setState({ selectedLog, showModal: true });

  closeModal = () => this.setState({ showModal: false });

  render() {
    const {
      response, showModal, selectedLog, isLoading, query,
    } = this.state;
    const { data = [] } = response;

    if (isLoading) {
      return <Preloader />;
    }

    return (
      <>
        <Header>
          <div className="d-flex justify-content-between">
            <div>Webhook Logs</div>
            <Link
              className="icon-stack icon-primary icon-with-text text-primary"
              to="/settings"
              role="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Webhook Setup"
              tabIndex={-1}
            >
              <span className="top-buffer10 right-buffer10 pull-left text-primary">
                Webhook Setup
              </span>
              <i className="seve-icon-circle-thin stack-one text-primary">
                <i className="seve-icon-pencil-2 stack-two text-primary" />
              </i>
            </Link>
          </div>
        </Header>
        <div className="min-container-height d-flex justify-content-between flex-column">
          <div className="padding40">
            <WebhookLogModalElement
              closeModal={this.closeModal}
              showModal={showModal}
              selectedLog={selectedLog}
            />
            {data.length > 0
              ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" role="button" onClick={this.sort}>
                        Date
                        <i className={`left-buffer5 seve-icon-chevron-${query.orderBy === 'DESC' ? 'down' : 'up'} icon-s-small`} />
                      </th>
                      <th scope="col">URL</th>
                      <th scope="col">Event Triggered</th>
                      <th scope="col">Status</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((log) => (
                      <tr key={log.id}>
                        <th>{log.date}</th>
                        <td>{log.url}</td>
                        <td className="text-capitalize">{log.payload.type}</td>
                        <td className="text-capitalize">
                          <i className={`seve-icon-circle text-${log.status === 'success' ? 'success' : 'danger'} right-buffer5 small`} />
                          {log.status}
                        </td>
                        <td>
                          <u
                            role="button"
                            className="text-primary"
                            tabIndex={0}
                            onClick={() => this.setShowModal(log)}
                            onKeyUp={() => this.setShowModal(log)}
                          >
                            More info
                          </u>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
              : 'You have no logged events'}
          </div>
          <div className="display-flex justify-content-end padding20">
            <PaginationElement goToPage={this.goToPage} transactionsResponse={response} />
          </div>
        </div>
      </>
    );
  }
}

export default WebhookLogs;
