import { Component } from 'react';
import AddDomainModal from './Modal/AddDomainModal';
import DomainVerificationDomains from './DomainVerificationDomains';
import Service from './Service';

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "handleSendVerificationEmailClicked",
    ]
  }
] */
const { verifyDomain } = Service;

class DomainVerification extends Component {
  static RESPONSE_MESSAGE = {
    domainCreated: 'Domain Created',
  };

  constructor(props) {
    super(props);

    this.state = {
      modal: {
        show: false,
      },
    };

    this.handleAddDomainButtonClicked = this.handleAddDomainButtonClicked.bind(this);
    this.handleSendVerificationEmailClicked = this.handleSendVerificationEmailClicked.bind(this);
    this.handleModalClosed = this.handleModalClosed.bind(this);
  }

  handleAddDomainButtonClicked() {
    const { state } = this;
    const { modal } = state;
    const newSate = {
      ...state,
      modal: {
        ...modal,
        show: true,
        verificationResponse: {},
      },
    };

    this.setState(newSate);
  }

  async handleSendVerificationEmailClicked(params, callback = () => {}) {
    const { RESPONSE_MESSAGE } = DomainVerification;
    const { domainCreated } = RESPONSE_MESSAGE;
    const domainCreatedMessages = [
      domainCreated,
    ];
    const { state, props } = this;
    const { modal } = state;
    const { updateDomains = () => {} } = props;
    const { email = '', domain = '' } = params;
    const verificationResponse = await verifyDomain({ email, domain });
    const { messages = [] } = verificationResponse;
    const message = messages.join(', ');
    const newState = {
      ...state,
      modal: {
        ...modal,
        verificationResponse,
      },
    };

    if (!domainCreatedMessages.includes(message) && !verificationResponse.error && messages[0] !== 'Domain Created') {
      verificationResponse.error = { message };
    }

    if (messages[0] === 'Domain Created') {
      verificationResponse.successMessage = message;
    }

    updateDomains();

    this.setState(newState);

    callback({ verificationResponse });
  }

  handleModalClosed() {
    const { state } = this;
    const { modal } = state;
    const newSate = {
      ...state,
      modal: {
        ...modal,
        show: false,
        verificationResponse: {},
      },
    };

    this.setState(newSate);
  }

  render() {
    const {
      state,
      props,
      handleSendVerificationEmailClicked,
      handleModalClosed,
    } = this;
    const { modal } = state;
    const { domains } = props;
    const { show } = modal;

    return (
      <div className="top-buffer30 bottom-buffer20 padding30 bg-white border-light">
        <div className="d-flex justify-content-between">
          <header>
            <h4 className="semibold">
              Add and Verify Domain
            </h4>

            <p>
              Verify ownership of your sending domain to improve deliverability of your emails.
            </p>
          </header>

          <div>
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={this.handleAddDomainButtonClicked}
            >
              Add Domain
            </button>
          </div>
        </div>

        <DomainVerificationDomains domains={domains} />

        <AddDomainModal
          show={show}
          handleModalClosed={handleModalClosed}
          panelClass="panel-modal-navy"
          handleSendVerificationEmailClicked={handleSendVerificationEmailClicked}
        />
      </div>
    );
  }
}

export default DomainVerification;
