// eslint-disable-next-line
import { Component } from 'react';
import { createPortal } from 'react-dom';

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "showModal",
      "hideModal"
    ]
  }
] */
class Modal extends Component {
  componentDidMount() {
    const { 'ev-modal': modal, $ } = window;

    const { body } = document;
    if (!modal) {
      body.appendChild(this.element);
    }

    const { show, closeModal } = this.props;
    if (show) {
      this.showModal();
    }
    $('#ev-modal').on('hidden.bs.modal', closeModal);
  }

  componentDidUpdate() {
    const { props } = this;
    const { show = false } = props;

    if (show) {
      this.showModal();
    } else {
      this.hideModal();
    }
  }

  get element() {
    const { 'ev-modal': modal, $ } = window;
    const { template } = this;
    const element = modal || $(template)[0];

    return element;
  }

  get template() {
    const { props } = this;
    const { containerClass = '', panelClass = '' } = props;
    const template = `
      <div id="ev-modal" class="modal fade" role="dialog" tabindex="-1">
        <div class="margin-auto width-transition modal-dialog ${containerClass}" style="margin-top: 75px;">
          <div id="ev-modal-panel" class="panel panel-default panel-circle-success position-relative bottom-buffer0 ${panelClass}">
          </div>
        </div>
      </div>
    `.trim();

    return template;
  }

  showModal() {
    const { 'ev-modal': modal, $ } = window;

    $(modal).modal('show');
  }

  hideModal() {
    const { 'ev-modal': modal, $ } = window;

    $(modal).modal('hide');
  }

  render() {
    const { $ } = window;
    const { props, element } = this;
    const { children } = props;
    const modalPanel = $(element).find('[id="ev-modal-panel"]')[0];

    return createPortal(children, modalPanel);
  }
}

export default Modal;
