const TwoColumnHeader = ({ children }) => (
  <div className="bg-white pad-left30 pad-right30 height70 border-bottom-light d-flex align-items-center">
    <div className="width100p">
      <div className="d-flex justify-content-between">
        {children}
      </div>
    </div>
  </div>
);

export default TwoColumnHeader;
