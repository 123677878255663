import React, { useEffect, useState } from 'react';
import Dropzone from './Dropzone';
import formatFileSize from '../../Utilities/format-file-size';
import AttachmentService from '../attachment-service';
import activateTooltip from '../../Utilities/activate-tooltip';
import { getPlural } from '../../Utilities/pluralise-text';

activateTooltip();
const FilesTab = ({
  initialFiles,
  groupId,
  callbackStatus,
  handleSuccess,
  handleFailure,
  handleCreateGroup,
}) => {
  const [files, setFiles] = useState(initialFiles);
  useEffect(() => {
    if (initialFiles) {
      setFiles(initialFiles);
    }
  }, [initialFiles]);

  const [progress, setProgress] = useState(0);
  const onUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setProgress(percentCompleted);
  };

  const uploadFile = async (uploadedFile) => {
    if (groupId === undefined) {
      try {
        await handleCreateGroup(uploadedFile);
      } catch (e) {
        // eslint-disable-next-line
      }
      return;
    }
    try {
      setProgress(0);
      callbackStatus('pending');
      setFiles((previousState) => [...previousState, { name: uploadedFile.name, size: uploadedFile.size, status: 'uploading' }]);
      const response = await AttachmentService.uploadFile(groupId, uploadedFile, onUploadProgress);
      const uploadedFileResponse = response.data.assets[0];
      setFiles((previousState) => {
        const currentFiles = [...previousState];
        currentFiles.pop();
        return [...currentFiles, uploadedFileResponse];
      });
      handleSuccess('File upload successful');
      callbackStatus('resolved');
    } catch (e) {
      callbackStatus('rejected');
      handleFailure(`Unable to upload file: ${e?.response?.data?.message ?? ''}`);
      setFiles((previousState) => {
        const currentFiles = [...previousState];
        currentFiles.pop();
        return [...currentFiles];
      });
    }
  };

  const deleteFile = async (fileId) => {
    try {
      callbackStatus('pending');
      await AttachmentService.deleteFile(groupId, fileId);
      setFiles((previousState) => previousState.filter((asset) => asset.id !== fileId));
      handleSuccess('File delete successful');
      callbackStatus('resolved');
    } catch (e) {
      callbackStatus('rejected');
      handleSuccess('Unable to delete file');
    }
  };

  const deleteFileHandler = (fileId) => {
    deleteFile(fileId);
  };

  return (
    <div>
      <p>
        {`${files.length} ${getPlural('file', files.length)} uploaded`}
      </p>
      <Dropzone
        updateFiles={uploadFile}
      />
      {files.map((file) => (
        <div key={file?.id ?? Math.random()} className="padding15 top-buffer10 bg-white border-radius-base shadow-sm">
          <div
            className="display-flex justify-content-between"
          >
            <span
              className="font-weight-600 flex-grow text-left flex-truncate"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={file.name}
            >
              {file.name}
            </span>
            <span>{formatFileSize(file.size)}</span>
            <span
              tabIndex={0}
              role="button"
              className="pad-left20 cursor-pointer"
              onClick={() => deleteFileHandler(file.id)}
              onKeyUp={() => deleteFileHandler(file.id)}
            >
              <i className="icon seve-icon-bin-2" />
            </span>
          </div>
          {'status' in file && file.status === 'uploading' && (
            <div className="bg-gray-lighter top-buffer10">
              <div className="bg-gray height-5" style={{ width: `${progress}%` }} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FilesTab;
