import React from 'react';
import Header from '../Layout/Header';
import Preloader from '../Layout/Preloader';
import Tabs from '../Utilities/Tabs';
import TemplateSearchElement from './TemplateSearchElement';

class CreateFromTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    document.title = 'Select Template';
  }

  render() {
    const { isLoading } = this.state;
    const tabOptions = [
      {
        name: 'My Templates', targetValue: '#my-templates', isActive: true, templateType: 'user',
      },
      {
        name: 'Templates Gallery', targetValue: '#template-gallery', isActive: false, templateType: 'system',
      },
    ];
    if (isLoading) {
      return <Preloader />;
    }

    return (
      <>
        <Header>
          <div className="d-flex justify-content-between">
            <div className="d-flex items-center">Select Template</div>
          </div>
        </Header>
        <div className="padding30 bg-gray-lightest min-container-height ">
          <Tabs tabOptions={tabOptions} />

          <div className="tab-content padding0">
            {tabOptions.map((tabOption, index) => (
              <TemplateSearchElement tabOption={tabOption} index={index} />
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default CreateFromTemplate;
