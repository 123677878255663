export default (props) => {
  const {
    header = '',
    primary = '',
    unit = '',
    secondary = '',
  } = props;
  const primaryUnit = unit && <small>{unit}</small>;

  return (
    <div className="flex-column">
      <h6 className="margin0">
        {header}
      </h6>

      <h3 className="font400 top-buffer5 bottom-buffer3">
        <span>
          {primary}
        </span>

        {primaryUnit}
      </h3>

      <p className="text-gray font400">
        {secondary}
      </p>
    </div>
  );
};
