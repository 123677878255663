import getAxiosClient from '../Utilities/get-axios-client';
import MockResponseData from '../test/TransactionalGroupReport/MockResponseData';
import pause from '../Utilities/pause';

class Service {
  static URL_PATH = {
    getTransactionGroups: 'api/v1/transaction-groups/search',
  };

  static getTransactionGroups(params) {
    const {
      type: reportType,
      groupHash,
      searchQuery,
      page,
      perPage,
      order,
      orderBy,
    } = params;
    const client = getAxiosClient(process.env.REACT_APP_SERVICE_BASE_URL);
    const requestParams = {
      reportType,
      groupHash,
      searchQuery,
      page,
      perPage,
      order,
      orderBy,
    };
    const response = client
      .post(Service.URL_PATH.getTransactionGroups, requestParams)
      .then(({ data }) => data)
      .catch((error) => ({ error }));

    return response;
  }

  static async getTransactionGroupsDummyData(params) {
    const { type } = params;
    const response = MockResponseData[type];

    await pause();

    return response;
  }
}

export default Service;
