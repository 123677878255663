import React, { useState } from 'react';

const TransactionTableHeader = ({
  dateRange,
  headers,
  transactionSearchQuery,
  getTransactions,
}) => {
  const lastColumnWidth = (11 - headers.map((header) => header.column).reduce((a, b) => a + b, 0));
  return (
    <thead>
      <tr>
        {headers.map((header) => (
          <TransactionTableHeaders
            key={header.headerTitle}
            column={header.column}
            headerTitle={header.headerTitle}
            order={header.order}
            orderBy={header.orderBy}
            isCenter={header.isCenter}
            transactionSearchQuery={transactionSearchQuery}
            getTransactions={getTransactions}
            dateRange={dateRange}
          />
        ))}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <th className={`col-sm-${lastColumnWidth}`}><></></th>
      </tr>
    </thead>
  );
};

const TransactionTableHeaders = ({
  headerTitle, order, orderBy = 'desc', column, isCenter, transactionSearchQuery, getTransactions, dateRange,
}) => {
  const [isAsc, setIsAsc] = useState(orderBy === 'asc');

  const handleSort = () => {
    const newSearchQuery = {
      ...transactionSearchQuery,
      ...{
        order,
        order_by: isAsc ? 'asc' : 'desc',
      },
      page: 1,
      startDate: dateRange.start,
      endDate: dateRange.end,
    };
    getTransactions(newSearchQuery);
    setIsAsc(!isAsc);
  };

  return (
    <th
      className={`col-sm-${column} ${isCenter ? 'text-center' : ''}`}
      onClick={order !== '' ? handleSort : undefined}
      role="button"
    >
      {headerTitle}
      {order !== '' && <span className={`right-buffer10 left-buffer5 ${isAsc ? 'seve-icon-angle-up' : 'seve-icon-angle-down'}`} />}
    </th>
  );
};

export default TransactionTableHeader;
