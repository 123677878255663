import AttachmentOptions from '../AttachmentOptions';
import LinkSettings from '../LinkSettings';

const CreateAttachmentSettings = ({ group, updateOptions }) => (
  <div>
    <div className="pad-bottom20"><LinkSettings initialOptions={group.options} updateOptions={updateOptions} /></div>
    <AttachmentOptions id={group.id} />
  </div>
);

export default CreateAttachmentSettings;
