import getAxiosClient from '../Utilities/get-axios-client';

class Service {
  static URL_PATH = {
    getStarted: 'v1/get-started',
    domains: 'v1/domains',
  };

  static getStartedData() {
    const client = getAxiosClient(process.env.REACT_APP_SERVICE_BASE_URL);
    return client
      .get(Service.URL_PATH.getStarted)
      .then(({ data }) => data)
      .catch((error) => ({ error }));
  }

  static getDomains() {
    const client = getAxiosClient(process.env.REACT_APP_SERVICE_BASE_URL);
    return client
      .get(Service.URL_PATH.domains)
      .then(({ data }) => data)
      .catch((error) => ({ error }));
  }

  static verifyDomain(params) {
    const { email: emailAddress = '', domain } = params;
    return getAxiosClient()
      .post(Service.URL_PATH.domains, {
        domain_verification_email: emailAddress,
        domain_url: domain,
      })
      .then(({ data }) => data)
      .catch((error) => ({ error }));
  }
}

export default Service;
