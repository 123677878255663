import ConfigurationApiField from '../Onboarding/ConfigurationApiField';

const ClickToCopyElement = (config) => {
  const { label, value, type = 'text' } = config;
  return (
    <div className="bottom-buffer15 top-buffer10" key={label}>
      <ConfigurationApiField
        label={label}
        key={label}
        id={label}
        value={value}
        type={type}
        placeholder=""
      />
    </div>
  );
};
const Config = ({ configValues }) => configValues.map(ClickToCopyElement);

export { Config, ClickToCopyElement };
