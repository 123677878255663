import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { defaultDashboardPieChartOptions } from './dashboard-chart-options';
import DashboardStatItems from './DashboardStatItems';
import makeAxiosCall from '../Utilities/make-axios-call';
import RetryFetchData from '../Retry/RetryFetchData';

const DashboardSendStats = ({
  dateRange,
  route,
  reportType,
}) => {
  const [data, setData] = useState({
    overview: {},
    chartOptions: defaultDashboardPieChartOptions,
  });
  const [retry, setRetry] = useState(false);
  const fetchData = async () => {
    try {
      const getGroupUUID = window.location.pathname.split('/')[2] ?? '';
      const overview = await makeAxiosCall(
        route,
        dateRange,
        { group_uuid: getGroupUUID, report_type: reportType },
      ).catch(() => {
        setTimeout(() => {
          setRetry(true);
        }, process.env.REACT_APP_AXIOS_TIMEOUT);
      });

      const prevChartOpts = data.chartOptions;

      setData({
        overview: overview?.data,
        chartOptions: {
          ...prevChartOpts,
          series: [{
            name: 'Percentage',
            data: [{
              color: '#1abc9c',
              name: 'Delivered',
              y: +overview?.data?.overviewStats?.delivered?.percentage ?? 0,
            }, {
              color: '#fc0',
              name: 'Bounced',
              y: +overview?.data?.overviewStats?.bounced?.percentage ?? 0,
            }, {
              color: '#e74c3c',
              name: 'Failed',
              y: +overview?.data?.overviewStats?.failed?.percentage ?? 0,
            }],
          }],
        },
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData().then((r) => r).catch((r) => r.toJSON());
  }, [dateRange, retry]);

  const overviewStats = data?.overview?.overviewStats;

  if (!overviewStats?.total || +overviewStats?.total === 0) {
    return (
      <div className="bg-white top-buffer20 padding40 border-light height-250">
        <div style={{ float: 'right' }}>
          { retry && <RetryFetchData setRetry={setRetry} /> }
        </div>
        <div className="d-flex items-center height100p">No Data</div>
      </div>
    );
  }

  return (
    <div className="bg-white top-buffer20 padding20 border-light height-250">
      <div className="row">
        <div className="col-md-4">
          <HighchartsReact
            highcharts={Highcharts}
            options={data.chartOptions}
          />
        </div>
        <div className="col-md-8">
          <DashboardStatItems overviewStats={overviewStats} />
        </div>
      </div>
    </div>
  );
};

export default DashboardSendStats;
