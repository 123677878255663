import { Config } from './SettingsConfigElement';

const SMTPCredentials = ({ smtpCredentials }) => (
  <>
    <h5 className="font500">SMTP Credentials</h5>
    <div className="padding15 pad-left30 border-radius-base border-light">
      <div className="row">
        <div className="col-md-7 pad-right40">
          <Config configValues={[
            {
              label: 'Host',
              value: smtpCredentials.host,
            },
            {
              label: 'Port',
              value: smtpCredentials.port,
            },
            {
              label: 'Username',
              value: smtpCredentials.username,
            },
            {
              label: 'Password',
              value: smtpCredentials.password,
              type: 'password',
            },
          ]}
          />
        </div>
      </div>
    </div>
  </>
);

export default SMTPCredentials;
