import SummaryStats from '../Components/SummaryStats';
import Form from '../Components/Form';
import Table from '../Components/Table';
import LineGraph from '../Components/LineGraph';

export default function TransactionalGroupReportTypeStats(props) {
  const {
    iconContainerClass,
    iconClass,
    stats,
    table,
    inputFields,
    inputValues,
    validate,
  } = props;
  const component = !stats.length ? null : (
    <>
      <SummaryStats
        iconContainerClass={iconContainerClass}
        iconClass={iconClass}
        stats={stats}
      />

      <LineGraph />

      <div className="padding30 bg-white border-light">
        <Form
          className="form-group d-flex column-gap-20px bottom-buffer0"
          inputFields={inputFields}
          inputValues={inputValues}
          validate={validate}
        />
      </div>

      <Table table={table} />
    </>
  );

  return component;
}
