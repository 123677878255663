const Tabs = ({ tabOptions }) => (
  <div className="row">
    <div className="col-sm-12">
      <ul className="nav nav-tabs tabsV2 evTab" role="tablist">
        {tabOptions.map((tab) => (
          <li role="presentation" className={`${tab.isActive ? 'active' : ''}`} key={tab.targetValue}>
            <a
              href={tab.targetValue}
              className="no-border h4 margin0 font600 pad-left0 pad-right0 bg-none"
              aria-controls="emails"
              role="tab"
              data-toggle="tab"
            >
              {tab.name}
            </a>
            <div className="border-animated" />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Tabs;
