import { Config } from '../Settings/SettingsConfigElement';

export default function configurationSmtpSettings(props) {
  const {
    host,
    port,
    username,
    password,
  } = props;

  return (
    <div className="display-grid row-gap-15px">
      <header>
        <h5 className="semibold">
          SMTP Settings
        </h5>

        <p>
          If you are configuring an email client
          or a Content Management System (i.e.
          Wordpress, Prestashop, etc.) you will
          probably need SMTP access, you can copy
          it. This key will be available under
          settings.
        </p>
      </header>

      <div className="row">
        <div className="col-md-6">
          <Config configValues={[
            {
              label: 'Host',
              value: host,
            },
            {
              label: 'Port',
              value: port,
            },
            {
              label: 'Username',
              value: username,
            },
            {
              label: 'Password',
              value: password,
              type: 'password',
            },
          ]}
          />
        </div>
      </div>
    </div>
  );
}
