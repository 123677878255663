import { Component } from 'react';
import Service from './Service';
import Onboarding from '../Onboarding/Onboarding';
import Dashboard from '../Dashboard/Dashboard';
import getAxiosClient from '../Utilities/get-axios-client';
import { AuthenticationContext } from '../Auth/AuthenticationContext';

const { getStartedData } = Service;

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      component: undefined,
    };
  }

  async componentDidMount() {
    const [overview] = await Promise.all([
      getAxiosClient().post(
        '/v1/dashboard/overview',
        {},
        { withCredentials: true },
      ).catch(() => {}),
    ]);
    const authentication = this.context;
    const { user } = authentication[0];
    if (user?.user_can_send_transactions === 'no' ?? true) {
      this.setState({
        component: <Dashboard />,
      });
      return;
    }
    const { state } = this;
    const { domains = [] } = await getStartedData();
    const verifiedDomains = domains
      .filter(({ domain_sending_verified: verified }) => verified === 'yes');

    const component = ((verifiedDomains.length > 0) && (+overview?.data?.overviewStats?.total > 0))
      ? (
        <Dashboard />
      )
      : <Onboarding />;
    const newState = {
      ...state,
      component,
    };
    this.setState(newState);
  }

  render() {
    const { state } = this;
    const { component } = state;

    return (
      <>
        {component}
      </>
    );
  }
}

Home.contextType = AuthenticationContext;
export default Home;
