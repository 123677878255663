import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

export default (props) => {
  const {
    dateRange,
    className = '',
    buttonClassName: currentButtonClassName = '',
    handleDateRangeSelected = () => { },
  } = props;
  const {
    from: fromDate,
    to: toDate,
    dateFormat = 'MMMM D, YYYY',
    separator = ' - ',
  } = dateRange;
  const from = moment(fromDate).format(dateFormat);
  const to = moment(toDate).format(dateFormat);
  const initialSettings = {
    startDate: fromDate,
    endDate: toDate,
  };
  const dateRangeString = [from, to].join(separator);
  const buttonClassName = `border-2 form-control font600 text-primary width100p bg-none d-flex align-items-center ${currentButtonClassName}`;

  return (
    <DateRangePicker initialSettings={initialSettings} onApply={handleDateRangeSelected}>
      <div className={`ev-date-range-picker input-group bg-white ${className}`}>
        <div role="button" className={buttonClassName}>
          <span>
            {dateRangeString}
          </span>
        </div>
        <span className="input-group-addon">
          <i className="icon seve-icon-calendar" />
        </span>
      </div>
    </DateRangePicker>
  );
};
