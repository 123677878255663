import { forwardRef, useImperativeHandle, useState } from 'react';

const BrandingColorPicker = ({ name, initColor = '#333333' }, ref) => {
  const [color, setColor] = useState(initColor);

  useImperativeHandle(ref, () => ({
    setColor: (colorCode) => setColor(colorCode),
    getColor: () => color,
  }));

  return (
    <div className="d-flex items-center width-300 flex-shrink-0">
      <div className="width-100">{name}</div>
      <input readOnly ref={ref} id="Username" type="text" value={color} className="form-control bg-white width-120" />
      <input type="color" id="colorpicker" onChange={(e) => setColor(e.target.value)} value={color} className="cursor-pointer padding0 border-0 width-40 height-40 left-buffer10" />
    </div>
  );
};

export default forwardRef(BrandingColorPicker);
