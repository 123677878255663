export default () => (
  <header>
    <h2>
      Let&apos;s get started
    </h2>

    <p>
      Connect your custom domain, API and SMTP
      for fast sending personalised transactional
      emails.
      For more information you can access them here.
    </p>
  </header>
);
