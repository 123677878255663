import { Component } from 'react';

/* eslint class-methods-use-this: [
  "error",
  {
    "exceptMethods": [
      "HeaderRowColumn",
      "BodyRowColumn",
      "BodyRow",
      "getTypedLabel",
      "BulletLabel"
    ]
  }
] */
export default class Table extends Component {
  static LABEL_TYPE = {
    bullet: 'bullet',
  };

  constructor(props) {
    super(props);

    this.HeaderRow = this.HeaderRow.bind(this);
    this.HeaderRowColumn = this.HeaderRowColumn.bind(this);
    this.BodyRows = this.BodyRows.bind(this);
    this.BodyRow = this.BodyRow.bind(this);
    this.BodyRowColumn = this.BodyRowColumn.bind(this);
    this.BulletLabel = this.BulletLabel.bind(this);
  }

  getTypedLabel(params) {
    const { LABEL_TYPE } = Table;
    const { BulletLabel } = this;
    const { value } = params;
    const { type, value: currentValue } = value;

    let label = null;

    if (type === LABEL_TYPE.bullet) {
      label = <BulletLabel value={currentValue} />;
    }

    return label;
  }

  BulletLabel(params) {
    const { LABEL_TYPE } = Table;
    const { value } = params;

    return (
      <span data-type={LABEL_TYPE.bullet} data-value={value}>
        {value}
      </span>
    );
  }

  HeaderRowColumn(params) {
    const { value, index: key } = params;
    const { label, class: className = '' } = value;

    return (
      <th key={key} className={`text-transform-capitalize ${className}`}>
        <span>
          {label}
        </span>
      </th>
    );
  }

  HeaderRow(params) {
    const { HeaderRowColumn } = this;
    const { row = [] } = params;
    const columns = row.map((value, index) => HeaderRowColumn({ value, index }));
    const component = !columns.length ? null : (
      <thead>
        <tr>
          {columns}
        </tr>
      </thead>
    );

    return component;
  }

  BodyRowColumn(params) {
    const { value, key } = params;
    const { value: label, type } = typeof value === 'object'
      ? value : { value };
    const currentLabel = type ? this.getTypedLabel({ value }) : (
      <span>
        {label}
      </span>
    );

    return (
      <td key={key} className="height85 vertical-align-middle">
        {currentLabel}
      </td>
    );
  }

  BodyRow(params) {
    const { BodyRowColumn } = this;
    const { row = [], key } = params;
    const columns = row
      .map((value, index) => BodyRowColumn({ value, key: index }));
    const component = !row.length ? null : (
      <tr key={key}>
        {columns}
      </tr>
    );

    return component;
  }

  BodyRows(params) {
    const { BodyRow } = this;
    const { rows = [] } = params;
    const bodyRows = rows
      .map((row, key) => BodyRow({ row, key }));
    const component = !rows.length ? null : (
      <tbody>
        {bodyRows}
      </tbody>
    );

    return component;
  }

  render() {
    const { props, HeaderRow, BodyRows } = this;
    const { table = [] } = props;
    const [
      headerRow = [],
    ] = table;
    const bodyRows = table.slice(1);
    const component = !(headerRow.length)
      ? null : (
        <div className="ev-table-component padding30 bottom-buffer30 bg-white border-light">
          <table className="table border-collapse-separate table-hover">
            <HeaderRow row={headerRow} />

            <BodyRows rows={bodyRows} />
          </table>
        </div>
      );

    return component;
  }
}
