import { Component, createRef } from 'react';
import ConfigurationHeader from './ConfigurationHeader';
import ConfigurationApiDetail from './ConfigurationApiDetail';
import ConfigurationApiTest from './ConfigurationApiTest';
import ConfigurationApi from './ConfigurationApi';
import ConfigurationSmtpSettings from './ConfigurationSmtpSettings';
import ConfigurationSmtp from './ConfigurationSmtp';

export default class Configuration extends Component {
  constructor(props) {
    super(props);

    this.state = { activeOnboardingConfigureButton: undefined };

    this.apiButton = createRef();
    this.smtpButton = createRef();

    this.handleApiButtonClick = this.handleApiButtonClick.bind(this);
    this.handleSmtpButtonClick = this.handleSmtpButtonClick.bind(this);
  }

  handleApiButtonClick() {
    this.setState((state) => ({
      activeOnboardingConfigureButton: this.apiButton || state.activeOnboardingConfigureButton,
    }));
  }

  handleSmtpButtonClick() {
    this.setState((state) => ({
      activeOnboardingConfigureButton: this.smtpButton || state.activeOnboardingConfigureButton,
    }));
  }

  get configurationApiDetail() {
    const { props } = this;
    const { apiCredentials } = props;
    const { isReseller } = props;
    return [
      <ConfigurationApiDetail
        key="api-detail"
        username={apiCredentials?.username}
        apiKey={apiCredentials?.apiKey}
        url={apiCredentials?.url}
      />,
      <ConfigurationApiTest isReseller={isReseller} />,
    ];
  }

  get configurationSmtpDetail() {
    const { props } = this;
    const { smtpCredentials } = props;
    return [
      <ConfigurationSmtpSettings
        key="smtp-settings"
        host={smtpCredentials?.host}
        port={smtpCredentials?.port}
        username={smtpCredentials?.username}
        password={smtpCredentials?.password}
      />,
    ];
  }

  render() {
    const { state } = this;
    const apiButtonActiveClass = this.apiButton
      && state.activeOnboardingConfigureButton === this.apiButton
      ? 'active-border-color' : '';
    const apiButtonActiveHeaderClass = apiButtonActiveClass ? 'text-primary' : '';
    const smtpButtonActiveClass = this.smtpButton
      && state.activeOnboardingConfigureButton === this.smtpButton
      ? 'active-border-color' : '';
    const smtpButtonActiveHeaderClass = smtpButtonActiveClass ? 'text-primary' : '';
    const configurationDetail = (apiButtonActiveClass && this.configurationApiDetail)
      || (smtpButtonActiveClass && this.configurationSmtpDetail);

    return (
      <div className="top-buffer30 bottom-buffer20 padding30 bg-white border-light display-grid row-gap-30px">
        <ConfigurationHeader />

        <div className="display-grid-2-col column-gap-30px">
          <ConfigurationApi
            ref={this.apiButton}
            onClick={this.handleApiButtonClick}
            activeClass={apiButtonActiveClass}
            activeHeaderClass={apiButtonActiveHeaderClass}
          />
          <ConfigurationSmtp
            ref={this.smtpButton}
            onClick={this.handleSmtpButtonClick}
            activeClass={smtpButtonActiveClass}
            activeHeaderClass={smtpButtonActiveHeaderClass}
          />
        </div>

        {configurationDetail}
      </div>
    );
  }
}
