import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { useContext } from 'react';
import { AuthenticationContext } from './AuthenticationContext';

const publicRoutes = ['/login', '/unsubscribe/:transactionId', '/header-unsubscribe/:transactionId'];

/* eslint-disable react/jsx-props-no-spreading */
const PrivateRoute = ({ children, ...rest }) => {
  const [authentication] = useContext(AuthenticationContext);

  return authentication.loading === false ? (
    <Route
      {...rest}
      render={() => {
        // eslint-disable-next-line no-restricted-globals
        if (!authentication.isAuthenticated && publicRoutes.includes(rest?.path ?? '')) {
          return children;
        }

        if (!authentication.isAuthenticated) {
          return <Redirect to="/login" />;
        }
        // eslint-disable-next-line no-restricted-globals
        if (location.pathname === '/login') {
          const query = new URLSearchParams(window.location.search);
          const url = query.get('url') ?? null;

          if (url !== null) {
            const redirect = `/${url}`;
            return <Redirect to={redirect} />;
          }

          return <Redirect to="/" />;
        }
        return children;
      }}
    />
  ) : null;
};

export default PrivateRoute;
