import Modal from '../Layout/Modal';

const containerClass = 'width980';
const panelClass = 'panel-circle-success panel-circle-blue-primary';

const TransactionModal = ({ showModal, closeModal, selectedTransaction }) => {
  let headers = null;
  try {
    headers = JSON.parse(selectedTransaction?.tm_additional_headers);
  } catch (e) {
    // do nothing
  }

  return (
    <Modal
      show={showModal}
      closeModal={closeModal}
      containerClass={containerClass}
      panelClass={panelClass}
    >
      <div className="modal-header bg-gray-lightest">
        <button
          type="button"
          className="close top-buffer0"
          data-dismiss="modal"
        >
          <span aria-hidden="true">×</span>
        </button>
        <div className="circle circle-100 circle-no-hover img-circle text-center index-icon -top-55">
          <i className="seve-icon-receipt-3 icon-36 absolute-center top-buffer5 border" />
        </div>
        <h4 className="pad-top20 ">Transaction Info</h4>
      </div>
      <div className="modal-body panel-body bg-white box">
        <div className="padding-50">
          <div className="row pad-top10 pad-bottom10">
            <div className="col-md-4 text-right semibold">Transaction ID:</div>
            <div className="col-md-8">
              {selectedTransaction?.trans_uuid}
            </div>
          </div>
          <div className="row pad-top10 pad-bottom10">
            <div className="col-md-4 text-right semibold">From:</div>
            <div className="col-md-8">
              {selectedTransaction?.tm_from_name}
              &lt;
              {selectedTransaction?.tm_from_email}
              &gt;
            </div>
          </div>
          <div className="row pad-top10 pad-bottom10">
            <div className="col-md-4 text-right semibold">To:</div>
            <div className="col-md-8">
              {selectedTransaction?.trans_to_name}
              &lt;
              {selectedTransaction?.trans_to_email}
              &gt;
            </div>
          </div>
          <div className="row pad-top10 pad-bottom10">
            <div className="col-md-4 text-right semibold">Subject:</div>
            <div className="col-md-8">
              {selectedTransaction?.tm_subject}
            </div>
          </div>
          <div className="row pad-top10 pad-bottom10">
            <div className="col-md-4 text-right semibold">Sent:</div>
            <div className="col-md-8">
              {selectedTransaction?.trans_date_sent}
            </div>
          </div>
          { (headers !== null && (Object.keys(headers).length > 0)) && (
            <div className="row pad-top10 pad-bottom10">
              <div className="col-md-4 text-right semibold">Custom Headers:</div>
              <div className="col-md-8">
                <pre>
                  {
                    JSON.stringify(
                      headers,
                      null,
                      2,
                    )
                  }
                </pre>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="bg-gray-lightest padding20 d-flex justify-content-between pad-left20 pad-right45">
        <button type="button" className="btn btn-link" data-dismiss="modal">Cancel</button>
      </div>
    </Modal>
  );
};

export default TransactionModal;
