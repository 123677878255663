import TransactionDelivered from '../TransactionDelivered';

const TransactionGroupSentReportTableBody = ({ transactionsData, showTransactionModal }) => (
  <tbody>
    {transactionsData.map((transaction) => (
      <tr key={transaction?.trans_uuid} title={transaction?.trans_uuid}>
        <td>{transaction?.trans_date_sent ?? ''}</td>
        <td>
          <p
            className="width200 text-truncate margin0"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={transaction.tm_from_email}
          >
            {transaction?.tm_from_email ?? ''}
          </p>
        </td>
        <td>
          <p
            className="width300 text-truncate margin0"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={transaction.tm_subject}
          >
            {transaction?.tm_subject ?? ''}
          </p>
        </td>
        <td>
          <p
            className="width200 text-truncate margin0"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={transaction.trans_to_email}
          >
            {transaction?.trans_to_email ?? ''}
          </p>
        </td>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <td><TransactionDelivered deliveryStatus={transaction.trans_delivered_status} /></td>
        <td>
          <div className="text-center">
            { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => showTransactionModal(transaction)}
              onKeyDown={() => showTransactionModal(transaction)}
              role="button"
              tabIndex={0}
            >
              <span className="text-primary">More Info</span>
            </a>
          </div>
        </td>
      </tr>
    ))}
  </tbody>
);

export default TransactionGroupSentReportTableBody;
