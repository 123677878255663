import { useRef, useState } from 'react';

const TemplateCreateDefaultsForm = ({ template, updateTemplateDefault, domains = [] }) => {
  const [templateData, setTemplateData] = useState(template);
  const [isDifferentReplyTo, setIsDifferentReplyTo] = useState(
    templateData.from_email !== templateData.reply_email,
  );
  const getDomainFromEmail = (email) => email.substring(email.lastIndexOf('@') + 1);
  const getLocalPartFromEmail = (email) => email.substring(0, email.lastIndexOf('@'));
  const fromEmailDomain = useRef();
  const fromEmailLocalPart = useRef();
  const isFromEmail = (type) => ['templateFromEmailLocalPart', 'templateFromEmailDomain'].includes(type);
  const updateData = async (type, value) => {
    if (isFromEmail(type)) {
      await setTemplateData((previousState) => ({
        ...previousState,
        from_email: `${fromEmailLocalPart.current.value}@${fromEmailDomain.current.value}`,
      }
      ));
      if (!isDifferentReplyTo) {
        await updateData('reply_email', `${fromEmailLocalPart.current.value}@${fromEmailDomain.current.value}`);
      }
    }
    await setTemplateData((previousState) => ({
      ...previousState,
      [type]: value,
    }));
    await updateTemplateDefault(templateData);
  };

  const onChangeCheckbox = async (event) => {
    await setIsDifferentReplyTo(event.target.checked);
    if (!event.target.checked) {
      await updateData('reply_email', `${fromEmailLocalPart.current.value}@${fromEmailDomain.current.value}`);
    }
  };

  return (
    <form>
      <div className="form-group pad-top10">
        <label htmlFor="fromSender">From Sender</label>
        <input
          type="text"
          className="form-control"
          value={templateData.from_name}
          id="fromSender"
          placeholder="From Sender"
          onChange={(e) => updateData('from_name', e.target.value)}
        />
      </div>
      <div className="form-group pad-top10">
        <label htmlFor="fromEmail">From Email</label>
        <div className="row">
          <div className="col-md-5 pad-right0">
            <input
              type="text"
              className="form-control"
              id="fromEmail"
              placeholder="From Email"
              ref={fromEmailLocalPart}
              value={getLocalPartFromEmail(templateData.from_email)}
              onChange={(e) => updateData('templateFromEmailLocalPart', e.target.value)}
            />
          </div>
          <div className="col-md-1 height-40 font600 d-flex align-items-center">@</div>
          <div className="col-md-6 pad-left5">
            <select
              className="form-control"
              ref={fromEmailDomain}
              value={getDomainFromEmail(templateData.from_email)}
              onChange={(e) => updateData('templateFromEmailDomain', e.target.value)}
            >
              {domains.map((domain) => (
                <option key={domain.domain_domain}>{domain.domain_domain}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="pad-left30">
          <div className="checkbox">
            <label>
              <input type="checkbox" onChange={onChangeCheckbox} checked={isDifferentReplyTo} />
              Use different reply to Email
            </label>
          </div>
        </div>
      </div>
      <div className={`form-group pad-top10 pad-bottom10 ${isDifferentReplyTo ? '' : 'disabled'}`}>
        <label htmlFor="fromSender">Reply to Email</label>
        <input
          type="email"
          className="form-control"
          id="replyToEmail"
          placeholder="Reply to Email"
          value={templateData.reply_email}
          onChange={(e) => updateData('reply_email', e.target.value)}
        />
      </div>
      <hr />
      <div className="form-group pad-top10">
        <label htmlFor="subject">Subject</label>
        <input
          type="text"
          className="form-control"
          id="subject"
          placeholder="Subject"
          value={templateData.subject}
          onChange={(e) => updateData('subject', e.target.value)}
          onKeyUp={(e) => updateData('subject', e.target.value)}
        />
      </div>
      <div className="form-group pad-top10">
        <label htmlFor="description">Description</label>
        <textarea
          className="form-control"
          rows="5"
          placeholder="Description"
          value={templateData.description}
          onChange={(e) => updateData('description', e.target.value)}
        />
      </div>
    </form>
  );
};

export default TemplateCreateDefaultsForm;
