import React from 'react';
import shortenNumber from '../Utilities/shorten-number';
import DashboardStatItem from './DashboardStatItem';

const DashboardStatItems = ({ overviewStats }) => (
  <>
    <DashboardStatItem colorClass="bg-brand-success" statTitle="Delivered" bottomData={shortenNumber(overviewStats?.delivered?.value ?? 0)} topData={`${(overviewStats?.delivered?.percentage ?? 0)}%` ?? '0%'} containerWidth="col-md-4" />
    <DashboardStatItem colorClass="bg-brand-warning" statTitle="Bounced" bottomData={shortenNumber(overviewStats?.bounced?.value ?? 0)} topData={`${(overviewStats?.bounced?.percentage ?? 0)}%` ?? '0%'} containerWidth="col-md-4" />
    <DashboardStatItem colorClass="bg-brand-danger" statTitle="Failed" bottomData={shortenNumber(overviewStats?.failed?.value ?? 0)} topData={`${(overviewStats?.failed?.percentage ?? 0)}%` ?? '0%'} containerWidth="col-md-4" />
    <DashboardStatItem colorClass="" statTitle="Sent" bottomData={shortenNumber(overviewStats.sent.value ?? 0)} topData={`${(overviewStats?.sent?.percentage)}%` ?? '0%'} containerWidth="col-md-4" />
    <DashboardStatItem colorClass="" statTitle="Transactions" bottomData="&nbsp;" topData={shortenNumber(overviewStats.total) ?? 0} containerWidth=" bg-gray-lightest col-md-8" />
  </>
);

export default DashboardStatItems;
