const TransactionGroupOpensReportTableBody = ({ transactionsData }) => (
  <tbody>
    {transactionsData.map((transaction) => (
      <tr key={transaction.trans_uuid} title={transaction.trans_uuid}>
        <td>{transaction?.log_date ?? ''}</td>
        <td>
          <p
            className="width300 text-truncate margin0"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={transaction.trans_to_email}
          >
            {transaction?.trans_to_email ?? ''}
          </p>
        </td>
        <td>
          <p
            className="width300 text-truncate margin0"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={transaction.tm_subject}
          >
            {transaction?.tm_subject ?? ''}
          </p>
        </td>
        <td className="text-center">{transaction?.ts_total_opens ?? 0}</td>
        <td><></></td>
      </tr>
    ))}
  </tbody>
);

export default TransactionGroupOpensReportTableBody;
