const Header = ({ children }) => (
  <div className="bg-white pad-left30 pad-right30 height70 border-bottom-light d-flex align-items-center">
    <div className="width100p">
      <div className="top-buffer10">
        <h4 className="font500">{children}</h4>
      </div>
    </div>
  </div>
);

export default Header;
