import { Component } from 'react';

class Steps extends Component {
  static getStep(label) {
    const color = label > 1 ? 'circle-gray' : 'circle-secondary-blue';

    const step = (
      <div key={label} className={`circle circle-40 circle-no-hover img-circle ${color}`}>
        <div className="icon-16 absolute-center text-white">
          {label}
        </div>
      </div>
    );

    return step;
  }

  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      ...props,
    };
  }

  get steps() {
    const { state } = this;
    const { count } = state;
    const steps = [];

    let step;

    for (let i = 0; i < count; i += 1) {
      step = Steps.getStep(i + 1);

      steps.push(step);
    }

    return steps;
  }

  render() {
    const { steps } = this;
    const { domains } = this.props;

    return (
      <div className="width-40px margin-top60px">
        <div className="display-grid stacked-grid-items position-relative">
          <div className="verticle-line" />

          <div className={`display-grid ${(domains > 0 ?? false) ? 'row-gap-215px' : 'row-gap-120px'}`}>
            {steps}
          </div>
        </div>
      </div>
    );
  }
}

export default Steps;
