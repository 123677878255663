import React, { useState } from 'react';
import AddSuppressionModal from './AddSuppressionModal';
import TextSearchElement from '../Components/TextSearchElement';
import getAxiosClient from '../Utilities/get-axios-client';

const suppressionStatusValues = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Bounce',
    value: 'bounce',
  },
  {
    label: 'Unsubscribe',
    value: 'unsubscribe',
  },
];

const SuppressionHeader = ({
  setSearchTextValue, setSuppressedOriginValue, emailToBeDeleted, goToPage, done,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const closeModal = () => {
    setShowModal(false);
  };

  const setSuppressedOrigin = (origin) => {
    setCurrentStatus(origin);
    setSuppressedOriginValue(origin);
  };

  const deleteEmail = async () => {
    try {
      await getAxiosClient().delete(`/v1/suppression/${emailToBeDeleted}`);
      done();
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  return (
    <div className="pad-left30 pad-top30 pad-bottom30">
      <AddSuppressionModal showModal={showModal} closeModal={closeModal} />
      <div className="row">
        <div className="col-md-6">
          <div
            className={`icon-stack icon-gray icon-with-text text-muted right-buffer10 ${emailToBeDeleted === '' ? 'disabled' : ''}`}
            role="button"
            onClick={deleteEmail}
            onKeyUp={deleteEmail}
            tabIndex={0}
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title="Delete"
          >
            <i className="seve-icon-circle-thin stack-one text-muted">
              <i className="seve-icon-bin-2 stack-two text-muted" />
            </i>
          </div>
          <div
            className="icon-stack icon-primary icon-with-text text-primary"
            role="button"
            onClick={() => setShowModal(true)}
            onKeyUp={() => setShowModal(true)}
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title="Add Recipients"
            tabIndex={-1}
          >
            <i className="seve-icon-circle-thin stack-one text-primary">
              <i className="seve-icon-add-1 stack-two text-primary" />
            </i>
            <span className="top-buffer10 left-buffer10 pull-left text-primary">
              Add Recipients
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <TextSearchElement
            dateRange="date"
            setSearchTextValue={setSearchTextValue}
            getTransactions={goToPage}
          />
        </div>
        <div className="col-md-3">
          {suppressionStatusValues.map((suppressionStatus) => (
            <button
              key={suppressionStatus.label}
              type="button"
              className={`btn right-buffer10 border-radius50 ${currentStatus === suppressionStatus.value ? 'btn-primary' : 'btn-default'}`}
              onClick={() => setSuppressedOrigin(suppressionStatus.value)}
            >
              {suppressionStatus.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SuppressionHeader;
