import Modal from '../../Layout/Modal';
import ModalHeader from './AddDomainModalHeader';
import ModalBody from './AddDomainModalBody';

export default (props) => {
  const {
    show = false,
    handleModalClosed = () => {},
  } = props;
  const {
    containerClass = 'width980',
    panelClass = '',
    handleSendVerificationEmailClicked = () => {},
  } = props;

  return (
    <Modal show={show} containerClass={containerClass} panelClass={panelClass}>
      <ModalHeader handleModalClosed={handleModalClosed} />

      <ModalBody
        show={show}
        handleSendVerificationEmailClicked={handleSendVerificationEmailClicked}
        handleModalClosed={handleModalClosed}
      />
    </Modal>
  );
};
