import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';
import activateTooltip from '../Utilities/activate-tooltip';

const TransactionGroupsTableBody = ({ groups }) => {
  useEffect(() => {
    activateTooltip();
  }, []);

  if (!groups) {
    return '';
  }
  return (
    <tbody>
      {groups.map((group) => (
        <tr key={group.group_id}>
          <td className="vertical-align-middle height-80">
            <Link to={`/groups/${group.group_uuid}/overview`} className="text-primary">
              {group.group_name}
            </Link>
            <br />
            <small>
              Created on:
              {' '}
              {moment(group?.group_create_date ?? 0).format('DD MMMM YYYY')}
            </small>
          </td>
          <td className="vertical-align-middle height-80 text-center">{group?.group_last_send_date === null ? '-' : moment(group?.group_last_send_date ?? 0).format('DD MMMM YYYY')}</td>
          <td className="vertical-align-middle height-80 text-center">{group.groupStats.tgs_total_sent ?? 0}</td>
          <td className="vertical-align-middle height-80 text-center">{group.groupStats.tgs_total_sent ?? 0}</td>
          <td className="vertical-align-middle height-80 text-center">{group.groupStats.tgs_total_failed ?? 0}</td>
          <td className="vertical-align-middle height-80 text-center">{group.groupStats.tgs_total_delivered ?? 0}</td>
          <td className="vertical-align-middle height-80 text-center">{group.groupStats.tgs_total_bounces ?? 0}</td>
          <td className="vertical-align-middle height-80 text-center">{group.groupStats.tgs_total_opens ?? 0}</td>
          <td className="vertical-align-middle height-80 text-center">{group.groupStats.tgs_total_link_clicks ?? 0}</td>
          <td className="vertical-align-middle height-80">
            <div className="d-flex items-center">
              <Link to={`/groups/${group.group_uuid}/overview`}>
                <div
                  className="icon-stack icon-primary icon-with-text text-primary"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Detailed Report"
                >
                  <i className="seve-icon-circle-thin stack-one text-primary">
                    <i className="seve-icon-business-chart-2-1 stack-two text-primary" />
                  </i>
                </div>
              </Link>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TransactionGroupsTableBody;
