import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Modal from '../Layout/Modal';
import SmallLoader from '../Loader/SmallLoader';
import ModalAlert from '../Utilities/ModalAlert';
import getAxiosClient from '../Utilities/get-axios-client';

const containerClass = 'width980';
const panelClass = 'panel-circle-danger';

const AddSuppressionModal = ({ showModal, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const contentRef = useRef();
  const cancelButton = useRef();

  const callbackSuccess = () => {
    cancelButton.current.click();
    history.go(0);
  };

  const addSuppressions = async () => {
    await setShowErrorAlert(false);
    await setShowSuccessAlert(false);
    const emails = contentRef.current.value.split('\n').filter((email) => email !== '');
    try {
      await setIsLoading(true);
      await getAxiosClient().post('v1/suppression', { emails });
      await setShowSuccessAlert(true);
    } catch (error) {
      await setShowErrorAlert(true);
      await setErrorMessage(error.response.data.message ?? 'Error in adding suppressions');
    }
    await setIsLoading(false);
  };

  return (
    <Modal
      show={showModal}
      closeModal={closeModal}
      containerClass={containerClass}
      panelClass={panelClass}
    >
      <div className="modal-header bg-gray-lightest">
        <button
          type="button"
          className="close top-buffer0"
          data-dismiss="modal"
        >
          <span aria-hidden="true">×</span>
        </button>
        <div className="circle circle-100 circle-no-hover img-circle text-center index-icon -top-55">
          <i className="seve-icon-user-block-2-1 icon-36 absolute-center top-buffer5 border" />
        </div>
        <h4 className="pad-top20 ">Add recipients to your suppression list.</h4>
      </div>
      <div className={`modal-body panel-body pad-top50 pad-bottom50 bg-white box ${isLoading ? 'disabled' : ''}`}>
        <ModalAlert showAlert={showErrorAlert} message={errorMessage} />
        <ModalAlert type="success" showAlert={showSuccessAlert} message="Suppression successfully added." callback={callbackSuccess} />
        <div className="pad-top10 col-md-10 col-md-offset-1">
          <p>
            Paste the email addresses of contacts to suppress.
            Ensure each row is copied and pasted on a separate line.
          </p>
          <textarea className="form-control" placeholder="Type email addresses here" rows="10" ref={contentRef} />
        </div>
      </div>
      <div className={`bg-gray-lightest padding20 d-flex justify-content-between pad-left20 pad-right45 ${isLoading ? 'disabled' : ''}`}>
        <div className="d-flex justify-content-between width100p">
          <button type="button" className="btn btn-link" data-dismiss="modal" ref={cancelButton}>Cancel</button>
          <div>
            <SmallLoader isLoading={isLoading} />
            <button type="button" className="btn btn-primary left-buffer5" onClick={addSuppressions}>Save Changes</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddSuppressionModal;
