import { Component } from 'react';
import NavContainer from './NavContainer';

class NavUi extends Component {
  constructor(props) {
    super(props);

    const { name: id } = NavUi;

    this.state = {
      id,
      title: '',
      primaryIconClass: '',
      secondaryIconClass: '',
      action: {},
      navigation: {},
    };
  }

  render() {
    const { state } = this;
    const {
      id,
      title,
      primaryIconClass,
      secondaryIconClass,
      action,
      navigation,
      data,
    } = state;

    return (
      <NavContainer
        id={id}
        title={title}
        action={action}
        primaryIconClass={primaryIconClass}
        secondaryIconClass={secondaryIconClass}
        navigation={navigation}
        data={data}
      />
    );
  }
}

export default NavUi;
