import React, { useState } from 'react';
import getAxiosClient from '../Utilities/get-axios-client';

const SendingOptions = ({ settingsValue }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState(settingsValue);
  const updateOptions = async (key, currentValue) => {
    const value = !currentValue;
    await setIsLoading(true);
    try {
      await getAxiosClient().post('/v1/ui-settings', { [key]: value }, { withCredentials: true });
      setSettings((previousState) => {
        const stateKey = `tsetting_${key}`;
        return {
          ...previousState,
          [stateKey]: value,
        };
      });
    } catch (e) {
      // eslint-disable-next-line
    } finally {
      await setIsLoading(false);
    }
  };
  return (
    <>
      <h5 className="font500">Sending Options</h5>
      <div className={`pad-right-65 pad-left40 bg-gray-lightest ${isLoading ? 'disabled' : ''}`}>
        <div className="d-flex">
          <div className="ev-control pad-right-50">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="track-opens"
                className="ev-control-switch"
                checked={settings.tsetting_track_opens}
                onChange={() => updateOptions('track_opens', settings.tsetting_track_opens)}
              />
              <label htmlFor="track-opens" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div className="float-left">
              Track Opens
            </div>
          </div>
          <div className="ev-control pad-right-50">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="track-link-clicks"
                className="ev-control-switch"
                checked={settings.tsetting_track_links}
                onChange={() => updateOptions('track_links', settings.tsetting_track_links)}
              />
              <label htmlFor="track-link-clicks" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div className="float-left">
              Track Link Clicks
            </div>
          </div>
          <div className="ev-control pad-right-50">
            <div className="float-left pad-right10 top-buffer5">
              <input
                type="checkbox"
                id="disable-suppressions"
                className="ev-control-switch"
                checked={settings.tsetting_disable_suppression}
                onChange={() => updateOptions('disable_suppression', settings.tsetting_disable_suppression)}
              />
              <label htmlFor="disable-suppressions" className="ev-control-lbl">&nbsp;</label>
            </div>
            <div className="float-left">
              Disable Suppressions
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendingOptions;
