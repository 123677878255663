import React, { useEffect } from 'react';
import activateTooltip from '../../Utilities/activate-tooltip';
import TransactionDelivered from '../TransactionDelivered';
import { getPlural } from '../../Utilities/pluralise-text';

const TransactionGroupOverviewTableBody = ({ transactionsResponse, showTransactionModal }) => {
  useEffect(() => {
    activateTooltip();
  }, []);

  if (!transactionsResponse.data) {
    return '';
  }
  return (
    <tbody>
      {transactionsResponse.data.map((transaction) => (
        <tr key={transaction.trans_uuid} title={transaction.trans_uuid}>
          <td className="height-80 col-md-2 vertical-align-middle">{transaction.trans_date_queued}</td>
          <td className="height-80 col-md-2 vertical-align-middle">
            <p
              className="width300 text-truncate"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={transaction.trans_to_email}
            >
              {transaction.trans_to_email}
            </p>
          </td>
          <td className="height-80 col-md-2 vertical-align-middle">
            <p
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={transaction.tm_subject}
              className="width300 text-truncate"
            >
              {transaction.tm_subject}
            </p>
          </td>
          <td className="height-80 col-md-1 vertical-align-middle text-capitalize text-center">
            <TransactionDelivered deliveryStatus={transaction.trans_delivered_status} />
          </td>
          <td className="height-80 col-md-1 vertical-align-middle text-center">
            <i className={`icon icon-24 seve-icon-mail-1-1 ${+(transaction.trans_read_count > 0) ? 'text-success' : 'text-gray'}`} />
          </td>
          <td className="height-80  col-md-1 vertical-align-middle text-center">{`${transaction.trans_click_count} ${getPlural('link', +transaction.trans_click_count)}`}</td>
          <td className="height-80 vertical-align-middle text-center">
            <div className="d-flex items-center">
              { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                onClick={() => showTransactionModal(transaction)}
                onKeyDown={() => showTransactionModal(transaction)}
                role="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="More Info"
                tabIndex={0}
              >
                <div className="icon-stack icon-primary icon-with-text text-primary">
                  <i className="seve-icon-circle-thin stack-one text-primary">
                    <i className="seve-icon-receipt-3 stack-two text-primary" />
                  </i>
                </div>
              </a>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TransactionGroupOverviewTableBody;
