export default (props) => {
  const {
    iconContainerClass: className = '',
    iconClass = '',
  } = props;
  return (
    <div className="height-65 width-65 d-flex items-center border-light border-radius-large">
      <div className={`width-60 height-60 d-flex items-center border-radius-large text-white ${className}`}>
        <i className={`d-flex items-center ${iconClass}`} />
      </div>
    </div>
  );
};
