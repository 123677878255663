/* eslint-disable class-methods-use-this */

class TransactionGroupOverviewDataFormatter {
  getChartOptions = (data, previousStateChartOptions) => {
    const days = data.dailyStats.map((dailyStat) => dailyStat.tstat_day);
    const sent = data.dailyStats.map((dailyStat) => +dailyStat.sent);
    const failed = data.dailyStats.map((dailyStat) => +dailyStat.failed);
    const bounced = data.dailyStats.map((dailyStat) => +dailyStat.bounced);
    return {
      ...previousStateChartOptions,
      xAxis: {
        categories: days,
      },
      series: [{
        name: 'Sent',
        data: sent,
        color: '#3b5998',
      }, {
        name: 'Failed',
        data: failed,
        color: '#e74c3c',
      }, {
        name: 'Bounced',
        data: bounced,
        color: '#dddddd',
      }],
    };
  };
}

export default new TransactionGroupOverviewDataFormatter();
