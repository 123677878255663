import { Link } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
import Files from '../Create/Files';
import { valueIsNotSet } from '../../Utilities/check-value';

const ListingOverview = ({ selectedGroup }) => {
  if (selectedGroup === null) {
    return '';
  }

  const options = [
    { key: 'ID', icon: '#', value: selectedGroup.id },
  ];

  return (
    <div className="padding30">
      <div className="d-flex justify-content-between bottom-buffer10">
        <Link
          className="icon-stack icon-primary icon-with-text text-primary"
          to={`/online-attachments/${selectedGroup.id}/edit`}
          role="button"
          data-toggle="tooltip"
          data-placement="top"
          title=""
          data-original-title="Edit"
          tabIndex={-1}
        >
          <i className="seve-icon-circle-thin stack-one text-primary">
            <i className="seve-icon-pencil-2 stack-two text-primary" />
          </i>
        </Link>
        <a
          className="icon-stack icon-primary icon-with-text text-primary"
          href={`${selectedGroup.preview_link}`}
          target="_blank"
          rel="noreferrer"
          role="button"
          data-toggle="tooltip"
          data-placement="top"
          title=""
          data-original-title="Preview"
          tabIndex={-1}
        >
          <i className="seve-icon-circle-eye stack-one font-size-42">
            <i className="stack-two" />
          </i>
        </a>
      </div>
      <div className="display-flex justify-content-between pad-top20 bottom-buffer20 border-bottom-light pad-bottom10">
        <h4 className="font-weight-400 bottom-buffer0 top-buffer0">{selectedGroup.name}</h4>
        <span>{`Created on ${moment(selectedGroup.created_timestamp).format('D MMMM YYYY')}`}</span>
      </div>
      <div className="row bottom-buffer20">
        <div className="col-md-6">
          <div className="display-flex align-items-center pad-top10 pad-bottom10">
            <div className="width-30"><i className="seve-icon-calendar" /></div>
            <div>
              <div className="font-weight-600">Expiry date</div>
              <span>
                {`${valueIsNotSet(selectedGroup.options.expiry_timestamp)
                  ? 'Never'
                  : moment(selectedGroup.options.expiry_timestamp).format('D MMMM YYYY')}`}
              </span>
            </div>
          </div>
          <div className="display-flex align-items-center pad-top10 pad-bottom10">
            <div className="width-30"><i className="seve-icon-hand-touch-2-1" /></div>
            <div>
              <div className="font-weight-600">Access Limit</div>
              <span>
                {`${valueIsNotSet(selectedGroup.options.access_limit)
                  ? 'None'
                  : selectedGroup.options.access_limit}`}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {options.map((option) => (
            <div key={option.key} className="display-flex align-items-center pad-top10 pad-bottom10">
              <div className="width-30">
                {(option.key === 'URL') ? <i className={option.icon} /> : <h4 className="font-weight-600">{option.icon}</h4>}
              </div>
              <div className="flex-grow">
                <div className="font-weight-600">{option.key}</div>
                <div>{option.value}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Files files={selectedGroup.assets} />
    </div>
  );
};

export default ListingOverview;
