const TransactionDelivered = ({ deliveryStatus }) => (
  <div className="text-capitalize">
    <div
      className={`width-10 height-10 display-inline-block border-radius-large right-buffer5 ${deliveryStatus === 'delivered' ? 'bg-brand-success' : 'bg-danger'}`}
    />
    {deliveryStatus}
  </div>
);

export default TransactionDelivered;
