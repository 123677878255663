import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import moment from 'moment';
import activateTooltip from '../../Utilities/activate-tooltip';

const TemplateListingOverview = ({ template }) => {
  useEffect(() => {
    activateTooltip();
  }, []);

  if (Object.keys(template).length === 0) {
    return '';
  }

  return (
    <>
      <div className="d-flex justify-content-between bottom-buffer10">
        <Link
          className="icon-stack icon-primary icon-with-text text-primary"
          to={`/templates/edit/${template.id}`}
          role="button"
          data-toggle="tooltip"
          data-placement="top"
          title=""
          data-original-title="Edit template"
          tabIndex={-1}
        >
          <i className="seve-icon-circle-thin stack-one text-primary">
            <i className="seve-icon-pencil-2 stack-two text-primary" />
          </i>
        </Link>
        {template.status === 'published' && (
          <Link
            className="icon-stack icon-gray icon-with-text text-primary"
            to={`/templates/create/${template.id}`}
            role="button"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title="Re-create template"
            tabIndex={-1}
          >
            <i className="seve-icon-circle-thin stack-one">
              <i className="seve-icon-files-4 stack-two" />
            </i>
          </Link>
        )}
      </div>
      <div className="d-flex justify-content-between bottom-border bottom-buffer30">
        <div className="d-flex">
          <h5 className="padding0 font400">{template.name}</h5>
          {template.status === 'draft' && <div className="top-buffer5"><span className="badge badge-warning text-uppercase">Draft</span></div>}
        </div>
        <p className="padding0">
          {`Created on ${moment(template.created_timestamp).format('d MMM YYYY - HH:mm')}`}
        </p>
      </div>
      <div className="row">
        <div className="col-md-4">
          <img src="https://live.everlytic.net/images/misc/default_thumb.png" className="img-responsive" alt={template.subject} />
        </div>
        <div className="col-md-8">
          <div className="height-160">
            <div className="d-flex">
              <div className="font600 width-60 text-center">#</div>
              <div>
                <p className="font600 margin0">Template ID</p>
                <span>{template.id}</span>
              </div>
            </div>
            {template.description && (
              <div className="d-flex top-buffer20">
                <div className="font600 width-60 text-center">
                  <i
                    className="seve-icon-pencil "
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Template description"
                  />
                </div>
                <div>{template.description}</div>
              </div>
            )}
            {template.from_name && (
              <div className="d-flex top-buffer20">
                <div className="font600 width-60 text-center">
                  <i
                    className="seve-icon-mail-1-1 "
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="From email"
                  />
                </div>
                <div>
                  {template.from_name}
                  {' '}
                  &lt;
                  {template.from_email}
                  &gt;
                  {' '}
                </div>
              </div>
            )}
            {template.reply_email && (
              <div className="d-flex top-buffer20">
                <div className="font600 width-60 text-center">
                  <i
                    className="seve-icon-reply-mail-2 bootstrap-tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Reply to email"
                  />
                </div>
                <div>
                  {template.from_name}
                  {' '}
                  &lt;
                  {template.reply_email}
                  &gt;
                  {' '}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateListingOverview;
