import React from 'react';
import Modal from '../Layout/Modal';

const containerClass = 'width980';
const panelClass = 'panel-circle-success panel-circle-blue-primary';

const WebhookLogModalElement = ({ showModal, closeModal, selectedLog }) => (
  <Modal
    show={showModal}
    closeModal={closeModal}
    containerClass={containerClass}
    panelClass={panelClass}
  >
    <div className="modal-header bg-gray-lightest">
      <button
        type="button"
        className="close top-buffer0"
        data-dismiss="modal"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div className="circle circle-100 circle-no-hover img-circle text-center index-icon -top-55">
        <i className="seve-icon-webhook icon-36 absolute-center top-buffer5 border" />
      </div>
      <h4 className="pad-top20 ">Webhook Log Info</h4>
    </div>
    <div className="modal-body panel-body bg-white box">
      <div className="padding-50">
        <div className="border-bottom-light bottom-buffer20 pad-bottom5 d-flex justify-content-between">
          <div className="h5 margin0 font-weight-600">Request</div>
          <div className="top-buffer5">
            <i className={`seve-icon-circle text-${selectedLog.status === 'success' ? 'success' : 'danger'} right-buffer5 small`} />
            <span className="text-capitalize">{selectedLog.status}</span>
          </div>
        </div>
        <div>
          <h6 className="font-weight-600">Headers</h6>
          <div>
            <pre>
              <div>
                <b>Request URL: </b>
                {selectedLog.url}
              </div>
              <div>
                <b>Request method: </b>
                <span className="text-uppercase">{selectedLog.verb}</span>
              </div>
              <div>
                <b>Field set: </b>
                <span className="text-capitalize">{selectedLog.field_set ?? ''}</span>
              </div>
              <div>
                <b>content-type: </b>
                {selectedLog.content_type}
              </div>
              <div>
                <b>User-Agent: </b>
                Slingshot
              </div>
            </pre>
          </div>
        </div>
        <div className="pad-top10">
          <h6 className="font-weight-600">Payload</h6>
          <pre>{JSON.stringify(selectedLog.payload, null, 2) }</pre>
        </div>
      </div>
    </div>
    <div className="bg-gray-lightest padding20 d-flex justify-content-between pad-left20 pad-right45">
      <button type="button" className="btn btn-link" data-dismiss="modal">Cancel</button>
    </div>
  </Modal>
);

export default WebhookLogModalElement;
