import Unauthorised from '../../Pages/Unauthorised';
import ExportNotFound from '../../Pages/ExportNotFound';

const downloadBlock = ({ fileName, downloadLink, statusCode }) => {
  if (statusCode === 204) {
    return <ExportNotFound />;
  }

  if (statusCode === 412) {
    return <Unauthorised />;
  }

  if (statusCode === 200 || statusCode === 0) {
    return (
      <div className="pad-bottom30">
        <div className="d-flex justify-content-between top-buffer25 bg-white shadow-sm padding20">
          <div className="text-left margin-xy-auto">
            <div className="font600">{fileName}</div>
          </div>
          <div className="left-buffer20">
            <a
              href={downloadLink}
              download
              className="btn btn btn-primary"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="pad-bottom30">Unknown Error.</div>
    </>
  );
};

export default downloadBlock;
