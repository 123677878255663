const DashboardStatItem = ({
  colorClass,
  statTitle,
  topData,
  bottomData,
  containerWidth,
  noPadding = false,
}) => (
  <div className={`${containerWidth} d-flex padding15 ${noPadding ? 'pad-left0' : ''}`}>
    {!noPadding && (
    <div
      className={`width-10 height-10 display-inline-block border-radius-large right-buffer5 top-buffer3 ${colorClass}`}
    />
    )}
    <div>
      <h6
        className="margin0 text-truncate width-100"
        data-toggle="tooltip"
        data-placement="right"
        title=""
        data-original-title={statTitle}
      >
        { statTitle }
      </h6>
      <h3 className="top-buffer3 bottom-buffer0 font500">{ topData }</h3>
      <span>{ bottomData }</span>
    </div>
  </div>
);

export default DashboardStatItem;
