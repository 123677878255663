import axios from 'axios';

const getAxiosClient = () => {
  const unauthorizedCodes = [401, 403];
  const client = axios.create({
    baseURL: process.env.REACT_APP_NEUTRON_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Request-Type': 'ui',
    },
    timeout: process.env.REACT_APP_AXIOS_TIMEOUT,
    withCredentials: true,
  });
  client.interceptors.request.use((request) => {
    try {
      document.getElementById('loading').style.display = 'block';
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return request;
  });
  client.interceptors.response.use((response) => {
    try {
      document.getElementById('loading').style.display = 'none';
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return response;
  }, (error) => {
    if (
      ('response' in error && error.response !== undefined)
      && ('status' in error.response && error.response.status !== undefined)
      && (unauthorizedCodes.includes(error.response.status))) {
      window.location.href = '/unauthorized';
    }

    return Promise.reject(error);
  });

  return client;
};

export default getAxiosClient;
