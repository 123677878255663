import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

const LinkSettings = ({
  initialOptions,
  display = 'block',
  type = 'edit',
  updateOptions = null,
}) => {
  const datePickerRef = useRef();
  const [options, setOptions] = useState(initialOptions);
  const [hasExpiry, setHasExpiry] = useState(initialOptions.expiry_timestamp > 0);
  const [hasAccessLimit, setHasAccessLimit] = useState(initialOptions.access_limit > 0);
  const initialSettings = {
    singleDatePicker: true,
    showDropdowns: true,
    timePicker: true,
    startDate: initialOptions.expiry_timestamp > 0
      ? moment(initialOptions.expiry_timestamp)
      : moment(),
    timePickerSeconds: true,
    timePicker24Hour: true,
    minDate: moment(),
    maxDate: moment().add(1, 'year'),
  };
  useEffect(() => {
    if (initialOptions.expiry_timestamp > 0) {
      setOptions(initialOptions);
    } else {
      // setOptions(initialSettings);
    }
  }, [initialOptions]);

  const handleApply = (event, picker) => {
    setOptions((prev) => ({ ...prev, expiry_timestamp: picker.startDate }));
    if (updateOptions !== null) {
      updateOptions({ ...options, expiry_timestamp: picker.startDate });
    }
  };

  const updateAccessOptions = (value) => {
    setOptions((prev) => ({ ...prev, access_limit: value }));
    if (updateOptions !== null) {
      updateOptions({ ...options, access_limit: value });
    }
  };

  const handleUpdateExpiry = () => {
    if (hasExpiry) {
      setOptions((prev) => ({ ...prev, expiry_timestamp: null }));
      updateOptions({ ...options, expiry_timestamp: null });
      setHasExpiry(false);
      return;
    }
    setOptions((prev) => ({ ...prev, expiry_timestamp: moment() }));
    updateOptions({ ...options, expiry_timestamp: moment() });
    datePickerRef.current.setStartDate(moment());
    datePickerRef.current.setEndDate(moment());
    setHasExpiry(true);
  };

  const handleUpdateAccessLimit = () => {
    if (hasAccessLimit) {
      setOptions((prev) => ({ ...prev, access_limit: 0 }));
      updateOptions({ ...options, access_limit: 0 });
      setHasAccessLimit(false);
      return;
    }
    setHasAccessLimit(true);
  };

  return (
    <div>
      <h5 className="border-bottom-light pad-bottom10 top-buffer0 bottom-buffer20 font-weight-400">Link Settings</h5>
      <div>
        <div className={`form-group ${display === 'inline' && 'display-flex items-center'}`}>
          <div className="d-flex">
            <input
              id="setExpiry"
              type="checkbox"
              className="cursor-pointer margin-top-minus-5"
              checked={hasExpiry}
              onChange={handleUpdateExpiry}
            />
            <label htmlFor="setExpiry" className="cursor-pointer left-buffer10 font-weight-400 width-120">
              Set Expiry Date
              <i
                className="seve-icon-question-mark left-buffer5"
                data-toggle="tooltip"
                data-placement="top"
                role="button"
                aria-label="Allow limited access to files by specifying an expiry date"
                title="Allow limited access to files by specifying an expiry date"
              />
            </label>
          </div>
          <DateRangePicker
            onApply={handleApply}
            initialSettings={initialSettings}
            ref={datePickerRef}
          >
            <div className={`input-group bg-white ${display === 'inline' && 'flex-grow'} ${!hasExpiry && 'hide'} ${type === 'view' && 'no-pointer-events'}`}>
              <div
                id="reportrange"
                className="border-2 form-control font600 text-primary width100p bg-none d-flex align-items-center"
                role="button"
              >
                {hasExpiry && (
                <span>{`${moment(options.expiry_timestamp).format('MMMM Do YYYY, h:mm:ss a')}`}</span>
                )}
              </div>
              <span className="input-group-addon">
                <i className="icon seve-icon-calendar" />
              </span>
            </div>
          </DateRangePicker>
        </div>
        <div className={`form-group ${display === 'inline' && 'display-flex items-center'}`}>
          <div className="d-flex">
            <input
              id="accessLimit"
              type="checkbox"
              className="cursor-pointer margin-top-minus-5"
              checked={hasAccessLimit}
              onChange={handleUpdateAccessLimit}
            />
            <label htmlFor="accessLimit" className="cursor-pointer left-buffer10 font-weight-400 control-label">
              Set Access Limit
              <i
                className="seve-icon-question-mark left-buffer5"
                data-toggle="tooltip"
                data-placement="top"
                role="button"
                aria-label="Allow limited access to files by specifying an access limit"
                title="Allow limited access to files by specifying an access limit"
              />
            </label>
          </div>
          <div>
            <input
              type="number"
              className={`form-control ${!hasAccessLimit && 'hide'}`}
              id="accessLimit"
              value={initialOptions.access_limit}
              onChange={(e) => updateAccessOptions(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkSettings;
