import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Alert from '../../Utilities/Alert';
import Header from '../../Layout/Header';
import CreateAttachmentHeader from './CreateAttachmentHeader';
import CreateAttachmentSettings from './CreateAttachmentSettings';
import FilesTab from './FilesTab';
import CreateAttachmentContent from './CreateAttachmentContent';
import AttachmentService from '../attachment-service';

const CreateAttachment = () => {
  const location = useLocation();
  const history = useHistory();
  const [status, setStatus] = useState('idle');
  const [group, setGroup] = useState({ name: 'Untitled', options: { expiry_timestamp: 0 }, assets: [] });
  const [message, setMessage] = useState('');
  const { id } = useParams();
  const isLoading = () => status === 'pending';
  const isRejected = () => status === 'rejected';

  const isNewGroup = () => location.pathname === '/online-attachments/create';

  const updateOptions = (options) => {
    setGroup((prev) => ({ ...prev, options }));
  };

  const update = async (callback) => {
    await AttachmentService.updateGroup(group);
    callback();
  };

  const saveIfNew = async (callback, file) => {
    const response = await AttachmentService.createGroup(group, file);
    callback();
    const { id: groupId } = response.data.group;
    setGroup((prev) => ({ ...prev, id: groupId }));
    history.replace(`/online-attachments/${groupId}/edit`);
  };

  const saveCallback = (callback = null) => {
    setMessage('Save successful');
    setStatus('resolved');
    if (callback !== null) {
      callback();
    }
  };

  const review = async () => {
    await AttachmentService.updateGroup(group);
    setMessage('Save successful');
    setStatus('resolved');
    setTimeout(() => history.push(`/online-attachments/${id}/review`), 2000);
  };

  const setCallbackStatus = (callbackStatus) => setStatus(callbackStatus);

  const handleFileOperationSuccess = (successMessage) => {
    setMessage(successMessage);
    setTimeout(() => setMessage(''), 4000);
  };

  const handleFileOperationFailure = (failureMessage) => {
    setMessage(failureMessage);
    setTimeout(() => setMessage(''), 4000);
  };

  const handleCreateGroup = async (uploadedFile) => {
    await saveIfNew(saveCallback, uploadedFile);
  };

  const saveAndExit = async () => {
    await AttachmentService.updateGroup(group);
    setMessage('Save successful');
    setStatus('resolved');
    setTimeout(() => history.push('/online-attachments'), 2000);
  };

  const saveByType = (type, file) => {
    if (type === 'new' || isNewGroup()) {
      return saveIfNew(saveCallback, file);
    }
    if (type === 'review') {
      return review();
    }
    if (type === 'saveAndExit') {
      return saveAndExit();
    }
    return update(saveCallback);
  };

  const save = async (type = '', file = null) => {
    try {
      setMessage('');
      setStatus('pending');
      await saveByType(type, file);
      setStatus('resolved');
    } catch (e) {
      setMessage(`Unable to save: ${e.response?.data?.message ?? 'Unable to save'}`);
      setStatus('rejected');
    }
  };

  const updateName = (name) => { setGroup((prev) => ({ ...prev, name })); };

  useEffect(() => {
    async function getGroup() {
      try {
        setMessage('');
        setStatus('pending');
        const response = await AttachmentService.getGroup(id);
        setGroup(response.data.group);
        setStatus('resolved');
      } catch (e) {
        setStatus('rejected');
        setMessage('Unable to get attachments');
        setTimeout(() => history.push('/online-attachments'), 5000);
      }
    }

    if (id !== undefined) {
      getGroup();
    }
  }, []);

  return (
    <div className={`online-attachments ${isLoading() ? 'disabled' : ''}`}>
      <Alert message={message} type={isRejected() ? 'danger' : 'success'} showAlert={message !== ''} />
      <>
        <Header noHeader>
          <div className="">
            <CreateAttachmentHeader name={group.name} updateName={updateName} />
          </div>
        </Header>
        <div className="row d-flex">
          <div className="width-400 bg-gray-lightest pad-right0 min-container-height overflow-y-scroll pad-bottom50">
            <div>
              <ul className="nav nav-tabs d-flex" role="tablist">
                <li role="presentation" className="active flex-grow">
                  <a href="#files" aria-controls="home" role="tab" data-toggle="tab">
                    <i className="seve-icon-paper-clip right-buffer10" />
                    Files
                  </a>
                </li>
                <li role="presentation" className="flex-grow">
                  <a href="#settings" aria-controls="profile" role="tab" data-toggle="tab">
                    <i className="seve-icon-settings-streamline right-buffer10" />
                    Settings
                  </a>
                </li>
              </ul>
              <div className="tab-content padding40">
                <div role="tabpanel" className="tab-pane active" id="files">
                  <FilesTab
                    initialFiles={group.assets}
                    groupId={id}
                    callbackStatus={setCallbackStatus}
                    handleSuccess={handleFileOperationSuccess}
                    handleFailure={handleFileOperationFailure}
                    handleCreateGroup={handleCreateGroup}
                  />
                </div>
                <div role="tabpanel" className="tab-pane" id="settings">
                  <CreateAttachmentSettings
                    group={group}
                    updateOptions={updateOptions}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow pad-left0 min-container-height display-flex flex-column justify-content-between">
            <CreateAttachmentContent group={group} />
          </div>
        </div>
      </>
      <div className="neutron-footer">
        <div className="text-right pad-left20 pad-right20">
          <button
            type="button"
            className="btn btn-link gray padding0 right-buffer20"
            onClick={save}
            onKeyUp={save}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-next"
            onClick={() => save('saveAndExit')}
            onKeyUp={() => save('saveAndExit')}
          >
            Save & Exit
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateAttachment;
