import { Field } from 'formik';

export default (props) => {
  const {
    id,
    placeholder = '',
    containerClass = '',
    iconClass = 'seve-icon-search-find',
  } = props;

  return (
    <div className={`input-group ${containerClass}`}>
      <Field
        type="text"
        className="form-control"
        id={id}
        name={id}
        placeholder={placeholder}
      />

      <div className="input-group-addon">
        <i className={`${iconClass}`} />
      </div>
    </div>
  );
};
